export interface GroupI {
  id: number;
  schoolID: number;
  unitID: number;
  typeRxGroupID: number;
  typeRxGroup: TypeRxGroupI,
  userID: number; //manager
  userapp: UserappI,
  category:string;
  name: string;
  description: string;
  researchGroupToTypeResearches: ResearchGroupToTypeResearchesI[];
  researchGroupID: number;
  researchGroup:{
    name: string;
  }
  researchArea:{
    NameArea:string;
  }
  unit:{
    nameUnit:string;
  }
  school: {
    NameTSchool:string;
  }
  activeCard?: boolean;
  startDate: Date;
  endDate: Date;
}

export interface TypeRxGroupI{
  id?: number;
  name: string;
  description: string;
}

export interface UserappI{
  Name1: string;
    Name2: string;
    LastName1: string;
    LastName2: string;
}

export interface ResearchGroupToTypeResearchesI{
  typeResearch: {
    name: string;
  }
}


