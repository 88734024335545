import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MemberSelectInputComponent } from 'src/app/components/shared/selectors/member-select-input/member-select-input.component';
import { MemberI } from 'src/app/models/researchModels/member';
import { UserI } from 'src/app/models/researchModels/user';
import { RequestResearchService } from 'src/app/services/research/research.service';


interface Select{
  id:number | string;
  text: string;
}

@Component( {
  selector: 'request-add-member-modal',
  templateUrl: './add-member-modal.component.html',
  styleUrls: [ './add-member-modal.component.css' ]
} )
export class AddMemberModalComponent {

  constructor (
    private _fb: FormBuilder,
    private _requestResearchService: RequestResearchService
  ) {
    //
  }

  @ViewChild( MemberSelectInputComponent ) memberSelectInput: MemberSelectInputComponent;
  @Output( 'isMemberAdded' ) isMemberAdded: EventEmitter<void> = new EventEmitter();
  @Output( 'closeModal' ) closeModal: EventEmitter<void> = new EventEmitter();
  @Input( 'selectedGroupId' ) selectedGroupId:number = 0;
  @Input( 'userListToExclude' ) userListToExclude: UserI[] = [];
  @Input( 'showModal' ) showModal: boolean = false;


  public ocde: Select[] = [
    { id: "Ciencias Agrícolas", text: "Ciencias Agrícolas" },
    { id: "Ciencias Médicas y de Salud", text: "Ciencias Médicas y de Salud" },
    { id: "Ciencias Naturales", text: "Ciencias Naturales" },
    { id: "Ciencias Sociales", text: "Ciencias Sociales" },
    { id: "Humanidades", text: "Humanidades" },
    { id: "Ingeniería y Tecnología", text: "Ingeniería y Tecnología" },
  ];

  public categories: Select[] = [
    { id: "Eméritos", text: "Eméritos" },
    { id: "Sénior", text: "Sénior" },
    { id: "Asociados", text: "Asociados" },
    { id: "Junior", text: "Junior" },
    { id: "No reconocido", text: "No reconocido" },
    { id: "Estudiante de Especialización", text: "Estudiante de Especialización" },
    { id: "Estudiante de Maestría", text: "Estudiante de Maestría" },
    { id: "Estudiante de Doctorado", text: "Estudiante de Doctorado" },
  ];
 
  public memberForm: FormGroup = this._fb.group( {
    ocde: [ '', Validators.required ],
    category: [ '', Validators.required ],
    isProfessor: [ false ],
    isActive: [ true ],
    hours: [ '', Validators.required ],
    linkMinEdu: [ '' ],
  } );

  public memberToAdd?: UserI = undefined;
  public isSubmitting:boolean = false;

  /**
  * creates a new member for the current group
  */
  public addMember(): void {
    if ( !( this.memberToAdd !== undefined && this.memberForm.valid ) || this.isSubmitting )
      return;

    this.isSubmitting = true;
    let data: MemberI = {
      isActive: true,
      userID: this.memberToAdd!.id,
      researchGroupID: this.selectedGroupId,
      OCDE: this.memberForm.get( 'ocde' )?.value,
      hours: this.memberForm.get( 'hours' )?.value,
      category: this.memberForm.get( 'category' )?.value,
      linkMinEdu: this.memberForm.get( 'linkMinEdu' )?.value,
      isProfessor: this.memberForm.get( 'isProfessor' )?.value,
    };
   
    this._requestResearchService.createMember( data ).subscribe( {
      complete: () => {
        this.isMemberAdded.emit();
        this.closeMembersModal();
      },
      error: ( err: Error ) => console.log( err )
    } ) 
  }

  /**
  * obtains the member to be added
  * @param members 
  */
  public getMemberToAdd( member: UserI[] ) {
    this.memberToAdd = member[ 0 ];
  }

  /**
  * clear the form data
  */
  private clearForm() {
    this.memberForm.reset( {
      ocde: '',
      category: '',
      isProfessor: false,
      isActive: false
    } );

    this.memberSelectInput.clearData();
    this.memberToAdd = undefined;
  }

  /**
  * closes and clears the add member modal
  */
  public closeMembersModal() {
    this.clearForm();
    this.showModal = false;
    this.closeModal.emit();
    this.isSubmitting = false;
  }
}
