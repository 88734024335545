import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EventsModule } from './events/events.module';
import { ScientificProductionModule } from './scientific-production/scientific-production.module';
import { ProjectsModule } from './projects/projects.module';

@NgModule({
  declarations: [
  ],
  imports: [
    ProjectsModule,
    ScientificProductionModule,
    EventsModule,
    CommonModule
  ],
  exports:[
    ScientificProductionModule,
    EventsModule,
    ProjectsModule
  ]
})
export class SectionsModule { }
