<div class="group-stats" [ngClass]="showStats ? 'group-stats--show' : ''">
  <div class="group-stats__header">
    <button class="button button--none" (click)="closeStatsModal()">
      <em class="icon-i-close"></em>
    </button>
    <p class="stats-text stats-text--bold">Filtrar por:</p>
  </div>

  <div class="group-stats__body">
    <!-- FILTERS -->
    <div class="stats-pill__filter-container">
      <div class="filter-container__date-filter" *ngIf="showDateFilter">
        <request-date-pill (onInputChange)="getFromDate($event)" [defaultDate]="(fromDate | date:'yyyy-MM-dd')"></request-date-pill>
        <span class="stats-text stats-text--center">A</span>
        <request-date-pill (onInputChange)="getToDate($event)" [defaultDate]="(toDate | date:'yyyy-MM-dd')"></request-date-pill>
      </div>

      <div class="filter-container__type-filter">
        <div class="type-filter" *ngFor="let filter of filterList" (click)="changeFilter(filter)">
          <p class="stats-text stats-text--center"
            [ngClass]="selectedFilter === filter ? 'stats-text--bold': '' ">
            {{ filter }}
          </p>
        </div>
      </div>
    </div>

    <ng-container [ngTemplateOutlet]="statBars" [ngTemplateOutletContext]="{data:stats}"></ng-container>
    <ng-container [ngTemplateOutlet]="statsInfoQty" [ngTemplateOutletContext]="{data:stats}"></ng-container>

    <ng-container *ngIf="innerStats != undefined">
      <h2 class="innerstats-title">{{ innerStats.barName }}</h2>
      <ng-container [ngTemplateOutlet]="statBars" [ngTemplateOutletContext]="{data:innerStats.stats}"></ng-container>
      <ng-container [ngTemplateOutlet]="statsInfoQty" [ngTemplateOutletContext]="{data:innerStats.stats}"></ng-container>
    </ng-container>

    <button class="button button--dark button--stats" (click)="downloadExcel()" *ngIf="excelData !== undefined">Descargar reporte</button>
  </div>
</div>

<!-- #region STATS INFO -->
<ng-template #statsInfoQty let-data="data">
  <div class="group-stats__info">
    <ng-container *ngFor="let info of data">
      <div class="group-stats__box-container" *ngIf="info.filter.includes(selectedFilter) && info.qty > 0">
        <span class="group-stats__box" [ngStyle]="{'background-color':info.color}"></span>
        <p class="stats-text">{{ info.name | titlecase }}: {{ info.qty }}</p>
      </div>
    </ng-container>
  </div>
</ng-template>
<!-- #endregion -->

<!-- #region BAR STATS DISPLAY -->
<ng-template #statBars let-data="data">
  <div class="group-stats__plane">
    <ng-container [ngTemplateOutlet]="statLevels"></ng-container>
    <div class="group-stats__bars">
      <div class="group-stats__bars-content">
        <ng-container [ngTemplateOutlet]="barDiv" [ngTemplateOutletContext]="{ data:bar }" *ngFor="let bar of data"></ng-container>
      </div>
    </div>
  </div>
</ng-template>
<!-- #endregion -->

<!-- #region BAR -->
<ng-template #barDiv let-data="data">
 <!--  default bars -->
  <div *ngIf="(data.filter.includes(selectedFilter) && data.qty > 0) && 
    (data.innerStats?.length === 0 || data.innerStats === undefined)" class="stats-bar" [ngStyle]="{'background-color': data.color + '40'}">
    <div class="stats-bar__fill" [ngStyle]="{'background-color': data.color, 'height.px':data.qty * 250 / 164}">
    </div>
  </div>

  <!-- clickable bars -->
  <div
    *ngIf="(data.filter.includes(selectedFilter) && data.qty > 0) && data.innerStats?.length > 0"
    class="stats-bar" (click)="showInnerStats(data.barId)" [ngStyle]="{'background-color': data.color + '40', 'cursor': 'pointer'}">
    <div class="stats-bar__fill" [ngStyle]="{'background-color': data.color, 'height.px':data.qty * 250 / 164}">
    </div>
  </div>
</ng-template>
<!-- #endregion -->

<!-- #region STAT LEVELS -->
<ng-template #statLevels>
  <div class="group-stats__level" 
    *ngFor="let level of [ 250,200,150,100,50, 0 ]">
    <p class="stats-text stats-text--bold stats-text--right stats-text--width">{{ level }}</p>
    <span class="stats-line"></span>
  </div>
</ng-template>
<!-- #endregion -->

<div class="modal-bg" (click)="closeStatsModal()" [ngClass]="showStats ? 'modal-bg--show' : ''">
</div>