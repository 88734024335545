import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'request-date-input',
  templateUrl: './date-input.component.html',
  styleUrls: ['./date-input.component.css']
})
export class DateInputComponent implements OnInit {

  constructor() { 
    //
  }

  //receives the parent form
  @Input('parentForm') parentForm:FormGroup = new FormGroup({});

  //receives the name of the control name
  @Input('controlName') controlName:string = '';

  //receives label title
  @Input('title') title:string = '';

  ngOnInit(): void {
    //
  }

}
