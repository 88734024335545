import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ProductI } from 'src/app/models/researchModels/product';
import { ProjectI } from 'src/app/models/researchModels/project';
import { ResearchRolesService } from 'src/app/services/research/research-roles.service';

@Component({
  selector: 'request-scientific-card',
  templateUrl: './scientific-card.component.html',
  styleUrls: ['./scientific-card.component.css']
})
export class ScientificCardComponent implements OnInit {

  constructor(
    public rolesService: ResearchRolesService
  ) {
    //
  }

  ngOnInit(): void {
    //
  }

  //gets product information
  @Input( 'productInfo' ) productInfo: ProductI = {} as ProductI;

  //issues a call when the edit button is clicked
  @Output('editProduct') editProduct: EventEmitter<any> = new EventEmitter();
}
