import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'request-checkbox-input',
  templateUrl: './checkbox-input.component.html',
  styleUrls: ['./checkbox-input.component.css']
})
export class CheckboxInputComponent implements OnInit {

  constructor() { 
    //
  }

  //outputs the status of the checkbox, whether it is checked or unchecked.
  @Output('checkBox') checkBox: EventEmitter<boolean> = new EventEmitter(); 

  //receives the input id
  @Input('inputId') inputId:string = '';

  //receives the parent form
  @Input('parentForm') parentForm:FormGroup = new FormGroup({});

  //receives the name of the control name
  @Input('controlName') controlName:string = '';

  //receives label title
  @Input('title') title:string = '';

  ngOnInit(): void {
    //
  }

  onCheckboxCheck(data){
    this.checkBox.emit(data.target.checked)
  }

}
