import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GroupI } from '../../models/researchModels/group';
import { MemberI } from '../../models/researchModels/member';
import { ProjectI } from '../../models/researchModels/project';
import { UserI } from '../../models/researchModels/user';
import { HttpService } from '../http.service';

@Injectable( {
  providedIn: 'root'
} )
export class RequestResearchService {

  constructor ( private _httpService: HttpService ) { }

  private _currentSchools:any[] = [];
  get currentSchools ():any[]{
    return this._currentSchools;
  }

  set currentSchools(value:any[]){
    this._currentSchools = value;
  }

  /**
   * get the list of members
   * @param filter
   * @returns
   */
  getMembers( filter?: string ): Observable<MemberI[]> {
    let filterData = "";
    if ( filter ) filterData = `?filter=${ filter }`;
    return this._httpService.httpGet(`/Members${ filterData }`);
  }

  /**
  * get the list of members
  * @param filter
  * @returns
  */
  getMember( id: number ): Observable<MemberI> {
    return this._httpService.httpGet(`/Members/${ id }`);
  }

  /**
   * updates a member
   * @param id
   * @param data
   * @returns
   */
  updateMember( id:number, data: MemberI ): Observable<MemberI> {
    return this._httpService.httpPatch(`/Members/${ id }`, data);
  }

  deleteMember(id:number){
    return this._httpService.httpDelete(`/Members/${ id }`);
  }

  /**
   * creates a new member
   * @param data
   * @returns
   */
  createMember(data: MemberI ): Observable<MemberI> {
    return this._httpService.httpPost('/Members', data);
  }

  /**
   * removes a member
   * @param id
   * @returns
   */
  removeMember(id: number): Observable<MemberI> {
    return this._httpService.httpDelete(`/Members/${id}`);
  }

  /**
  * get the list of groups
  * @param filter
  * @returns
  */
  getGroups( filter?: string ): Observable<GroupI[]> {
    let filterData = "";
    if ( filter ) filterData = `?filter=${ filter }`;
    return this._httpService.httpGet(`/ResearchGroups${ filterData }`);
  }

  /**
  * get the list of groups
  * @param filter
  * @returns
  */
  getUnities( filter?: string ): Observable<GroupI[]> {
    let filterData = "";
    if ( filter ) filterData = `?filter=${ filter }`;
    return this._httpService.httpGet(`/Units${ filterData }`);
  }

  /**
   * gets a group by id
   * @param id
   * @returns
   */
  getGroup( id:number,filter?: string  ): Observable<GroupI> {
    let filterData = "";
    if ( filter ) filterData = `?filter=${ filter }`;
    return this._httpService.httpGet( `/ResearchGroups/${ id }${ filterData }` );
  }

  /**
  * get the list of group types
  * @returns
  */
  getTypeRxGroup(): Observable<any[]> {
    return this._httpService.httpGet('/TypeRxGroups');
  }

  /**
  * get the list of research areas
  * @returns
  */
  getResearchAreas(filter?:string): Observable<any[]> {
    let filterData = "";
    if ( filter ) filterData = `?filter=${ filter }`;
    return this._httpService.httpGet(`/ResearchAreas${ filterData }`);
  }

  /**
  * sets the research lines per group
  * @returns
  */
  setGroupTypeResearches(data:any): Observable<any[]> {
    return this._httpService.httpPost('/ResearchGroupToTypeResearches', data);
  }

  deleteGroupTypeResearches(id:number): Observable<any[]> {
    return this._httpService.httpDelete(`/ResearchGroupToTypeResearches/${id}`);
  }

  deleteGroup(id:number): Observable<any[]>{
    return this._httpService.httpDelete(`/ResearchGroups/${id}`);
  }

  /**
   * sets the research lines per project
   * @param data
   * @returns
   */
  setProjectTypeResearches(data:any): Observable<any[]> {
    return this._httpService.httpPost('/ProjectToTypeResearches', data);
  }

  /**
   * gets the research lines per project
   * @param filter
   * @returns
   */
  getProjectTypeResearches(filter?:string): Observable<any[]> {
    let filterData = "";
    if ( filter ) filterData = `?filter=${ filter }`;
    return this._httpService.httpGet(`/ProjectToTypeResearches${ filterData }`);
  }

  /**
   * deletes the research lines
   * @param id
   * @returns
   */
  removeProjectTypeResearches(id:number): Observable<any[]> {
    return this._httpService.httpDelete(`/ProjectToTypeResearches/${ id }`);
  }

  /**
  * get the list of schools
  * @returns
  */
  getSchools(filter?:string): Observable<any[]> {
    let filterData = "";
    if ( filter ) filterData = `?filter=${ filter }`;
    return this._httpService.httpGet(`/Schools${filterData}`);
  }

  /**
  * get the list of research types
  * @returns
  */
  getTypeResearch( filter?: string ): Observable<any[]> {
    let filterData = "";
    if ( filter ) filterData = `?filter=${ filter }`;
    return this._httpService.httpGet( `/TypeResearches${ filterData }` );
  }

  /**
   * create a new group
   * @param data
   * @returns
   */
  createGroup( data: GroupI ): Observable<GroupI> {
    return this._httpService.httpPost( '/ResearchGroups', data);
  }

  updateGroup(data: GroupI){
    const {id, ...finalData} = data;
    return this._httpService.httpPatch( `/ResearchGroups/${id}`, finalData);
  }

  /**
   * creates a new project
   * @param data
   * @returns
   */
  createProject(data: ProjectI):Observable<ProjectI>{
    return this._httpService.httpPost('/Projects', data);
  }

  /**
   * get the list of projects
   * @param filter
   * @returns
   */
  getProjects(filter?:string):Observable<ProjectI[]>{
    let filterData = "";
    if ( filter ) filterData = `?filter=${ filter }`;
    return this._httpService.httpGet(`/Projects${ filterData }`);
  }

  /**
   * gets a project for its id
   * @param id
   * @returns
   */
  getProject(id:number):Observable<ProjectI>{
    return this._httpService.httpGet(`/Projects/${id}`);
  }

  /**
  * set project members
  * @param filter
  * @returns
  */
   setProjectMember( data: any ): Observable<any[]> {
    return this._httpService.httpPost('/ProjectMembers', data);
  }

  /**
  * get project members
  * @param filter
  * @returns
  */
  getProjectMembers( filter?: string ): Observable<any[]> {
    let filterData = "";
    if ( filter ) filterData = `?filter=${ filter }`;
    return this._httpService.httpGet(`/ProjectMembers${ filterData }`);
  }


  /**
   * removes members of a group
   * @param memberId
   * @returns
   */
  removeProjectMembers( memberId:number ): Observable<any[]> {
    return this._httpService.httpDelete(`/ProjectMembers/${ memberId }`);
  }

  /**
   * gets project documents
   * @param filter
   * @returns
   */
  getProjectDocuments(filter?:string):Observable<any>{
    let filterData = "";
    if ( filter ) filterData = `?filter=${ filter }`;
    return this._httpService.httpGet(`/ProjectDocuments${ filterData }`);
  }

  /**
   * sets project documents
   * @param data
   * @returns
   */
  setProjectDocument(data:any):Observable<any>{
    return this._httpService.httpPost('/ProjectDocuments',data);
  }

  /**
   * updates a project
   * @param id
   * @param data
   * @returns
   */
  updateProject(id:number, data:ProjectI):Observable<ProjectI>{
    return this._httpService.httpPatch(`/Projects/${id}`, data);
  }

  /**
   * gets the list of scientific production documents
   * @param filter
   * @returns
   */
  getProducts(filter?: string):Observable<any[]>{
    let filterData = "";
    if ( filter ) filterData = `?filter=${ filter }`;
    return this._httpService.httpGet(`/Products${ filterData }`);
  }

  /**
   * gets a scientific production document per id
   * @param id
   * @returns
   */
  getProduct(id:number):Observable<ProjectI>{
    return this._httpService.httpGet(`/Products/${id}`);
  }

  /**
   * sets the scientific production document
   * @param data
   * @returns
   */
  setProduct(data:any):Observable<any>{
    return this._httpService.httpPost('/Products',data);
  }

  /**updates a scientific production document
   *
   * @param id
   * @param data
   * @returns
   */
  updateProduct(id:number, data:any):Observable<any>{
    return this._httpService.httpPatch(`/Products/${id}`,data);
  }

  /**
   * gets the list of care areas
   * @param filter
   * @returns
   */
  getCareArea(filter?: string){
    let filterData = "";
    if ( filter ) filterData = `?filter=${ filter }`;
    return this._httpService.httpGet(`/CareAreas${ filterData }`);
  }

  /**
   * sets an article
   * @param data
   * @returns
   */
  setArticle(data:any){
    return this._httpService.httpPost('/Articles',data);
  }

  /**
   * gets the list of product types
   * @returns
   */
  getProductTypes(){
    return this._httpService.httpGet("/CategoryProducts");
  }

  /**
   * get the list of product subtypes
   * @param filter
   * @returns
   */
  getProductSubtypes(filter?: string):Observable<any[]>{
    let filterData = "";
    if ( filter ) filterData = `?filter=${ filter }`;
    return this._httpService.httpGet(`/TypeProducts${ filterData }`);
  }

  /**
   * obtains user information in a compact way
   * @param filter
   * @returns
   */
  getUserInfo( filter?: string ): Observable<UserI[]> {
    let filterData = "";
    if ( filter ) filterData = `?filter=${ filter }`;
    return this._httpService.httpGet(`/ViewUserInfos${ filterData }`);
  }


  //GET QTY


  /**
   * gets the amount of projects
   * @returns
   */
   getProjectsQuantity(filter?: string){
    let filterData = "";
    if ( filter ) filterData = `?where=${ filter }`;
    return this._httpService.httpGet(`/Projects/count${ filterData }`);
  }

  /**
  * gets the amount of groups
  * @returns
  */
  getProductsQty(filter?: string) {
    let filterData = "";
    if ( filter ) filterData = `?filter=${ filter }`;
    return this._httpService.httpGet(`/ViewProductsInfos${ filterData }`);
  }

  /**
   * gets a list of project records
   * @returns
   */
  generateStatsExcel(){
    return this._httpService.httpGet('/ViewProjectsInfos');
  }
}
