<div class="main">

  <!-- #region CONTAINER HEADER -->
  <div class="main__header">
    <div class="main__actions">
      <div class="main__group-stats" *ngIf="originalGroups.length > 0">
        <button class="button button--dark" (click)="showStatsModal = true">Estadísticas generales</button>
        <request-group-stats [showStats]="showStatsModal" 
          [groupId]="groupListIds" 
          [filterList]="['Grupos, Proyectos, Eventos', 'Producción científica', 'Inscritos']"
          (closeModal)="showStatsModal = false"
          [showDateFilter]="false">
        </request-group-stats>
      </div>

      <div class="main__group-form" *ngIf="rolesService.allowedRoles.ADMIN">
        <button class="button button--dark" (click)="onCreateGroup()">Crear organización</button>
        <request-group-form [showModal]="showOrganizationForm" (closeModal)="showOrganizationForm = false;"
          (onCreateGroup)="getGroupList()">
        </request-group-form>
      </div>

      <div class="main__group-form" *ngIf="rolesService.allowedRoles.ADMIN && selectedGroup !== null">
        <button class="button button--dark" (click)="onEditGroup()">Editar organización</button>
        <request-edit-group-form [selectedGroup]="groupToEdit" [showModal]="showOrganizationEditForm" 
          (onCloseModal)="showOrganizationEditForm = false; groupToEdit = null" *ngIf="groupToEdit"
          (onDeleteGroup)="getGroupList()" (onUpdateGroup)="getGroupList()">
        </request-edit-group-form>
      </div>
    </div>
  </div>
  <!-- #endregion -->

  <div class="info-panel">
    <div class="info-panel__cards">
      <div class="filter__groups">
        <request-filter-input [placeholder]="'Buscar por nombre'" (value)="filterByName($event)">
        </request-filter-input>

        <div class="filter__button-container">
          <button class="button button--filter" type="button" id="filter-button">
            <span class="filter-bar__icon"></span>
            <span class="filter-bar__icon"></span>
            <span class="filter-bar__icon"></span>
          </button>
          <ng-container [ngTemplateOutlet]="filterModal"></ng-container>
        </div>
      </div>

      <!-- If there are groups... -->
      <ng-container *ngIf="filteredGroups.length > 0">
        <request-group-card *ngFor="let group of filteredGroups; trackBy: trackItems"
          [activateCard]="selectedGroup && selectedGroup.id === group.id"
          [groupData]="group"
          (onSelected)="onSelectCard( group )" [buttonId]="group.id">
        </request-group-card>
      </ng-container>

      <!-- If there are no groups... -->
      <ng-container *ngIf="(originalGroups.length === 0 || filteredGroups.length === 0)">
        <div class="info-panel__empty-card">
          <p class="main-text main-text--center main-text--bold">Aquí podrá ver la lista de grupos</p>
        </div>
      </ng-container>
    </div>

    <div class="info-panel__members" [ngClass]="showGroupInfo ? 'info-panel__members--active' : '' ">
      <h2 class="main-text main-text--bold main-text--lg main-text--absolute main-text--center" *ngIf="!showGroupInfo">
        Seleccione un grupo para ver su información
      </h2>

      <request-member-list *ngIf="showGroupInfo && selectedGroup" (closeMemberList)="closeMemberList()"
        [selectedGroupId]="selectedGroup.id">
      </request-member-list>
    </div>
  </div>
</div>

<!-- TODO:  -->
<ng-template #filterModal>
  <div class="filter__modal" [ngClass]="showFilters ? 'filter__modal--show': ''">
    <h2 class="main-text main-text--lg main-text--bold">FILTROS</h2>

    <div class="filter__modal-option ">
      <h2 class="main-text main-text--bold filter-label">Escuela</h2>
      <select class="school-filter form-control" name="" id="school-filter" (change)="onSelectSchool($event)" [(ngModel)]="schoolId">
        <option value="0" selected>Todas</option>
        <option value="{{ school.id }}" *ngFor="let school of schoolList">{{ school.NameTSchool }}</option>
      </select>
    </div>

    <div class="filter__modal-option " [ngClass]="{'disabled': unityList.length === 0}">
      <h2 class="main-text main-text--bold filter-label">Unidad</h2>
      <select class="school-filter form-control" name="" id="school-filter" [(ngModel)]="unityId">
        <option value="0" selected>Todas</option>
        <option value="{{ unity.id }}" *ngFor="let unity of unityList">{{ unity.nameUnit }}</option>
      </select>
    </div>

    <div class="filter__modal-option ">
      <h2 class="main-text main-text--bold filter-label">Tipo de investigación</h2>
      <div class="filter__investigation-type">
        <request-filter-pill [title]="item.title"
          (onSelected)="investigationType = item.id" [buttonId]="item.id" [activatePill]="item.id === 'all'" 
          *ngFor="let item of investigationTypeFilter">
        </request-filter-pill>
      </div>
    </div>

    <div class="filter__modal-option">
      <button class="button button--apply-filter" (click)="applyFilters()">Aplicar</button>
    </div>
  </div>
</ng-template>