import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { forkJoin, of } from 'rxjs';
import { InfoFileData } from 'src/app/models/logicModels/infoFileData';
import { SchoolI } from 'src/app/models/researchModels/project';
import { RequestResearchService } from 'src/app/services/research/research.service';
import { environment } from 'src/environments/environment';

interface Select{
  id:number | string;
  text:string;
}

@Component( {
  selector: 'add-event-form',
  templateUrl: './add-event-form.component.html',
  styleUrls: [ './add-event-form.component.css' ]
} )
export class AddEventFormComponent implements OnInit {

  constructor (
    private _requestResearchService: RequestResearchService, 
    private _fb: FormBuilder,
     ) {
  }

  //emits a call each time a new events is created
  @Output( 'createdEvent' ) createdEvent: EventEmitter<void> = new EventEmitter();

  //issues a call when the modal is closed
  @Output( 'closeModal' ) closeModal: EventEmitter<void> = new EventEmitter();

  //allows to show or hide the event form
  @Input( 'showModal' ) showModal: boolean = false;

  //receives the id of the current group
  @Input( 'groupId' ) groupId: number = 0;

  //form
  public eventsForm: FormGroup = this._fb.group( {
    news: [false],
    school: [''],
    careArea: [''],
    description: [''],
    name: ['', Validators.required],
    date: ['', Validators.required],
    unitsParticipant: ['', Validators.required],
  } );

  //stores the unites list
  public schoolList: Select[] = [];


  //stores the careArea list
  public careAreaList: Select[] = [];

  //stores the article image link
  public imageLink: string = '';

  //checks whether data is being uploaded or not
  public isSubmitting:boolean = false;

  public urlBackV1:any = environment.urlBackV1;

  ngOnInit(): void {
    this.schoolList =  this._requestResearchService.currentSchools.map(e => ({id:e.id, text:e.NameTSchool}))
  }

  /**
   * creates the event
   * @returns 
   */
  submitEvent() {
    let eventData = {
      isEvent: true,
      researchGroupID: this.groupId,
      name:  this.eventsForm.get('name')?.value,
      date:  this.eventsForm.get('date')?.value,
      description: this.eventsForm.get( 'description' )?.value,
      unitsParticipant:  this.eventsForm.get( 'unitsParticipant' )?.value,
    }

    let articleData = {
      Description: this.eventsForm.get( 'description' )?.value,
      CareAreaID:  this.eventsForm.get( 'careArea' )?.value,
      SchoolID:  this.eventsForm.get( 'school' )?.value,
      Title:  this.eventsForm.get('name')?.value,
      Imagen: this.imageLink,
      /*  date:  this.eventsForm.get('date')?.value, */
    }

    let addNews = this.eventsForm.get( 'news')?.value;
    if ( !this.eventsForm.valid || this.isSubmitting ) return;

    this.isSubmitting = true;
    forkJoin({
      event: this._requestResearchService.setProduct(eventData),
      article: addNews ? this._requestResearchService.setArticle(articleData) : of(null),
    }).subscribe({
      complete:()=>{
        this.closeEventModal();
        this.createdEvent.emit();
        this.isSubmitting = false;
      }, error: ( err: Error ) => console.log( err )
    })
  }

  /**
   * gets the data from the file
   * @param data 
   */
  getImageData(data: InfoFileData){
    this.imageLink = this.urlBackV1 + data.urlFile;
  }

  /**
  * clear the form data
  */
   clearForm() {
    this.eventsForm.reset( {
      type: '',
      school: '',
      news:false,
      project: '',
      subtype: '',
      careArea: '',
    } );
  }

  /**
  * closes and clears the events modal
  */
  closeEventModal() {
    this.clearForm();
    
    this.showModal = false;
    this.closeModal.emit();
    this.imageLink = '';
    this.isSubmitting = false;
    this.careAreaList = [];
  }

  /**
   * if the upload file option is checked it converts certain fields to required or vice versa.
   * @param isAddingFile 
   */
   setControlValidations(){
    if(this.eventsForm.get( 'news' )?.value){
      this.eventsForm.controls['school'].setValidators(Validators.required);
      this.eventsForm.controls['careArea'].setValidators(Validators.required);
    }
    else{
      this.eventsForm.controls['school'].clearValidators();
      this.eventsForm.controls['careArea'].clearValidators();
    }
    this.eventsForm.get('school')?.updateValueAndValidity();
    this.eventsForm.get('careArea')?.updateValueAndValidity();
  }

  /**
   * obtains the list of areas
   * @param unityId 
   */
   getCareArea( unityId: number ) {
    this.eventsForm.get('careArea')?.reset('');
    this.careAreaList = [];

    let areaFilter = JSON.stringify( { where: { SchoolID: unityId } } );
    this._requestResearchService.getCareArea( areaFilter ).subscribe( {
      next: ( areaList: any ) => {
        this.careAreaList = areaList.map( e => ({id:e.id, text:e.CareAreaName}));
      },
      error: ( err ) => console.log( err )
    } );
  }

  /**
   * checks when the checkbox is checked or unchecked
   */
   onCheckboxCheck(){
    this.setControlValidations();
  } 

}
