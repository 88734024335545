import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { forkJoin, of, switchMap } from 'rxjs';
import { MemberI } from 'src/app/models/researchModels/member';
import { RequestResearchService } from 'src/app/services/research/research.service';
import Swal from 'sweetalert2';

interface Select{
  id:number | string;
  text: string;
}
@Component( {
  selector: 'request-edit-member-modal',
  templateUrl: './edit-member-modal.component.html',
  styleUrls: [ './edit-member-modal.component.css' ]
} )
export class EditMemberModalComponent {

  constructor (
    private _requestResearchService: RequestResearchService,
    private _fb: FormBuilder
  ) {
    //
  }

  @Output( 'refreshList' ) refreshList: EventEmitter<void> = new EventEmitter();
  @Output( 'closeModal' ) closeModal: EventEmitter<void> = new EventEmitter();

  public ocde: Select[] = [
    { id: "Ciencias Agrícolas", text: "Ciencias Agrícolas" },
    { id: "Ciencias Médicas y de Salud", text: "Ciencias Médicas y de Salud" },
    { id: "Ciencias Naturales", text: "Ciencias Naturales" },
    { id: "Ciencias Sociales", text: "Ciencias Sociales" },
    { id: "Humanidades", text: "Humanidades" },
    { id: "Ingeniería y Tecnología", text: "Ingeniería y Tecnología" },
  ];

  public categories: Select[] = [
    { id: "Eméritos", text: "Eméritos" },
    { id: "Sénior", text: "Sénior" },
    { id: "Asociados", text: "Asociados" },
    { id: "Junior", text: "Junior" },
    { id: "No reconocido", text: "No reconocido" },
    { id: "Estudiante de Especialización", text: "Estudiante de Especialización" },
    { id: "Estudiante de Maestría", text: "Estudiante de Maestría" },
    { id: "Estudiante de Doctorado", text: "Estudiante de Doctorado" },
  ];

  //allows to display the disable  member modal
  private _onShowModal:any = {};
  @Input( 'onShowModal' ) set showModal(value: any){
    this._onShowModal = value;
    if ( value.showModal === true ) this.editMember();
  }

  get onShowModal(){
    return this._onShowModal;
  }

  public memberForm: FormGroup = this._fb.group( {
    ocde: [ '', Validators.required ],
    category: [ '', Validators.required ],
    isProfessor: [ false ],
    isActive: [ false ],
    hours: [ '', Validators.required ],
    linkMinEdu: [ '' ],
  } );
  public isSubmitting:boolean = false;


  /**
   * loads the data of the member to be edited
   */
  private editMember() {
    this._requestResearchService.getMember( this.onShowModal.member?.id! ).subscribe( {
      next: ( resp: MemberI ) => {
        this.memberForm.patchValue( {
          ocde: resp.OCDE,
          category: resp.category,
          isProfessor: resp.isProfessor,
          isActive: resp.isActive,
          hours: resp.hours,
          linkMinEdu: resp.linkMinEdu,
        } )
      }, error: ( err: Error ) => console.log( err )
    } );
  }

  /**
   * send the data to be edited
   * @returns 
   */
  public updateMember() {
    if ( !this.memberForm.valid || this.isSubmitting )
      return;

    this.isSubmitting = true;
    let data: any = {
      OCDE: this.memberForm.get( 'ocde' )?.value,
      hours: this.memberForm.get( 'hours' )?.value,
      category: this.memberForm.get( 'category' )?.value,
      linkMinEdu: this.memberForm.get( 'linkMinEdu' )?.value,
      isProfessor: this.memberForm.get( 'isProfessor' )?.value,
      isActive: this.memberForm.get( 'isActive' )?.value,
      lastActive: !this.memberForm.get( 'isActive' )?.value ? new Date : null
    };

    this._requestResearchService.updateMember( this.onShowModal.member?.id!, data ).subscribe( {
      complete: () => {
        this.closeEditModal();
        this.refreshList.emit();
      }, error: ( err: Error ) => console.log( err )
    } );
  }

  public deleteMember(){
    Swal.fire({
      icon: "warning",
      text:"¿Seguro que deseas eliminar este usuario?",
      allowEscapeKey:false,
      allowOutsideClick:false,
      showCancelButton:true
    }).then((result) => {
      if (result.isConfirmed){
        forkJoin({
          mainResearcherProject: this._requestResearchService.getProjects(JSON.stringify({
            where: { mainResearchID: this.onShowModal.member?.id! }
          })),
          projectMember: this._requestResearchService.getProjectMembers(JSON.stringify({
            include:["projects"],
            where:{ memberID: this.onShowModal.member?.id! }
          }))
        }).pipe(
          switchMap(({projectMember, mainResearcherProject}) => {
            //Verify that the user does not belong to a project.
            const allMemberProjects =  projectMember.map(e => e.projects.name);
            const projects = allMemberProjects.length > 0 ? 
              allMemberProjects : 
              mainResearcherProject.map(e => e.name)
                .join(", ")

            if(allMemberProjects.length > 0 || mainResearcherProject.length > 0){
              Swal.fire({
                icon: "error",
                html: `Este usuario no se puede eliminar porque hace parte de los siguientes proyectos: <strong>${projects}</strong>.`,
                allowEscapeKey: false,
                allowOutsideClick: false
              })
              return of(null);
            }

            return this._requestResearchService.deleteMember(this.onShowModal.member?.id!);
          })
        ).subscribe({
          next:(resp)=>{
            if(!resp) return;
            Swal.fire({
              icon:"success",
              text: "Usuario eliminado.",
              allowEscapeKey:false,
              allowOutsideClick:false
            }).then(result => {
              if(result.isConfirmed ){
                this.closeEditModal();
                this.refreshList.emit();
              }
            })
          },
          error:(err)=>{
            console.log(err);
          },
          complete:() => { }
        })
      }
    })
  }

  /**
   * closes the edit member modal
   */
  public closeEditModal() {
    this.memberForm.reset( {
      ocde: '',
      category: '',
      isProfessor: false,
      isActive: false
    } );

    this.onShowModal.showModal = false;
    this.closeModal.emit();
    this.isSubmitting = false;
  }
}