import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'trimWhitespace'
})
export class TrimWhitespacePipe implements PipeTransform {

  transform(value: string,): any {
    return value.replace(/\s/g,'');
  }

}
