import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'request-number-input',
  templateUrl: './number-input.component.html',
  styleUrls: ['./number-input.component.css']
})
export class NumberInputComponent implements OnInit {

  constructor() { 
    //
  }

  //receives the minimum and maximum value that the input will have
  @Input('max') max: number = Number.MAX_VALUE;
  @Input('min') min: number = 0;

  //receives the parent form
  @Input('parentForm') parentForm:FormGroup = new FormGroup({});

  //receives the name of the control name
  @Input('controlName') controlName:string = '';

  //receives label title
  @Input('title') title:string = '';

  @Input('placeholder') placeholder:string = '';

  ngOnInit(): void {
    //
  }

  /**
   * sets minimum and maximum input values
   * @param event 
   */
  setValue(event){
    let value = event.target.value;
    if(value > this.max)
      event.target.value = this.max;
    else if(value < this.min)
      event.target.value = this.min;
  }

}
