<div class="product-card">
  <button class="button button--edit" (click)="editProduct.emit()"
    *ngIf="rolesService.allowedRoles.ADMIN || rolesService.allowedRoles.LEADER">
    <em class="icon-i-filled-edit-directive"></em>
  </button>

  <div class="product-card__container">
    <p class="card__text">
      <span class="card__text--bold">Nombre: </span>
      {{ productInfo.name }}
    </p>
    <p class="card__text" *ngIf="productInfo.date">
      <span class="card__text--bold">Fecha en la que subió: </span>
      {{ productInfo.date | date:'dd/MM/yyyy' }}
    </p>

    <p class="card__text">
      <span class="card__text--bold">Tipo: </span>
      {{ productInfo.categoryProduct.name }}
    </p>

    <p class="card__text">
      <span class="card__text--bold">Subtipo: </span>
      {{ productInfo.typeProduct.name.length > 55 ?
      (productInfo.typeProduct.name | slice:0:55)+'... ':productInfo.typeProduct.name }}
    </p>

    <p class="card__text" *ngIf="productInfo.projects">
      <span class="card__text--bold">Proyecto: </span>
      {{ productInfo.projects.name || 'N/A' }}
    </p>

    <div>
      <span class="card__text card__text--bold">Link de archivo: </span>
      <a href="{{ productInfo.linkDrive }}" target="_blank">Ver archivo</a>
    </div>

    <p class="card__text card__text--clamp" *ngIf="productInfo.description">
      <span class="card__text--bold ">Descripción: </span>
      {{ productInfo.description || 'N/A' }}
    </p>

  </div>
</div>