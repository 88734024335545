import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { ProductI } from 'src/app/models/researchModels/product';
import { ResearchRolesService } from 'src/app/services/research/research-roles.service';
import { RequestResearchService } from 'src/app/services/research/research.service';

@Component( {
  selector: 'request-events',
  templateUrl: './events.component.html',
  styleUrls: [ './events.component.css' ]
} )
export class EventsComponent implements OnInit, OnDestroy {

  constructor (
    private _requestResearchService: RequestResearchService,
    public rolesService: ResearchRolesService
  ) {
  }

  //emits a call each time the project section is closed
  @Output( 'closeSection' ) closeSection: EventEmitter<void> = new EventEmitter();

  private _selectedGroupId: number = 0;
  @Input( 'selectedGroupId' ) set selectedGroupId( value: number ) {
    this._selectedGroupId = value;
    this.eventList = [];
    if ( value !== 0 )
      this.getEvents();
  }

  get selectedGroupId() {
    return this._selectedGroupId;
  }

  //stores the event list
  public eventList: ProductI[] = [];

  //checks if the event list is being loaded
  public isLoadingProducts: boolean = false;

  //displays or displays or displays the event form 
  public showModal = false;

  //shows or hides the modal
  public showEditModal = false;

  //stores the id of the event to be edited
  public selectedEventId: number = 0;

  private _subscription: Subscription = new Subscription();

  ngOnInit(): void {
    //
  }

  /**
   * get the list of events
   */
  getEvents() {
    this.isLoadingProducts = true;
    let filter = JSON.stringify( { 
      where: { and: [{ isEvent: true}, {researchGroupID: this.selectedGroupId} ] } 
    } );

    this._subscription.unsubscribe();
    this._subscription = this._requestResearchService.getProducts( filter ).subscribe( {
      next: ( result: ProductI[] ) => {
        this.eventList = result;
        this.isLoadingProducts = false;
      }, error: ( err: Error ) => console.log( err )
    } )
  }

  /**
   * sets the event id and opens the edit modal
   * @param edEventId 
   */
  editEvent( edEventId: number ) {
    this.showEditModal = true;
    this.selectedEventId = edEventId;
  }

  /**
  * emits a call when the events section is closed
  */
  onCloseSection() {
    this.closeSection.emit();
  }

  ngOnDestroy(): void {
    this._subscription.unsubscribe();
  }
}
