import { Component, ElementRef, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { debounceTime, filter, fromEvent, map, switchMap } from 'rxjs';
import { MemberI } from 'src/app/models/researchModels/member';
import { UserI } from 'src/app/models/researchModels/user';
import { RequestResearchService } from 'src/app/services/research/research.service';

@Component( {
  selector: 'request-member-select-input',
  templateUrl: './member-select-input.component.html',
  styleUrls: [ './member-select-input.component.css' ]
} )
export class MemberSelectInputComponent implements OnInit {

  constructor ( private _requestResearchService: RequestResearchService ) { }

  //search for input 
  @ViewChild( 'filterInput' ) filterInput: ElementRef;

  //receives the maximum number of elements that can be added
  @Input( 'maxMembers' ) maxMembers: number = 8;
  private _maxMembersCounter: number = 0;

  //input id
  @Input('inputId') inputId: string = '';

  //disables input
  @Input('disabled') disabled: boolean = false;

  //receives a list of members to be excluded
  @Input( 'membersToExclude' ) membersToExclude: UserI[] = [];
  @Input('defaultSelectedUsers') set defaultSelectedUsers(ids:number[]){
    this._maxMembersCounter = 0;
    const filter = JSON.stringify({
      where: {id: {inq:ids} }
    });
    this._requestResearchService.getUserInfo( filter ).subscribe({
      next:(resp:UserI[]) => {
        if(resp.length > 0) this._maxMembersCounter += resp.length;
        if ( this._maxMembersCounter >= this.maxMembers ) this.hideInput = true;

        const data = [...this.membersToAdd, ...resp]
        this.membersToAdd = data;
      },
      error:(err)=>console.log(err)
    })
  }


  //label title
  @Input( 'title' ) title: string = '';

  //emits the members to be added
  @Output( 'addedMembers' ) addedMembers: EventEmitter<UserI[]> = new EventEmitter();

  //hides the input or not
  public hideInput: boolean = false;

  //stores the members to be added
  public membersToAdd: UserI[] = [];

  //allows to show or not to show the search dialog box
  public showDialog: boolean = false;

  //stores the list of filtered members
  public filteredMembers: UserI[] = [];

  //checks whether the input is valid or not
  public isInvalid: boolean = false;

  //checks whether you click inside or outside the input
  public clickedInside: boolean = false;

  ngOnInit(): void {
    //
  }

  ngAfterViewInit(): void {
    //members filter
    fromEvent<Event>( this.filterInput.nativeElement, 'keyup' ).pipe(
      map( ( event: Event ) => {
        let value = ( event.target as HTMLInputElement ).value.toLowerCase();
        let usersQuery = [ { Document: { regexp: `/${ value }/` } }, { email: { regexp: `/${ value }/` } } ];
        let filter = JSON.stringify( { where: { or: usersQuery }, limit: 3 } )
        if ( value.length < 3 ) this.filteredMembers = [];
        this.showDialog = value.length > 3;
        return value.length > 3 && !this.disabled ? filter : '';
      } ),
      debounceTime( 300 ),
      /*  distinctUntilChanged(), */
      filter( ( value: string ) => value.length > 3 ),
      switchMap( ( value: string ) => this._requestResearchService.getUserInfo( value ) )
    ).subscribe( {
      next: ( users: UserI[] ) => {
        this.filterMembers( users );
      }, error: ( err: Error ) => console.log( err )

    } )
  }

  /**
   * hides the members that are already part of the current group or those to be added
   * @param users 
   */
  filterMembers( users: UserI[] ) {
    let toExclude: UserI[] = [...this.membersToAdd, ...this.membersToExclude];

    //omits users to be added
    toExclude.forEach( ( u ) => {
      users.forEach( ( e: UserI, i ) => {
        if ( e.id === u.id ) users.splice( i, 1 );
      } )
    } )

    this.filteredMembers = users;
  }

  /**
  * temporarily stores possible group members
  * @param data 
  */
  addMember( data: UserI ) {
    if(this.maxMembers === 0)
      return;

    if ( this._maxMembersCounter < this.maxMembers ) {
      this._maxMembersCounter += 1;
      this.setMembers( data );
    }

    if ( this._maxMembersCounter >= this.maxMembers ) 
      this.hideInput = true;

    this.isInvalid = false;
  }

  /**
  * adds an item to the list of possible members to be added
  * @param data 
  */
  setMembers( data: UserI ) {
    this.filterInput.nativeElement.value = '';
    this.filteredMembers = [];

    this.membersToAdd.push( data );
    this.addedMembers.emit( this.membersToAdd );
  }

  /**
  * deletes possible members
  * @param member 
  */
  deleteMember( userToRemove: UserI ) {
    this.hideInput = false;
    if ( this.maxMembers > 0 )
      this._maxMembersCounter -= 1;

    this.membersToAdd.forEach( ( addedUser, index ) => {
      if ( addedUser.id === userToRemove.id )
        this.membersToAdd.splice( index, 1 );
    } );

    this.addedMembers.emit( this.membersToAdd );
  }

  /**
   * when clicking on the input
   * @returns 
   */
  onClick(){
    if(this.disabled)
      return;
      
    this.showDialog = this.filteredMembers.length > 0
  }

  /**
  * clears the selector data
  */
  clearData() {
    this.filterInput.nativeElement.value = '';
    this.filteredMembers = [];
    this.membersToAdd = [];
    this.membersToExclude = [];
    this._maxMembersCounter = 0;
    this.hideInput = false;

    this.isInvalid = false;
    this.clickedInside = false;
  }

  @HostListener( 'document:click', [ '$event' ] )
  clickOutside( e ) {
    if(e.target.id === this.inputId){
      this.showDialog = true;
      this.clickedInside = true;
    }
    else if(!e.target.closest( '.member-select__dialog' )){
      this.showDialog = false;
      if( this.membersToAdd.length === 0 && this.clickedInside){
        this.isInvalid = true;
        this.clickedInside = false;
      }
    }
  }
}
