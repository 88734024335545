import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MemberListComponent } from './member-list.component';
import { AddMemberModalComponent } from './add-member-modal/add-member-modal.component';
import { MemberCardComponent } from './member-card/member-card.component';
import { ReactiveFormsModule } from '@angular/forms';
import { EditMemberModalComponent } from './edit-member-modal/edit-member-modal.component';
import { SelectorsModule } from 'src/app/components/shared/selectors/selectors.module';
import { FormInputsModule } from 'src/app/components/shared/form-inputs/form-inputs.module';
import { FilterInputModule } from 'src/app/components/shared/filter-input/filter-input.module';
import { SectionsModule } from './sections/sections.module';
import { GroupStatsModule } from '../../shared/group-stats/group-stats.module';

@NgModule({
  declarations: [
    MemberListComponent,
    
    AddMemberModalComponent,
    EditMemberModalComponent,
    MemberCardComponent,
  ],
  imports: [
    SectionsModule,
    SelectorsModule,
    FormInputsModule,
    FilterInputModule,
    ReactiveFormsModule,
    GroupStatsModule,
    CommonModule
  ],
  exports:[
    MemberListComponent,
  ]
})
export class MemberListModule { }
