import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { forkJoin } from 'rxjs';
import { InfoFileData } from 'src/app/models/logicModels/infoFileData';
import { RequestResearchService } from 'src/app/services/research/research.service';
import { environment } from 'src/environments/environment';

interface Select{
  id:number | string;
  text:string;
}

@Component( {
  selector: 'request-scientific-add-form',
  templateUrl: './scientific-add-form.component.html',
  styleUrls: [ './scientific-add-form.component.css' ]
} )
export class ScientificAddFormComponent {

  constructor ( private _fb: FormBuilder, private _requestResearchService: RequestResearchService ) { }

  //allows to show or hide the product form
  private _showForm: boolean = false;
  @Input( 'showForm' ) set showForm(value: boolean ) {
    this._showForm = value;
    if(value === true) this.onOpenForm();
  }

  get showForm(){
    return this._showForm;
  }

  //receives the id of the current group
  @Input( 'groupId' ) groupId: number = 0;

  //emits a call each time a new product is created
  @Output( 'createdProduct' ) createdProduct: EventEmitter<void> = new EventEmitter();

  //issues a call when the modal is closed
  @Output( 'closeModal' ) closeModal: EventEmitter<void> = new EventEmitter();

  //form
  public fileForm: FormGroup = this._fb.group( {
    name: [ '', Validators.required ],
    type: [ '', Validators.required ],
    subtype: [ '', Validators.required ],
    project: [ '' ],
    addFile: [ false ],
    linkDrive: [ '', Validators.required ],
    publishDate: [ '' ],
    description: [ '' ],
  } );

  public projectList: Select[] = [];
  public productTypeList: Select[] = [];
  public productSubtypeList: Select[] = [];
  public isFileUploaded: boolean = false;
  public fileData?: InfoFileData = undefined;
  public isSubmitting:boolean = false;
  public schoolList: Select[] = [];
  public urlBackV1:any = environment.urlBackV1;

  private onOpenForm() {
    this.productSubtypeList = [];
    this.schoolList = this._requestResearchService.currentSchools.map(e => ({ id: e.id, text: e.NameTSchool }))
    forkJoin({
      productTypes: this._requestResearchService.getProductTypes(),
      projects: this._requestResearchService.getProjects(JSON.stringify({ where: { researchGroupID: this.groupId } })),
    }).subscribe({
      next: ({ productTypes, projects }: any) => {
        this.productTypeList = productTypes.map(e => ({ id: e.id, text: e.name }));
        this.projectList = projects.map(e => ({ id: e.id, text: e.name }));
      }, error: (err: Error) => console.log(err)
    });
  }

  /**
   * creates the file
   * @returns 
   */
  submitProduct() {
    const addFile = this.fileForm.get( 'addFile' )?.value;
    const data = {
      researchGroupID: this.groupId,
      typeProductID: this.fileForm.get( 'subtype' )?.value,
      categoryProductID: this.fileForm.get( 'type' )?.value,
      description: this.fileForm.get( 'description' )?.value,
      projectID: this.fileForm.get( 'project' )?.value,
      linkDrive: addFile ? this.urlBackV1 + this.fileData?.urlFile : this.fileForm.get( 'linkDrive' )?.value,
      date: this.fileForm.get( 'publishDate' )?.value || null,
      name: this.fileForm.get( 'name' )?.value,
    }

    if ( !this.fileForm.valid || ( this.fileData === undefined && addFile ) || this.isSubmitting ) return;
      
    this.isSubmitting = true;
    this._requestResearchService.setProduct( data ).subscribe( {
      complete: () => {
        this.closeFormModal();
        this.createdProduct.emit();
      }, error: ( err: Error ) => console.log( err )
    } )
  }

  /**
   * gets the list of subtypes
   * @param categoryID
   */
  getSubtype( categoryID ) {
    this.fileForm.get('subtype')?.reset('');
    this.productSubtypeList = [];

    let filter = JSON.stringify( {
      where: { categoryProductID: categoryID }
    } )

    this._requestResearchService.getProductSubtypes( filter ).subscribe( {
      next: ( resp ) => {
        this.productSubtypeList = resp.map(e => ({id:e.id, text:e.name}));
      }, error: ( err: Error ) => console.log( err )
    } );
  }

  /**
   * gets the data from the file
   * @param data 
   */
  getFile( data: InfoFileData ) {
    this.fileData = data;
    this.isFileUploaded = true;

    this.fileForm.patchValue({
      linkDrive: this.urlBackV1 + this.fileData?.urlFile,
      addFile: false
    })
  }

  /**
  * clear the form data
  */
  clearForm() {
    this.fileForm.reset( {
      type: '',
      subtype: '',
      project: '',
      addFile: false,
    } );
  }

  /**
   * close modal form
   */
  closeFormModal() {
    this.clearForm();

    this.showForm = false;
    this.closeModal.emit();
    this.isSubmitting = false;
    this.fileData = undefined;
    this.isFileUploaded = false;
  }

}
