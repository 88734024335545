<div class="event-card">

  <button class="button button--edit" (click)="editEvent.emit()" 
    *ngIf="rolesService.allowedRoles.ADMIN || rolesService.allowedRoles.LEADER">
    <em class="icon-i-filled-edit-directive"></em>
  </button>

  <p class="event-card__text">
    <span class="event-card__text--bold">Nombre del evento: </span>
    {{ eventInfo.name }}
  </p>
  <p class="event-card__text">
    <span class="event-card__text--bold">Fecha: </span>
    {{ eventInfo.date | date:'dd/MM/yyyy' }}
  </p>
  <p class="event-card__text">
    <span class="event-card__text--bold">Descripción: </span>
    {{ eventInfo.description }}
  </p>

  <p class="event-card__text">
    <span class="event-card__text--bold">Escuelas: </span>
    {{ eventInfo.unitsParticipant }}
  </p>
</div>