import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { forkJoin, map, Subscription } from 'rxjs';
import { StatsI } from 'src/app/models/researchModels/stats';
import { ExcelService } from 'src/app/services/research/excel.service';
import { RequestResearchService } from 'src/app/services/research/research.service';

import colorList from "../colorList.json";

@Component( {
  selector: 'request-group-stats',
  templateUrl: './group-stats.component.html',
  styleUrls: [ './group-stats.component.css' ]
} )
export class GroupStatsComponent implements OnInit, OnDestroy {

  constructor (
    private _requestResearchService: RequestResearchService,
    private _searchService: ExcelService
  ) {
  }

  //Issues a call when the modal is closed
  @Output( 'closeModal' ) closeModal: EventEmitter<void> = new EventEmitter();

  //receives a list of loaded group ids
  @Input( 'groupId' ) groupId:number[] = [];

  //allows to show or hide the date filter
  @Input( 'showDateFilter' ) showDateFilter:boolean = true;

  //allows to set the modal status
  private _showStats:boolean = false;
  @Input( 'showStats' ) set showStats (value: boolean) {
    this._showStats = value;
    if(this._showStats) 
      this.loadStats();
  };

  get showStats(){
    return this._showStats;
  }

  //stores all filters for stats panel
  @Input( 'filterList' ) filterList: any[] = [];

  //check if stats are being loaded
  public isLoadingStats: boolean = false;

  //stores information such as the color, quantity...
  public stats: StatsI[] = [];

  //stores the list of child statistics
  public innerStats?: { barName: string, stats: any[] } = undefined;

  //stores the selected filter
  public selectedFilter: string = 'Producción científica';

  //sets the start date for filtering stats
  public fromDate: Date = new Date( 2022, 10, 30 );

  //sets the end date for filtering stats
  public toDate: Date = new Date();

  //
  public colorIndex:number = 1;

  //stores the list of projects for download as an excel file
  public excelData: any = undefined;

  private _subscription: Subscription = new Subscription();


  ngOnInit(): void {
    this._requestResearchService.generateStatsExcel().subscribe( {
      next: ( excelContent: any ) => {
        this.excelData = excelContent;
      }, error: ( err: Error ) => console.log( err )
    }
    )
  }

  /**
   * closes and issues modal status
   */
  closeStatsModal() {
    this.showStats = false;
    this.closeModal.emit();
    this.innerStats = undefined;
    this._subscription.unsubscribe();
  }

  /**
   * assign statistics
   */
  loadStats() {
    this.stats = [];
    this.isLoadingStats = true;
    this._subscription = forkJoin( {
      members: this.getMembersCount(),
      projects: this.getProjectsCount(),
      products: this.getProductTypes(),
    } ).subscribe( {
      next: ( { members, projects, products }: any ) => {

        this.stats.push( {
          color: this.generateRandomColor(),
          qty: this.groupId.length,
          name: 'Grupos',
          filter: [ 'Grupos, Proyectos, Eventos' ]
        } );

        this.stats.push( {
          color: this.generateRandomColor(),
          qty: projects.count,
          name: 'Proyectos',
          filter: [ 'Proyectos', 'Grupos, Proyectos, Eventos' ]
        } );

        this.stats.push( {
          color: this.generateRandomColor(),
          qty: products.eventsQty,
          name: 'Eventos',
          filter: [ 'Proyectos' ]
        } );

        products.forEach( e => {
          this.stats.push( {
            barId: e.id,
            color: this.generateRandomColor(),
            qty: e.count,
            name: e.name,
            filter: [ 'Producción científica' ],
            innerStats: e.subtypes.map( i => {
              return {
                color: this.generateRandomColor(),
                qty: i.count,
                name: i.name,
                filter: [ 'Producción científica' ],
              }
            } )
          } );
        } );

        this.stats.push( {
          color: this.generateRandomColor(),
          qty: members.membersQty ,
          name: 'Total personas inscritas',
          filter: [ 'Inscritos' ],
        } );

        this.stats.push( {
          color: this.generateRandomColor(),
          qty: members.professorQty ,
          name: 'Cantidad de docentes',
          filter: [ 'Inscritos' ]
        } );

        this.stats.push( {
          color: this.generateRandomColor(),
          qty: members.studentsQty  ,
          name: 'Cantidad de alumnos',
          filter: [ 'Inscritos' ]
        } );

        this.stats.push( {
          color: this.generateRandomColor(),
          qty: members.inactiveProfessorsQty,
          name: 'Docentes inactivos',
          filter: [ 'Inscritos' ]
        } );

        this.stats.push( {
          color: this.generateRandomColor(),
          qty: members.inactiveStudentsQty,
          name: 'Alumnos inactivos',
          filter: [ 'Inscritos' ]
        } );

        this.isLoadingStats = false;
      }, error: ( err: Error ) => console.log( err )
    } );
  }

  /**
   * obtains the number of members and filters them
   * @returns 
   */
  getMembersCount() {
/*     let filters = {
      membersQty: JSON.stringify( { researchGroupID: { inq: this.groupId } } ),
      professorQty: JSON.stringify( { and: [ { researchGroupID: { inq: this.groupId } }, { isProfessor: true } ] } ),
      studentsQty: JSON.stringify( { and: [ { researchGroupID: { inq: this.groupId } }, { isProfessor: false } ] } ),
      inactiveProfessorsQty: JSON.stringify( { and: [ { researchGroupID: { inq: this.groupId } }, { isProfessor: true }, { isActive: false } ] } ),
      inactiveStudentsQty: JSON.stringify( { and: [ { researchGroupID: { inq: this.groupId } }, { isProfessor: false }, { isActive: false } ] } )

    }
    return forkJoin( {
      membersQty: this._requestResearchService.getMembersCount( filters.membersQty ),
      professorQty: this._requestResearchService.getMembersCount( filters.professorQty ),
      studentsQty: this._requestResearchService.getMembersCount( filters.studentsQty ),
      inactiveProfessorsQty: this._requestResearchService.getMembersCount( filters.inactiveProfessorsQty ),
      inactiveStudentsQty: this._requestResearchService.getMembersCount( filters.inactiveStudentsQty ),
    } )  */

    let filter = JSON.stringify({ where: { researchGroupID: { inq: this.groupId } }});

    return this._requestResearchService.getMembers( filter ).pipe(
      map( resp => {
        return {
          membersQty: resp.length,
          professorQty: resp.filter( e => e.isProfessor ).length,
          studentsQty: resp.filter( e => !e.isProfessor ).length,
          inactiveProfessorsQty: resp.filter( e => e.isProfessor && !e.isActive ).length,
          inactiveStudentsQty: resp.filter( e => !e.isProfessor && !e.isActive ).length,
        }
      } )
    )
  }

  /**
   * obtains the number of projects
   * @returns 
   */
  getProjectsCount() {
    let filter = JSON.stringify( {
      and: [
        { researchGroupID: { inq: this.groupId } },
        { dateStart: { between: [ new Date( this.fromDate ), new Date( this.toDate ) ] } },
      ]
    } );

    return this._requestResearchService.getProjectsQuantity(filter);
  }

  /**
   * obtains and filters the products
   * @returns 
   */
  getProductTypes() {

    let filter = JSON.stringify( {
      where: {
        and: [
          { researchGroupID: { inq: this.groupId } },
/*           { date: { between: [ new Date( this.fromDate ), new Date( this.toDate ) ] } }, */
        ]
      }
    } );

    return this._requestResearchService.getProductsQty(filter).pipe(
      map((e:any) => {

        let array = e.map(item => { return{ 
          id: item.id,
          name: item.Type, 
          count: item.TypeCount,
          subtypes: [ { name: item.Subtype, count: item.SubTypeCount} ]
        }});

        let unique: any = []
        array.forEach( item => {
          if ( !unique.find( i => {
            if ( i.name === item.name ) {
              i.count += item.count;
              i.subtypes.push({ name: item.subtypes[0].name, count: item.subtypes[0].count })
              return true;
            } else {
              return false;
            }
          } ) ) { unique.push( item ) }
        } )
      return unique;
      })
    );
  }

  /**
   * gets the from date
   * @param event 
   */
  getFromDate( event ) {
    this.innerStats = undefined;
    this.fromDate = event;
    this.loadStats();
  }

  /**
   * gets the to date
   * @param event 
   */
  getToDate( event ) {
    this.innerStats = undefined;
    this.toDate = event;
    this.loadStats();
  }

  /**
   * sets the stats filter
   * @param filter 
   */
  changeFilter( filter: string ) {
    this.innerStats = undefined;
    this.selectedFilter = filter;
  }

  /**
   * displays additional stats depending on the clicked bar
   * @param barId 
   */
  showInnerStats( barId: number ) {
    this.innerStats = undefined;
    this.stats.forEach( e => {
      if ( e.innerStats && e.innerStats?.length > 0 && e.barId === barId ) {
        this.innerStats = {
          barName: e.name,
          stats: [ ...e.innerStats ]
        };
      }
    } )
  }

  generateRandomColor() {
    this.colorIndex += 1;
    let hsl =  colorList[this.colorIndex];
    return hsl;
  }

  downloadExcel(){
    this._searchService.toExcel(this.excelData,'Reporte');
  }

  ngOnDestroy(): void {
    this._subscription.unsubscribe();
  }

}
