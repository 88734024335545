import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { ProductI } from 'src/app/models/researchModels/product';
import { ResearchRolesService } from 'src/app/services/research/research-roles.service';
import { RequestResearchService } from 'src/app/services/research/research.service';

@Component( {
  selector: 'request-scientific-production',
  templateUrl: './scientific-production.component.html',
  styleUrls: [ './scientific-production.component.css' ]
} )
export class ScientificProductionComponent implements OnInit, OnDestroy {

  constructor ( 
    private _requestResearchService: RequestResearchService,
    public rolesService: ResearchRolesService
  ) { }

  //emits a call each time the project section is closed
  @Output( 'closeSection' ) closeSection: EventEmitter<void> = new EventEmitter();

  private _selectedGroupId:number = 0;
  @Input( 'selectedGroupId' ) set selectedGroupId(value:number){
    this._selectedGroupId = value;
    this.productList = [];
    if ( value !== 0 ) 
      this.getProducts();
  }

  get selectedGroupId(){
    return this._selectedGroupId;
  }

  //shows or hides the add file modal
  public showAddFileModal = false;

  //shows or hides the edit products modal
  public showEditModal = false;

  //stores the list of products
  public productList: ProductI[] = [];

  //stores the id of the product to be edited
  public selectedProduct: ProductI | null = null;

  //checks if the product list is being loaded
  public isLoadingProducts: boolean = false;

  private _subscription: Subscription = new Subscription();

  ngOnInit(): void {
    //
  }

  /**
   * sets the product id and opens the edit modal
   * @param projectId 
   */
  editProduct( product: ProductI ) {
    this.showEditModal = true;
    this.selectedProduct = product;
  }

  /**
   * obtains the list of products based on the current group
   */
  getProducts() {
    this.isLoadingProducts = true;
    let filter = JSON.stringify( {
      include: [ 'projects', 'typeProduct','categoryProduct','cooperationUnitSchool' ],
      where: { and:[{ researchGroupID: this.selectedGroupId},{isEvent:false}] }
    } );

    this._subscription.unsubscribe();
    this._subscription = this._requestResearchService.getProducts( filter ).subscribe( {
      next: ( resp:any ) => {
        this.isLoadingProducts = false;
        this.productList = resp;
      }, error: ( err: Error ) => console.log( err )
    } );
  }

  ngOnDestroy(): void {
    this._subscription.unsubscribe();
  }
}
