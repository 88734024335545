<div class="group-form__input" [formGroup]="parentForm">
  <label for="" class="">{{ title }}</label>
  <textarea (input)="onInput($event)" rows="5" class="input form-control input--text" [formControlName]="controlName"
    [ngClass]="parentForm.get( controlName )?.invalid && parentForm.get( controlName )?.touched ? 'input--invalid' : ''"
    placeholder="{{ placeholder }}">
  </textarea>

  <small class="text--invalid" *ngIf="parentForm.get( controlName )?.hasError('required') && parentForm.get( controlName )?.touched">
    Este campo es obligatorio
  </small>
</div>