<div class="events-container">
  <div class="events-container__header">
    <div class="events-container__title-container">
      <button class="button button-back" (click)="onCloseSection()">
        <em class="icon-i-back"></em>
      </button>
      <h2 class="events-text events-text--bold events-text--lg">EVENTOS</h2>
    </div>

    <div class="events-container__button-container" *ngIf="rolesService.allowedRoles.ADMIN || rolesService.allowedRoles.LEADER">
      <button type="button" class="button button--dark button--plus-icon" (click)="showModal = true">
        <em class="icon-i-plus-circle"></em>
        Agregar evento
      </button>
      <add-event-form [showModal]="showModal" (closeModal)="showModal = false" (createdEvent)="getEvents()" [groupId]="selectedGroupId">
      </add-event-form>
    </div>
  </div>

  <div class="events-container__events">
    <request-event-card *ngFor="let event of eventList" [eventInfo]="event" (editEvent)="editEvent(event.id!)"></request-event-card>
  </div>

  <edit-event-form [onShowForm]="{ showForm:showEditModal, eventId:selectedEventId }" (closeModal)="showEditModal = false" 
    (editedEvent)="getEvents()" [groupId]="selectedGroupId" *ngIf="rolesService.allowedRoles.ADMIN || rolesService.allowedRoles.LEADER">
  </edit-event-form>
</div>
