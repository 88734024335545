import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ProductI } from 'src/app/models/researchModels/product';
import { ResearchRolesService } from 'src/app/services/research/research-roles.service';

@Component({
  selector: 'request-event-card',
  templateUrl: './event-card.component.html',
  styleUrls: ['./event-card.component.css']
})
export class EventCardComponent implements OnInit {

  constructor(public rolesService: ResearchRolesService) { }

  // gets event information
  @Input( 'eventInfo' ) eventInfo: ProductI = {} as ProductI;

  //issues a call when you click on the edit button
  @Output('editEvent') editEvent: EventEmitter<any> = new EventEmitter();

  ngOnInit(): void {
    //
  }

}
