<div class="product-container">
  <div class="product-container__header">
    <div class="product-container__title-container">
      <button class="button button-back" (click)="closeSection.emit()">
        <em class="icon-i-back"></em>
      </button>
      <h2 class="product-text product-text--bold product-text--lg">DOCUMENTOS DE PRODUCCIÓN CIENTÍFICA</h2>
    </div>

    <div class="product-container__button-container" *ngIf="rolesService.allowedRoles.ADMIN || rolesService.allowedRoles.LEADER">
      <button class="button button--dark button--plus-icon" (click)="showAddFileModal = true">
        <em class="icon-i-plus-circle"></em>
        Agregar Documento
      </button>
      <request-scientific-add-form [showForm]="showAddFileModal" [groupId]="selectedGroupId"
        (createdProduct)="getProducts()" (closeModal)="showAddFileModal = false">
      </request-scientific-add-form>
    </div>
  </div>

  <div class="product-container__projects">
    <request-scientific-card *ngFor="let product of productList" [productInfo]="product" (editProduct)="editProduct(product)" > 
    </request-scientific-card>
  </div>

  <request-scientific-edit-form [onShowForm]="{ showForm:showEditModal, product:selectedProduct }" [groupId]="selectedGroupId"
    (editedProduct)="getProducts()" (closeModal)="showEditModal = false"
    *ngIf="(rolesService.allowedRoles.ADMIN || rolesService.allowedRoles.LEADER) && selectedProduct">
  </request-scientific-edit-form>

</div>
