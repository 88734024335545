<form class="event-form__main" [formGroup]="eventsForm" [ngClass]="showModal ? 'event-form__main--show' : ''" (submit)="submitEvent()">
  <div class="event__input">
    <request-text-input [title]="'Nombre del evento *'" [placeholder]="'Escriba el nombre del evento'"
      [parentForm]="eventsForm" [controlName]="'name'">
    </request-text-input>
  </div>

  <div class="event__input">
    <request-date-input [title]="'Fecha del evento *'" [parentForm]="eventsForm" [controlName]="'date'">
    </request-date-input>
  </div>

  <div class="event__input">
    <request-textarea-input [title]="'Descripción del evento'" [placeholder]="'Escriba la descripción del evento'"
      [parentForm]="eventsForm" [controlName]="'description'">
    </request-textarea-input>
  </div>

  <div class="event__input">
    <request-text-input [title]="'Escuelas que participan *'" [placeholder]="'Escriba las escuelas que participan'"
      [parentForm]="eventsForm" [controlName]="'unitsParticipant'">
    </request-text-input>
  </div>

  <div class="event__input">
    <request-checkbox-input [title]="'Publicar como noticia'" [inputId]="'news-checkbox'" [parentForm]="eventsForm" [controlName]="'news'"
    (checkBox)="onCheckboxCheck()">
    </request-checkbox-input>
  </div>

  <ng-container *ngIf="eventsForm.get('news')?.value">
    <div class="event__input">
      <request-select-input [items]="schoolList" [title]="'Escuela encargada'" [placeholder]="'Seleccione una escuela'"
        [parentForm]="eventsForm" [controlName]="'school'" (onSelectChange)="getCareArea($event)">
      </request-select-input>
    </div>

    <div class="event__input">
      <request-select-input [items]="careAreaList" [title]="'Área de atención'" [placeholder]="'Seleccione un área'"
        [parentForm]="eventsForm" [controlName]="'careArea'" [ngClass]="careAreaList.length === 0 ? 'disabled': ''">
      </request-select-input>
    </div>
    
    <div class="event__input">
      <upload-file-box [title]="'Buscar imágen'" [maxMbSize]="25000000" [smallTitle]="'pdf (max 25MB)'" (responseDataFile)="getImageData($event)" 
      [containerName]="'scientificinfo'">
      </upload-file-box>
    </div>
  </ng-container>

  <button type="submit" class="button button--dark" 
    [ngClass]="!eventsForm.valid || isSubmitting ? 'button--disabled' : ''">
    Crear Evento
  </button>
</form>

<div class="modal-bg" (click)="closeEventModal()" [ngClass]="showModal ? 'modal-bg--show' : ''">
</div>