import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { map, Subscription } from 'rxjs';
import { MemberI } from 'src/app/models/researchModels/member';
import { UserI } from 'src/app/models/researchModels/user';
import { ResearchRolesService } from 'src/app/services/research/research-roles.service';
import { RequestResearchService } from 'src/app/services/research/research.service';

@Component( {
  selector: 'request-member-list',
  templateUrl: './member-list.component.html',
  styleUrls: [ './member-list.component.css' ]
} )
export class MemberListComponent implements OnInit, OnDestroy {

  constructor (
    private _requestResearchService: RequestResearchService,
    public rolesService: ResearchRolesService
  ) { }

  //emits an event when the members section is closed
  @Output( 'closeMemberList' ) closeMemberList: EventEmitter<void> = new EventEmitter();

  private _groupId: number = 0;
  @Input( 'selectedGroupId' ) set selectedGroupId( value: number ) {
    this._groupId = value;
    this.memberList = [];
    this.filteredList = [];
    if ( value !== 0 )
      this.refreshMemberList();
  }

  get selectedGroupId() {
    return this._groupId;
  }

  //stores the original copy of the members of the current group
  public memberList: MemberI[] = [];

  //stores the list of users to exclude
  public userListToExclude: UserI[] = [];

  //receives a list of filtered members
  public filteredList: MemberI[] = [];

  //stores the member to be edited
  public memberToEdit: MemberI = {} as MemberI;

  //shows or hides the add modal
  public showAddMemberModal: boolean = false;

  //shows or hides the add modal
  public showStatsModal: boolean = false;

  //shows or hides the edit modal
  public showEditModal: boolean = false;

  //stores the name of the section to be displayed depending on the clicked button
  public sections: string = 'mainSection'

  //checks if the member list is being loaded
  public isLoadingMembers: boolean = false;

  private _subscription: Subscription = new Subscription();

  ngOnInit(): void {
    //
  }

  /**
   * filters the members of the list each time a value is entered in the select
   * @param event
   * @returns
   */
  filterMemberList( event ) {
    this.filteredList = this.memberList;
    const value = event.toLowerCase();
    if ( value === '' || value.length < 4 )
      return;

    const filtered = this.memberList.filter( m => m.userapp?.email.toLowerCase().includes( value ) );
    this.filteredList = filtered;
  }

  /**
   * shows and sets edit member modal
   * @param member
   */
  getMemberToEdit( member: MemberI ) {
    this.memberToEdit = member;
    this.showEditModal = true;
  }

  /**
  * updates the list of members
  * @param newList
  */
  public refreshMemberList() {
    this.isLoadingMembers = true;
    this._subscription.unsubscribe();
    this._subscription = this.getMemberList().subscribe( {
      next: ( resp ) => {
        this.memberList = resp;
        this.filteredList = this.memberList;
        this.userListToExclude = this.memberList.map((m:any) => m.userapp);
        this.isLoadingMembers = false;
      },
      error: ( err ) => console.error( err )
    } );
  }

  /**
  * gets the list of members of a group
  */
  getMemberList() {
    const filter = JSON.stringify( {
      where: { researchGroupID: this.selectedGroupId },
      include: [ { userapp: 'UserDocuments' } ],
    } )
    return this._requestResearchService.getMembers( filter );
  }

  getProjectMembers( projectIds: any[] ) {
    const filter = JSON.stringify( {
      where: { projectID: { inq: [ projectIds ] } }
    } );

    return this._requestResearchService.getProjectMembers( filter ).pipe(
      map( ( projectMembers ) => {
        const membersId = projectMembers.map( e => e.memberID);
        return { isInUse: membersId.includes(99) }
      } )
    );
  }

  ngOnDestroy(): void {
    this._subscription.unsubscribe();
  }

}