import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProjectsComponent } from './projects.component';
import { ReactiveFormsModule } from '@angular/forms';
import { AddProjectFormComponent } from './add-project-form/add-project-form.component';
import { EditProjectFormComponent } from './edit-project-form/edit-project-form.component';
import { ProjectCardComponent } from './project-card/project-card.component';
import { TrimWhitespacePipe } from 'src/app/pipes/trim-whitespace.pipe';
import { MemberPillModule } from 'src/app/components/shared/member-pill/member-pill.module';
import { FormInputsModule } from 'src/app/components/shared/form-inputs/form-inputs.module';
import { SelectorsModule } from 'src/app/components/shared/selectors/selectors.module';
import { UploadFileBoxModule } from 'src/app/components/shared/upload-file-box/upload-file-box.module';
import { DatePillModule } from 'src/app/components/shared/date-pill/date-pill.module';

@NgModule({
  declarations: [
    ProjectsComponent,
    AddProjectFormComponent,
    EditProjectFormComponent,
    ProjectCardComponent,
    TrimWhitespacePipe,
  ],
  imports: [
    MemberPillModule,
    FormInputsModule,
    SelectorsModule,
    ReactiveFormsModule,
    UploadFileBoxModule,
    DatePillModule,
    CommonModule
  ],
  exports:[
    ProjectsComponent,
  ]
})
export class ProjectsModule { }
