import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component( {
  selector: 'request-member-pill',
  templateUrl: './member-pill.component.html',
  styleUrls: [ './member-pill.component.css' ]
} )
export class MemberPillComponent implements OnInit {

  constructor () { 
    //
  }

  //receives the member's name
  @Input( 'content' ) content: string = '';

  //Emits a call each time a pill is removed
  @Output( 'closePill' ) closePill: EventEmitter<void> = new EventEmitter();

  ngOnInit(): void { 
    //
   }

  //closes or removes a pill
  onClosePill(): void {
    this.closePill.emit();
  }

}
