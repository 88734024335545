import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'request-text-input',
  templateUrl: './text-input.component.html',
  styleUrls: ['./text-input.component.css']
})
export class TextInputComponent implements OnInit {

  constructor() { 
    //
  }

  //receives the parent form
  @Input('parentForm') parentForm:FormGroup = new FormGroup({});

  //receives the name of the control name
  @Input('controlName') controlName:string = '';

  //receives label title
  @Input('title') title:string = '';
  
  @Input('placeholder') placeholder:string = '';

  ngOnInit(): void {
    //
  }

}
