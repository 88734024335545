import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { Observable } from 'rxjs';
import { TypeRequest } from '../models/dbModels/typeRequest';
import { SubTypeRequest } from '../models/dbModels/subTypeRequest';
import { Request } from '../models/dbModels/request';
import { FileReq } from '../models/dbModels/fileReq';
import { RequestHistory } from '../models/dbModels/requestHistory';

@Injectable({
  providedIn: 'root'
})
export class RequestService {

  constructor(
    private _httpService: HttpService
  ) { }
  
  getTypeRequest(filter?: string): Observable<TypeRequest[]> {
    let url: string = `/TypesRequest`
    if (filter)
      url = `/TypesRequest?filter=${filter}`

    return this._httpService.httpGet(url);
  }

  getSubTypeRequest(filter?: string): Observable<SubTypeRequest[]> {
    let url: string = `/SubTypesRequest`
    if (filter)
      url = `/SubTypesRequest?filter=${filter}`
    
    return this._httpService.httpGet(url);
  }

  getRequest(filter?: String): Observable<Request[]> {
    let url: string = `/Requests`
    if (filter)
      url = `/Requests?filter=${filter}`
    
    return this._httpService.httpGet(url);
  }

  getRequestAdmins(filter?: string): Observable<Request[]> {
    let url: string = `/userApps/me/roleMappings`
    return this._httpService.httpGet(url);
  }

  createRequest(data?: Request) {
    let url: string = `/Requests`
    return this._httpService.httpPost(url, data);
  }

  createRequestHistory(data?: RequestHistory) {
    let url: string = `/RequestHistories`
    return this._httpService.httpPost(url, data);
  }
  
  createFileReq(data?: FileReq) {
    let url: string = `/FilesReq`
    return this._httpService.httpPost(url, data);
  }

  getFilesReq(filter?: string): Observable<FileReq[]> {
    let url: string = `/FilesReq`
    if (filter)
      url = `/FilesReq?filter=${filter}`
    
    return this._httpService.httpGet(url);
  }
  patchRequest(id: number | undefined, data: object): Observable<FileReq[]> {
    let url: string = `/Requests/${id}`
    return this._httpService.httpPatch(url, data);
  }
  
}


