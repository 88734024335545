import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EventsComponent } from './events.component';
import { EventCardModule } from './event-card/event-card.module';
import { SelectorsModule } from 'src/app/components/shared/selectors/selectors.module';
import { FormInputsModule } from 'src/app/components/shared/form-inputs/form-inputs.module';
import { ReactiveFormsModule } from '@angular/forms';
import { AddEventFormComponent } from './add-event-form/add-event-form.component';
import { EditEventFormComponent } from './edit-event-form/edit-event-form.component';
import { UploadFileBoxModule } from 'src/app/components/shared/upload-file-box/upload-file-box.module';

@NgModule({
  declarations: [
    EventsComponent,
    AddEventFormComponent,
    EditEventFormComponent
  ],
  imports: [
    SelectorsModule,
    FormInputsModule,
    EventCardModule,
    ReactiveFormsModule,
    UploadFileBoxModule,
    CommonModule
  ],
  exports:[
    EventsComponent
  ]
})
export class EventsModule { }
