<div class="research-select">
  <label for="">{{ title }}</label>
  <div class="research-select__input-container" [hidden]="hideInput">
    <!-- #region SEARCH INPUT -->
    <span class="icon-search"></span>
    <input type="text" class="research-select__input" autocomplete="off" id="{{ inputId }}"
      placeholder="Seleccionar línea de investigación" tabindex="-1" readonly
      [ngClass]="isInvalid && validation ? 'research-select__input--invalid' : ''"
      #filterInput>
    <!-- #endregion -->

    <!-- #region DROPDOWN -->
    <div class="research-select__dialog" *ngIf="showDialog && filteredRLines.length > 0">
      <div class="research-select__dialog-item" *ngFor="let line of filteredRLines">
        <p class="research-select__text">{{ line.name }}</p>
        <button class="button button--add" type="button" (click)="addElement(line)">
          <em class="icon-i-plus-circle"></em>
        </button>
      </div>
    </div>
    <!-- #endregion-->
  </div>

  <!-- SHOW ERRORS -->
  <ng-container *ngIf="isInvalid && validation">
    <small class="text-invalid">Este campo es obligatorio</small>
  </ng-container>
</div>

<div class="research-pills" [ngStyle]="{'margin-top': hideInput ? '0px' : ''}" *ngIf="linesToAdd.length > 0">
  <ng-container *ngFor="let element of linesToAdd">
    <request-member-pill [content]="element.name" (closePill)="deleteElement(element)">
    </request-member-pill>
  </ng-container>
</div>