import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { catchError, forkJoin, map, of, switchMap, tap } from 'rxjs';
import { AreaI } from 'src/app/models/researchModels/area';
import { GroupI, TypeRxGroupI } from 'src/app/models/researchModels/group';
import { MemberI } from 'src/app/models/researchModels/member';
import { SchoolI } from 'src/app/models/researchModels/project';
import { UserI } from 'src/app/models/researchModels/user';
import { RequestResearchService } from 'src/app/services/research/research.service';
import { MemberSelectInputComponent } from '../../../shared/selectors/member-select-input/member-select-input.component';
import { TypeResearchSelectInputComponent } from '../../../shared/selectors/type-research-select-input/type-research-select-input.component';
import Swal from 'sweetalert2';

interface Select{
  id:number | string;
  text:string
}

@Component( {
  selector: 'request-edit-group-form',
  templateUrl: './edit-group-form.component.html',
  styleUrls: [ './edit-group-form.component.css' ],
} )
export class EditGroupFormComponent implements OnInit {
  

  //searches for all user selectable and type research components
  @ViewChild( MemberSelectInputComponent ) userSelectInput: MemberSelectInputComponent;
  @ViewChild( TypeResearchSelectInputComponent ) typeResearch: TypeResearchSelectInputComponent;

  private _selectedGroup = null;
  @Input('selectedGroup') set selectedGroup(value:any){
    this._selectedGroup = value;
    this.patchForm(value);
  }

  get selectedGroup(){
    return this._selectedGroup;
  }

  //receives and establishes modal status
  @Input( 'showModal' ) showModal: boolean = false;

  //issues a call when the modal is closed
  @Output( 'onCloseModal' ) onCloseModal: EventEmitter<void> = new EventEmitter();
  @Output( 'onDeleteGroup' ) onDeleteGroup: EventEmitter<void> = new EventEmitter();
  @Output( 'onUpdateGroup' ) onUpdateGroup: EventEmitter<any> = new EventEmitter();

  //group form
  public groupForm: FormGroup = this._fb.group( {
    category: [ '' ],
    seedbedGroup: [ '' ],
    seedbedCheckbox: [ false ],
    type: [ '', Validators.required ],
    area: [ '', Validators.required ],
    unity: [ '' ],
    description: [ '', Validators.required ],
    organization: [ '', Validators.required ],
    startDate: [ '', Validators.required ],
    school: ['', Validators.required]
  } );

  //receive a list of areas
  public areaList: Select[] = [];

  //receives a list of group types
  public typeRxGroupList: Select[] = [];

  //receives a list of group types
  public schoolList: Select[] = [];
  public unityList: Select[] = [];
  public categories: Select[] = [
    { id: 'A', text: "A" },
    { id: 'B', text: "B" },
    { id: 'C', text: "C" },
    { id: 'D', text: "D" }
  ];

  //leader to be added
  public manager?: UserI = undefined;

  //stores the lines of research to be added
  public researchLines: any[] = [];
  private _defaultResearchLines: any[] = [];
  public defaultResearchLineIds: number[] = [];

  //establishes whether or not the form is being sent.
  public isSubmitting:boolean = false;

  public seedbedGroups: Select[] = [];

  constructor (
    private _requestResearchService: RequestResearchService,
    private _fb: FormBuilder,
  ) {
    //
  }

  ngOnInit(): void {
    this.schoolList = this._requestResearchService.currentSchools.map( e => ({id:e.id, text:e.NameTSchool}))

    forkJoin( {
      researchAreas: this._requestResearchService.getResearchAreas(),
      typeGroupList: this._requestResearchService.getTypeRxGroup(),
      schools: this._requestResearchService.getSchools(),
    } ).subscribe( {
      next: ( { typeGroupList, researchAreas, schools }: any ) => {
        this.areaList = researchAreas.map(e => ({ id: e.id, text: e.NameArea }));
        this.typeRxGroupList = typeGroupList.map(e => ({ id: e.id, text: e.name }));
      },
      error: ( err ) => console.log( err )
    } )
  }

  private patchForm(value:any){
    this.getUnitContent(value.schoolID);

    this.manager = value.userapp;
    this._defaultResearchLines = value.researchGroupToTypeResearches;
    this.defaultResearchLineIds = this._defaultResearchLines.map(e => e.typeResearchID);
    
    this.groupForm.patchValue({
      organization: value.name,
      category: value.category,
      type: value.typeRxGroupID,
      school: value.schoolID,
      area: value.researchAreaID,
      unity: value.unitID || '',
      description: value.description,
      seedbedCheckbox: value.researchGroupID !== null,
      seedbedGroup: value.seedbedGroup,
      startDate: value.startDate.split('T')[0]
    })
  }

  /**
   * obtains the list of areas and seedbedGroups
   * @param schoolId
   */
  getUnitContent( schoolId: number ) {
    this.groupForm.get('unity')?.reset('');
    this.groupForm.get('seedbedGroup')?.reset('');

    this.seedbedGroups = [];
    const seedbedGroupFilter = JSON.stringify( { where: { and: [ { typeRxGroupID: 1 }, { schoolID: schoolId } ] } } );
    const unitFilter = JSON.stringify({ where:{schoolID: schoolId} });

    forkJoin( {
      seedbedGroups: this._requestResearchService.getGroups( seedbedGroupFilter ),
      unityList: this._requestResearchService.getUnities(unitFilter)
    } ).subscribe( {
      next: ( { seedbedGroups, unityList }: any ) => {
        this.seedbedGroups = seedbedGroups.map(e => ({id: e.id, text: e.name}));
        this.unityList = unityList.sort((a, b) =>
          a.nameUnit.localeCompare(b.nameUnit)
        ).map(e => ({id: e.id, text: e.nameUnit}));
      },
      error: ( err ) => console.log( err )
    } );
  }

  /**
   * when changing the group type to 'Grupo de investigación', the category will be a required field
   * @param event 
   */
  requiredCategory(event:any){
    if(event != 3){
      this.groupForm.get('category')?.clearValidators();
      this.groupForm.get('category')?.updateValueAndValidity();
    }else{
      this.groupForm.get('category')?.setValidators([Validators.required]);
      this.groupForm.get('category')?.updateValueAndValidity();
    }

    if(event != 2){
      this.groupForm.patchValue({
        seedbedCheckbox: false,
      });
      this.requiredSeedGroup (false);
    }
  }

  /**
   * when checking the "semillero de investigación" checkbox, the "semillero" field will be mandatory
   * @param event 
   */
  requiredSeedGroup(event){
    if ( event === true ) {
      this.groupForm.get( 'seedbedGroup' )?.setValidators( [ Validators.required ] )
      this.groupForm.get( 'seedbedGroup' )?.updateValueAndValidity();
    } else {
      this.groupForm.get( 'seedbedGroup' )?.clearValidators()
      this.groupForm.get( 'seedbedGroup' )?.updateValueAndValidity();
    }
  }

  /**
   *  create a new group
   */
  submitGroup() {
    if ( !( this.groupForm.valid && this.manager !== undefined ) || this.isSubmitting )
      return;
    
    this.isSubmitting = true;
    let groupData: any = {
      id: this.selectedGroup.id,
      typeRxGroupID: parseInt( this.groupForm.get( 'type' )?.value ),
      userID: this.manager!.id,
      category: this.groupForm.get( 'category' )?.value,
      researchGroupID: this.groupForm.get( 'seedbedGroup' )?.value || null,
      name: this.groupForm.get( 'organization' )?.value,
      description: this.groupForm.get( 'description' )?.value,
      schoolID: parseInt( this.groupForm.get( 'school' )?.value ),
      unitID: parseInt( this.groupForm.get( 'unity' )?.value ) || null,
      researchAreaID: parseInt( this.groupForm.get( 'area' )?.value ),
      startDate: this.groupForm.get( 'startDate' )?.value
    }

    const allRLines = [...this.researchLines, ...this._defaultResearchLines];
    const newRLines = allRLines.map( rLine => {
      const {id, ...data} = rLine;
      return data
    } );

    const rLinesToCreateObs = newRLines.map( rLine =>
      this._requestResearchService.setGroupTypeResearches( rLine ));

    const rLinesToDeleteObs = allRLines.map(e => 
      this._requestResearchService.deleteGroupTypeResearches(e.id).pipe(catchError(_ => of(null))));

    this._requestResearchService.updateGroup( groupData ).pipe(
      switchMap(group => {
        if (allRLines.length === 0) return of(group)
        return forkJoin(rLinesToDeleteObs).pipe(
          switchMap(() => forkJoin(rLinesToCreateObs).pipe(
            map(() => group)
          )))
      })
    ).subscribe( {
      next: ( result ) => {
        this.closeGroupModal();
        this.onUpdateGroup.emit( result.id );
      },
      error: ( err: Error ) => console.log( err )
    } );
  }

  /**
   * receives the leader to add from the child component that displays the list of possible members
   * @param manager 
   */
  getManager( manager: UserI[] ) {
    this.manager = manager[ 0 ];
  }

  /**
   * receive the list of lines of research to be added
   * @param members 
   */
  getRLinesToAdd( researchLines: any[] ) {
    if(this.groupForm.get('type')?.value !== '')
      this.researchLines = researchLines;
  }

  /**
   * clear the form data
   */
  clearForm() {
    this.groupForm.reset( {
      type: '',
      area: '',
      unity: '',
      category: '',
      startDate: '',
      seedbedGroup: '',
      seedbedCheckbox: false,
      school: ''
    } );

    this.userSelectInput.clearData();
    this.typeResearch.clearData();

    this.researchLines = [];
    this.manager = undefined;
  }

  /**
   * closes and clears the group modal
   */
  closeGroupModal() {
    //this.clearForm();
    this.showModal = false;
    this.onCloseModal.emit();
    this.isSubmitting = false;
  }

  deleteGroup(){
    Swal.fire({
      text: "¿Seguro que deseas eliminar este grupo?",
      allowEscapeKey: false,
      showCancelButton: true,
      allowOutsideClick: false
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          text: "Eliminando...",
          allowEscapeKey: false,
          allowOutsideClick: false,
          didOpen: () => Swal.showLoading()
        })
        this._requestResearchService.deleteGroup(this.selectedGroup.id).subscribe({
          next:(resp) => {
            Swal.close();
            this.onDeleteGroup.emit();
            this.closeGroupModal();
          },error:(err) => {
            console.log(err);
          },
          complete:() => {
            Swal.close();
          }
        })
      }
    });
  }
}
