<form class="event-form__main" [formGroup]="eventsForm" [ngClass]="onShowForm.showForm ? 'event-form__main--show' : ''" (submit)="submitEvent()">
  <div class="event__input">
    <request-text-input [title]="'Nombre del evento *'" [placeholder]="'Escriba el nombre del evento'"
      [parentForm]="eventsForm" [controlName]="'name'">
    </request-text-input>
  </div>

  <div class="event__input">
    <request-date-input [title]="'Fecha del evento *'" [parentForm]="eventsForm" [controlName]="'date'">
    </request-date-input>
  </div>

  <div class="event__input">
    <request-textarea-input [title]="'Descripción del evento'" [placeholder]="'Escriba la descripción del evento'"
      [parentForm]="eventsForm" [controlName]="'description'">
    </request-textarea-input>
  </div>

  <div class="event__input">
    <request-text-input [title]="'Escuelas que participan *'" [placeholder]="'Escriba las escuelas que participan'"
      [parentForm]="eventsForm" [controlName]="'unitsParticipant'">
    </request-text-input>
  </div>

  <button type="submit" class="button button--dark" [ngClass]="!eventsForm.valid || isSubmitting ? 'button--disabled' : ''">Guardar Evento</button>
</form>

<div class="modal-bg" (click)="closeEventModal()" [ngClass]="onShowForm.showForm ? 'modal-bg--show' : ''">
</div>