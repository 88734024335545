<div class="member-select" [ngStyle]="{'opacity': disabled ? '.5' : '', 'pointer-events': disabled ? 'none' : ''}">
  <label for="">{{ title }}</label>
  <div class="member-select__input-container" [hidden]="hideInput">
    <!-- #region SEARCH INPUT -->
    <input type="text" class="member-select__input" autocomplete="off" id="{{ inputId }}"
      placeholder="Buscar por email o cédula"
      [ngClass]="isInvalid ? 'member-select__input--invalid' : ''" tabindex="-1"
      #filterInput (click)="onClick()" (keyup)="filterMembers($event)">

    <em class="icon-i-search"></em>
    <!-- #endregion -->

    <!-- #region DROPDOWN -->
    <div class="member-select__dialog" *ngIf="showDialog && filteredMembers.length > 0">

      <div class="member-select__dialog-item" *ngFor="let user of filteredMembers">
        <div class="member-select__dialog-info">
          <p class="member-select__text">Nombre: {{ user.userapp!.Name1 }}
            {{ user.userapp!.LastName1 }}
          </p>
          <p class="member-select__text">Documento: {{ user.userapp!.UserDocuments?.[0]?.Document }}</p>
        </div>

        <button class="button" (click)="addMember(user)">
          <em class="icon-i-plus-circle"></em>
        </button>
      </div>
    </div>
    <!-- #endregion-->
  </div>

  <!-- SHOW ERRORS -->
  <ng-container *ngIf="isInvalid">
    <small class="text-invalid">Este campo es obligatorio</small>
  </ng-container>
</div>

<div class="member__pills" *ngIf="membersToAdd.length > 0" [ngStyle]="{'margin-top': hideInput ? '0px' : ''}">
  <ng-container *ngFor="let user of membersToAdd">
    <request-member-pill [content]="user.userapp!.Name1 +' '+ user.userapp!.LastName1"
      (closePill)="deleteMember(user)">
    </request-member-pill>
  </ng-container>
</div>