<div class="member-list__modal-box" [ngClass]="onShowModal.showModal ? 'member-list__modal-box--show' : ''">
  <h2 class="members-text members-text--bold">
    Usuario:
    <span class="members-text--normal">
      {{ onShowModal.member?.userapp?.Name1 }}
      {{ onShowModal.member?.userapp?.LastName1 }}
    </span></h2>

  <form [formGroup]="memberForm" (submit)="updateMember()">
    <div class="member-list__input">
      <request-select-input [items]="ocde" [parentForm]="memberForm" [controlName]="'ocde'" [title]="'OCDE *'"
        [placeholder]="'Elija el OCDE'">
      </request-select-input>
    </div>

    <div class="member-list__input">
      <request-select-input [items]="categories" [parentForm]="memberForm" [controlName]="'category'" [title]="'Categoría *'"
        [placeholder]="'Elija la categoría'">
      </request-select-input>
    </div>

    <div class="member-list__input">
      <request-number-input [parentForm]="memberForm" [controlName]="'hours'" [title]="'Horas de dedicación *'"
        [placeholder]="'Escriba el número de horas'" [min]="0">
      </request-number-input>
    </div>

    <div class="member-list__input">
      <request-checkbox-input [parentForm]="memberForm" [controlName]="'isProfessor'" [title]="'Es profesor?'"
        [inputId]="'is-professor-edit'">
      </request-checkbox-input>
    </div>

    <div class="member-list__input">
      <request-text-input [parentForm]="memberForm" [controlName]="'linkMinEdu'" [title]="'Link Min Edu'"
        [placeholder]="'Escriba el link'">
      </request-text-input>
    </div>

    <div class="member-list__input">
      <request-checkbox-input [parentForm]="memberForm" [controlName]="'isActive'" [title]="'Activo'"
        [inputId]="'is-active'">
      </request-checkbox-input>
    </div>

    <div class="member-list__modal-actions">
      <button type="submit" class="button button--dark"
        [ngClass]=" !memberForm.valid || isSubmitting ? 'button--disabled':''">
        Guardar
      </button>
      <button class="button button--dark" (click)="closeEditModal()" type="button">
        Cancelar
      </button>
      <button class="button button--red" (click)="deleteMember()" type="button">
        Eliminar
      </button>
    </div>
  </form>
</div>

<div class="modal-bg" [ngClass]="onShowModal.showModal ?  'modal-bg--show' : ''" (click)="closeEditModal()">
</div>
