import { Injectable } from '@angular/core';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

const EXCEL_TYPE =
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet; charset=UTF-8';

const EXCEL_EXTENSION = '.xlsx';

@Injectable({
  providedIn: 'root'
})
export class ExcelService {

  constructor() { 
    //
  }

  toExcel(json: any[], fileName: string) {
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json); 
    const wb: XLSX.WorkBook = {Sheets:{'data':ws},SheetNames:['data']};
    const excelBuffer: any = XLSX.write( wb, { bookType: 'xlsx', type: 'array' } );
    this.saveExcel(excelBuffer,fileName);
  }

  private saveExcel(buffer:any, fileName: string){
    const data: Blob = new Blob([buffer],{type:EXCEL_TYPE});
    FileSaver.saveAs(data,fileName + EXCEL_EXTENSION);
  }
}
