import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'request-filter-input',
  templateUrl: './filter-input.component.html',
  styleUrls: ['./filter-input.component.css']
})
export class FilterInputComponent implements OnInit {

  //Sends the value entered in the input
  @Output('value') value:EventEmitter<string> = new EventEmitter();

  //placeholder
  @Input('placeholder') placeholder:string = 'Buscar por email o documento'

  constructor() { 
    //
   }

  ngOnInit(): void { 
    // 
  }

  /**
   * is emitted each time a key is pressed
   * @param event 
   */
  filterValue(event){
    this.value.emit(event.target.value);
  }

}
