import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UserI } from 'src/app/models/researchModels/user';
import { HttpService } from '../http.service';

@Injectable({
  providedIn: 'root'
})
export class ResearchRolesService {

  constructor(private _httpService: HttpService) { }

  public roleByGroups: { groupId:number, name:string }[] = [];
  public allowedRoles:any = { 
    ADMIN: false, 
    LEADER: false,
    MEMBER: false
  }

  getCurrentUser( id:any, filter?: string ): Observable<UserI> {
    let newFilter = "";
    if ( filter ) newFilter = `?filter=${ filter }`;
    return this._httpService.httpGet( `/Userapps/${ id }${ newFilter }` );
  }

}
