<div class="group-form__input" [formGroup]="parentForm">
  <label for="" class="">{{ title }}</label>
  <input type="text" class="input input--text form-control" [formControlName]="controlName"
    [ngClass]="parentForm.get( controlName )?.invalid && parentForm.get( controlName )?.touched ? 'input--invalid' : ''"
    placeholder="{{ placeholder }}">

  <small class="text--invalid" *ngIf="parentForm.get( controlName )?.invalid && parentForm.get( controlName )?.touched">
    Este campo es obligatorio
  </small>

</div>