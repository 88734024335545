import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { switchMap } from 'rxjs';
import { InfoFileData } from 'src/app/models/logicModels/infoFileData';
import { ProjectI } from 'src/app/models/researchModels/project';
import { ResearchRolesService } from 'src/app/services/research/research-roles.service';
import { RequestResearchService } from 'src/app/services/research/research.service';
import { environment } from 'src/environments/environment';

@Component( {
  selector: 'request-project-card',
  templateUrl: './project-card.component.html',
  styleUrls: [ './project-card.component.css' ]
} )
export class ProjectCardComponent implements OnInit {

  constructor ( 
    private _requestResearchService: RequestResearchService,
    public rolesService: ResearchRolesService
  ) {
    //
  }

  // get project information
  @Input( 'projectInfo' ) projectInfo: ProjectI = {} as ProjectI;

  //issues a call when clicking the edit button
  @Output( 'editProject' ) editProject: EventEmitter<any> = new EventEmitter();

  //stores the list of project documents.
  public documentList: any[] = [];

  public showAddProjects: boolean = false;

  public urlBackV1:any = environment.urlBackV1;

  ngOnInit(): void {
    this.getProjectDocuments().subscribe( {
      next: ( resp ) => {
        this.documentList = resp;
      }, error: ( err: Error ) => console.log( err )
    } );
  }

  /**
   * obtains the list of project documents.
   * @returns
   */
  getProjectDocuments() {
    let filter = JSON.stringify( {
      where: { projectID: this.projectInfo.id }
    } )
    return this._requestResearchService.getProjectDocuments( filter );
  }

  //creates the documents for the selected project
  setProjectDocuments( files: InfoFileData ) {
    let data = {
      projectID: this.projectInfo.id,
      name: files.nameFile,
      description: files.descript,
      linkDrive: files.urlFile
    }

    this._requestResearchService.setProjectDocument( data ).pipe(
      switchMap( () => this.getProjectDocuments() )
    ).subscribe( {
      next: ( resp ) => {
        this.documentList = resp;
      }, error: ( err: Error ) => console.log( err )
    } );
  }

}
