import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SelectInputComponent } from './select-input/select-input.component';
import { TextInputComponent } from './text-input/text-input.component';
import { TextareaInputComponent } from './textarea-input/textarea-input.component';
import { ReactiveFormsModule } from '@angular/forms';
import { NumberInputComponent } from './number-input/number-input.component';
import { CheckboxInputComponent } from './checkbox-input/checkbox-input.component';
import { DateInputComponent } from './date-input/date-input.component';



@NgModule({
  declarations: [
    SelectInputComponent,
    TextInputComponent,
    NumberInputComponent,
    CheckboxInputComponent,
    DateInputComponent,
    TextareaInputComponent
  ],
  imports: [
    ReactiveFormsModule,
    CommonModule
  ],
  exports:[
    SelectInputComponent,
    CheckboxInputComponent,
    TextInputComponent,
    NumberInputComponent,
    DateInputComponent,
    TextareaInputComponent
  ]
})
export class FormInputsModule { }
