//Here we will store all the variables required by the components.
//If we want, we can initialize them with default values

interface SchoolToLoadOptions {
  school?: string;
}


//Default storages
const storage = {
  cedoc: 'https://storage.googleapis.com/assets_cedoc',
  celic: 'https://storage.googleapis.com/celic_assets',
}


/**
 * Default school variables
 * @param school 
 * @returns 
 */
export const schoolToLoad = ({
  school = 'cedoc',
}: SchoolToLoadOptions = {}) => {
  return {
    SCHOOL_NAME: school.toUpperCase()
  }
}