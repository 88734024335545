import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ScientificProductionComponent } from './scientific-production.component';
import { ScientificCardComponent } from './scientific-card/scientific-card.component';
import { ScientificAddFormComponent } from './scientific-add-form/scientific-add-form.component';
import { ReactiveFormsModule } from '@angular/forms';
import { ScientificEditFormComponent } from './scientific-edit-form/scientific-edit-form.component';
import { FormInputsModule } from 'src/app/components/shared/form-inputs/form-inputs.module';
import { UploadFileBoxModule } from 'src/app/components/shared/upload-file-box/upload-file-box.module';



@NgModule({
  declarations: [
    ScientificProductionComponent,
    ScientificCardComponent,
    ScientificAddFormComponent,
    ScientificEditFormComponent
  ],
  imports: [
    FormInputsModule,
    ReactiveFormsModule,
    UploadFileBoxModule,
    CommonModule
  ],
  exports:[
    ScientificProductionComponent
  ]
})
export class ScientificProductionModule { }
