import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { forkJoin, map, of, switchMap } from 'rxjs';
import { AreaI } from 'src/app/models/researchModels/area';
import { GroupI, TypeRxGroupI } from 'src/app/models/researchModels/group';
import { MemberI } from 'src/app/models/researchModels/member';
import { SchoolI } from 'src/app/models/researchModels/project';
import { UserI } from 'src/app/models/researchModels/user';
import { RequestResearchService } from 'src/app/services/research/research.service';
import { MemberSelectInputComponent } from '../../../shared/selectors/member-select-input/member-select-input.component';
import { TypeResearchSelectInputComponent } from '../../../shared/selectors/type-research-select-input/type-research-select-input.component';

interface Select{
  id: number | string;
  text:string;
}

@Component( {
  selector: 'request-group-form',
  templateUrl: './group-form.component.html',
  styleUrls: [ './group-form.component.css' ],
} )
export class GroupFormComponent implements OnInit {

  //searches for all user selectable and type research components
  @ViewChild( MemberSelectInputComponent ) userSelectInput: MemberSelectInputComponent;
  @ViewChild( TypeResearchSelectInputComponent ) typeResearch: TypeResearchSelectInputComponent;

  //receives and establishes modal status
  @Input( 'showModal' ) showModal: boolean = false;

  //issues a call when the modal is closed
  @Output( 'closeModal' ) closeModal: EventEmitter<void> = new EventEmitter();
  @Output( 'onCreateGroup' ) onCreateGroup: EventEmitter<any> = new EventEmitter();

  //group form
  public groupForm: FormGroup = this._fb.group( {
    category: [ '' ],
    seedbedGroup: [ '' ],
    seedbedCheckbox: [ false ],
    type: [ '', Validators.required ],
    area: [ '', Validators.required ],
    unity: [ '' ],
    description: [ '', Validators.required ],
    organization: [ '', Validators.required ],
    startDate: [ '', Validators.required ],
    school: ['', Validators.required]
  } );

  //receive a list of areas
  public areaList: Select[] = [];

  //receives a list of group types
  public typeRxGroupList: Select[] = [];

  //receives a list of group types
  public schoolList: Select[];
  public unityList: Select[] = [];
  public categories: Select[] = [
    { id: 'A', text: "A" },
    { id: 'B', text: "B" },
    { id: 'C', text: "C" },
    { id: 'D', text: "D" }
  ];

  //leader to be added
  public manager?: UserI = undefined;

  //leader to be added
  public member?: UserI = undefined;

  //stores the lines of research to be added
  public researchLines: any[] = [];

  //establishes whether or not the form is being sent.
  public isSubmitting:boolean = false;

  //checks if the member is required
  public requiredMember: boolean = true;
  public seedbedGroups: Select[] = [];

  constructor (
    private _requestResearchService: RequestResearchService,
    private _fb: FormBuilder,
  ) {
    //
  }

  ngOnInit(): void {

    this.schoolList =  this._requestResearchService.currentSchools.map(e => ({id:e.id, text:e.NameTSchool}));

    forkJoin( {
      researchAreas: this._requestResearchService.getResearchAreas(),
      typeGroupList: this._requestResearchService.getTypeRxGroup(),
      schools: this._requestResearchService.getSchools(),
    } ).subscribe( {
      next: ( { typeGroupList, researchAreas, schools }: any ) => {
        this.areaList = researchAreas.map(e => ({ id: e.id, text: e.NameArea }));
        this.typeRxGroupList = typeGroupList.map( e => ({id:e.id, text:e.name}));
      },
      error: ( err ) => console.log( err )
    } )
  }

  /**
   * obtains the list of areas and seedbedGroups
   * @param schoolId
   */
  getUnitContent( schoolId: number ) {
    this.groupForm.get('unity')?.reset('');
    this.groupForm.get('seedbedGroup')?.reset('');

    this.seedbedGroups = [];

    const seedbedGroupFilter = JSON.stringify( { where: { and: [ { typeRxGroupID: 1 }, { schoolID: schoolId } ] } } );
    const unitFilter = JSON.stringify({ where:{schoolID: schoolId} });

    forkJoin( {
      seedbedGroups: this._requestResearchService.getGroups( seedbedGroupFilter ),
      unityList: this._requestResearchService.getUnities(unitFilter)
    } ).subscribe( {
      next: ( { seedbedGroups, unityList }: any ) => {
        this.seedbedGroups = seedbedGroups.map(e => ({id: e.id, atext:e.name}));
        this.unityList = unityList.sort((a, b) =>
          a.nameUnit.localeCompare(b.nameUnit)
        ).map(e => ({id:e.id, text:e.nameUnit}));
      },
      error: ( err ) => console.log( err )
    } );
  }

  /**
   * when changing the group type to 'Grupo de investigación', the category will be a required field
   * @param event 
   */
  requiredCategory(event:any){
    if(event != 2){
      this.member = undefined;
      this.groupForm.patchValue({
        seedbedCheckbox: false,
      });
      this.requiredSeedGroup (false);
    }

    this.requiredMember = event == 2 ? this.member === undefined : false;
  }

  /**
   * when checking the "semillero de investigación" checkbox, the "semillero" field will be mandatory
   * @param event 
   */
  requiredSeedGroup(event){
    if ( event === true ) {
      this.groupForm.get( 'seedbedGroup' )?.setValidators( [ Validators.required ] )
      this.groupForm.get( 'seedbedGroup' )?.updateValueAndValidity();
    } else {
      this.groupForm.get( 'seedbedGroup' )?.clearValidators()
      this.groupForm.get( 'seedbedGroup' )?.updateValueAndValidity();
    }
  }

  /**
   *  create a new group
   */
  submitGroup() {
    if ( !( this.groupForm.valid && this.manager !== undefined && !this.requiredMember ) || this.isSubmitting )
      return;
    
    this.isSubmitting = true;
    let groupData: any = {
      typeRxGroupID: parseInt( this.groupForm.get( 'type' )?.value ),
      userID: this.manager!.id,
      category: this.groupForm.get( 'category' )?.value,
      researchGroupID: this.groupForm.get( 'seedbedGroup' )?.value || null,
      name: this.groupForm.get( 'organization' )?.value,
      description: this.groupForm.get( 'description' )?.value,
      schoolID: parseInt( this.groupForm.get( 'school' )?.value ),
      unitID: parseInt( this.groupForm.get( 'unity' )?.value ) || null,
      researchAreaID: parseInt( this.groupForm.get( 'area' )?.value ),
      startDate: this.groupForm.get( 'startDate' )?.value
    }

    let newRLines = ( groupId: number ) => this.researchLines.map( rLine => {
      return ( {
        researchGroupID: groupId,
        typeResearchID: rLine.id,
      } )
    } );

    let rLinesMappedObservable = ( groupId: number ) => [ ...newRLines( groupId ) ].map( ( rLine: any ) =>
      this._requestResearchService.setGroupTypeResearches( rLine ) );

    this._requestResearchService.createGroup( groupData ).pipe(
      switchMap( ( group: GroupI ) => {
        return forkJoin( {
            rLines: this.researchLines.length > 0 ? forkJoin([...rLinesMappedObservable( group.id )]) : of([]),
            newMember: this.member !== undefined ? this.addMember(group.id) : of([])
          }  ).pipe( map( () => group ) )
      } ),
    ).subscribe( {
      next: ( result ) => {
        this.closeGroupModal();
        this.onCreateGroup.emit( result.id );
      },
      error: ( err: Error ) => console.log( err )
    } );
  }

  /**
   * receives the leader to add from the child component that displays the list of possible members
   * @param manager 
   */
  getManager( manager: UserI[] ) {
    this.manager = manager[ 0 ];
  }

  /**
   * receives the member to add 
   * @param member 
   */
  getMember( member:UserI[] ){
    this.member = member[ 0 ];
    this.requiredMember = this.groupForm.get( 'type' )?.value == 2 ? this.member === undefined : false;
  }

  /**
   * creates a new member
   * @param groupId 
   * @returns 
   */
  addMember(groupId:number){

    let data: MemberI = {
      isActive: true,
      userID: this.member?.id!,
      researchGroupID: groupId,
      OCDE: 'Ciencias Agrícolas',
      hours: 0,
      category: 'Eméritos',
      linkMinEdu: 'N/A',
      isProfessor: false,
    };

    return this._requestResearchService.createMember(data);
  }

  /**
   * receive the list of lines of research to be added
   * @param members 
   */
  getRLinesToAdd( researchLines: any[] ) {
    if(this.groupForm.get('type')?.value !== '')
      this.researchLines = researchLines;
  }

  /**
   * clear the form data
   */
  clearForm() {
    this.groupForm.reset( {
      type: '',
      area: '',
      unity: '',
      category: '',
      startDate: '',
      seedbedGroup: '',
      seedbedCheckbox: false,
      school: ''
    } );

    this.userSelectInput.clearData();
    this.typeResearch.clearData();

    this.researchLines = [];
    this.manager = undefined;
    this.member = undefined;
  }

  /**
   * closes and clears the group modal
   */
  closeGroupModal() {
    this.clearForm();
    this.showModal = false;
    this.closeModal.emit();
    this.isSubmitting = false;
  }
}
