import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'request-filter-pill',
  templateUrl: './filter-pill.component.html',
  styleUrls: ['./filter-pill.component.css']
})
export class FilterPillComponent implements OnInit {

  //Issues a callback when its value changes
  @Output('onSelected') onSelected:EventEmitter<any> = new EventEmitter();

  //Title
  @Input('title') title: string = '';

  //Allows to set whether it is pressed or not
  @Input('activatePill') activatePill: boolean = false;

  //input id
  @Input('buttonId') buttonId: string = '';

  constructor() {
    //
   }

  ngOnInit(): void {
    //
  }

}
