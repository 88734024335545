
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { MemberI } from 'src/app/models/researchModels/member';
import { ProjectI } from 'src/app/models/researchModels/project';
import { UserI } from 'src/app/models/researchModels/user';
import { ResearchRolesService } from 'src/app/services/research/research-roles.service';
import { RequestResearchService } from 'src/app/services/research/research.service';

@Component( {
  selector: 'request-projects',
  templateUrl: './projects.component.html',
  styleUrls: [ './projects.component.css' ]
} )
export class ProjectsComponent implements OnInit, OnDestroy {

  constructor ( 
    private _requestResearchService: RequestResearchService,
    public rolesService: ResearchRolesService
  ) { }

  //emits a call each time the project section is closed
  @Output( 'closeSection' ) closeSection: EventEmitter<void> = new EventEmitter();

  //receives the id of the selected group
  private _selectedGroupId:number = 0;
  @Input( 'selectedGroupId' ) set selectedGroupId(value:number){
    this._selectedGroupId = value;
    this.projectList = [];
    if(this._selectedGroupId !== 0)
      this.getProjects();
  };

  get selectedGroupId(){
    return this._selectedGroupId;
  }

  //stores the list of members of the selected group
  @Input('memberList') memberList: MemberI[] = [];

  //stores the list of projects in the current group
  public projectList: ProjectI[] = [];

  //allows to display the create project modal
  public showAddProjectModal = false;

  //allows to display the edit project modal
  public showEditProjectModal = false;

  //stores the id of the project to be edited
  public selectedProjectId: number = 0;

  //checks if the project list is being loaded
  public isLoadingProjects: boolean = false;

  //sets the start date for filtering projects
  public fromDate: Date = new Date(2022,10,30);

  //sets the end date for filtering projects
  public toDate: Date = new Date();

  private _subscription: Subscription = new Subscription();

  ngOnInit(): void {
    //
  }

  /**
   * obtains the list of projects based on the current group
   */
  getProjects() {
    this.isLoadingProjects = true;
    const filter = JSON.stringify( {
      include: [ 'school', 'researchArea', { projectMembers: { members: 'userapp' } }, { members: 'userapp' }, 'researchGroup', 'unit', {projectToTypeResearches: 'typeResearch'} ],
      where: {
        and: [
          { researchGroupID: this.selectedGroupId },
          { dateStart: { between: [ new Date( this.fromDate ), new Date( this.toDate ) ] } },
        ]
      }
    } )

    this._subscription.unsubscribe();
    this._subscription = this._requestResearchService.getProjects( filter ).subscribe( {
      next: ( resp: ProjectI[] ) => {
        this.projectList = resp;
        this.isLoadingProjects = false;
      },
      error: ( err: Error ) => console.log( err )
    } );
  }

  /**
   * sets the project id and opens the edit modal
   * @param projectId
   */
  editProject( projectId: number ) {
    this.showEditProjectModal = true;
    this.selectedProjectId = projectId;
  }

 /**
   * gets the start date
   * @param event
   */
  getStartDate( event ) {
    this.fromDate = event;
    this.getProjects();
  }

  /**
   * gets the end date
   * @param event
   */
  getEndDate( event ) {
    this.toDate = event;
    this.getProjects();
  }

  ngOnDestroy(): void {
    this._subscription.unsubscribe();
  }

}
