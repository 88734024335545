<form class="product-form__main" [ngClass]="showForm ? 'product-form__main--show' : ''" [formGroup]="fileForm"
  (submit)="submitProduct()">

  <div class="product-form__input">
    <request-select-input [items]="productTypeList" [parentForm]="fileForm" [controlName]="'type'" [title]="'Tipo *'"
      [placeholder]="'Elija el tipo'" (onSelectChange)="getSubtype($event)">
    </request-select-input>
  </div>

  <div class="product-form__input"
    [ngClass]="productSubtypeList.length === 0 ? 'disabled' : ''">
    <request-select-input [items]="productSubtypeList" [parentForm]="fileForm" [controlName]="'subtype'"
      [title]="'Subtipo *'" [placeholder]="'Elija el subtipo'">
    </request-select-input>
  </div>

  <div class="product-form__input">
    <request-date-input [parentForm]="fileForm" [controlName]="'publishDate'" [title]="'Fecha de publicación'">
    </request-date-input>
  </div>

  <div class="product-form__input">
    <request-select-input [items]="projectList" [parentForm]="fileForm" [controlName]="'project'" [title]="'Proyecto'"
      [placeholder]="'Elija el proyecto'">
    </request-select-input>
  </div>

  <div class="product-form__input">
    <request-text-input [parentForm]="fileForm" [controlName]="'name'" [title]="'Título del producto *'"
      [placeholder]="'Escriba el título del producto'">
    </request-text-input>
  </div>

  <div class="product-form__input">
    <request-textarea-input [parentForm]="fileForm" [controlName]="'description'" [title]="'Descripción del producto'"
      [placeholder]="'Escriba la descripción del producto'">
    </request-textarea-input>
  </div>

  <div class="product-form__input">
    <request-checkbox-input [parentForm]="fileForm" [controlName]="'addFile'" [title]="'Subir archivo'"
      [inputId]="'add-documet-file'">
    </request-checkbox-input>
  </div>

  <ng-container [ngTemplateOutlet]="fileForm.get('addFile')?.value ? fileInput : customFileInput">
  </ng-container>

  <button type="submit" class="button button--dark"
    [ngClass]=" !fileForm.valid || ( fileData === undefined && fileForm.get( 'addFile' )?.value ) || isSubmitting ? 'button--disabled' : ''">
    Crear Documento
  </button>
</form>

<div class="modal-bg" (click)="closeFormModal()" [ngClass]="showForm ? 'modal-bg--show' : ''">
</div>

<ng-template #fileInput>
  <div class="product-form__input">
    <upload-file-box [containerName]="'scientificinfo'" (responseDataFile)="getFile($event)" [maxMbSize]="25000000" [smallTitle]="'pdf (max 25MB)'"></upload-file-box>
    <small class="text--invalid" *ngIf="!isFileUploaded">Aún no has cargado ningún archivo</small>
  </div>
</ng-template>

<ng-template #customFileInput>
  <div class="product-form__input">
    <request-text-input [parentForm]="fileForm" [controlName]="'linkDrive'" [title]="'Link de publicación *'"
      [placeholder]="'Escriba el link'">
    </request-text-input>
  </div>
</ng-template>