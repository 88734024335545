<div class="member-list__modal-box" [ngClass]="showModal ? 'member-list__modal-box--show' : ''">
  <form [formGroup]="memberForm" (submit)="addMember()">
    <div class="member-list__input">
      <request-member-select-input [maxMembers]="1" [title]="'Integrante'"
        (addedMembers)="getMemberToAdd($event)" [inputId]="'add-member-select'"
        [membersToExclude]="userListToExclude">
      </request-member-select-input>
    </div>

    <div class="member-list__input">
      <request-select-input [items]="ocde" [parentForm]="memberForm" [controlName]="'ocde'" [title]="'OCDE *'"
        [placeholder]="'Elija el OCDE'">
      </request-select-input>
    </div>

    <div class="member-list__input">
      <request-select-input [items]="categories" [parentForm]="memberForm" [controlName]="'category'" [title]="'Categoría *'"
        [placeholder]="'Elija la categoría'">
      </request-select-input>
    </div>

    <div class="member-list__input">
      <request-number-input [parentForm]="memberForm" [controlName]="'hours'" [title]="'Horas de dedicación *'"
        [placeholder]="'Escriba el número de horas'" [min]="0">
      </request-number-input>
    </div>

    <div class="member-list__input">
      <request-checkbox-input [parentForm]="memberForm" [controlName]="'isProfessor'" [title]="'Es profesor?'"
        [inputId]="'is-professor'">
      </request-checkbox-input>
    </div>

    <div class="member-list__input">
      <request-text-input [parentForm]="memberForm" [controlName]="'linkMinEdu'" [title]="'Link Min Edu'"
        [placeholder]="'Escriba el link'">
      </request-text-input>
    </div>

    <div class="member-list__modal-actions">
      <button type="submit" class="button button--dark"
        [ngClass]="!(memberToAdd !== undefined && memberForm.valid) || isSubmitting ? 'button--disabled' : ''">
        Agregar
      </button>
      <button class="button button--dark" (click)="closeMembersModal()" type="button">
        Cancelar
      </button>
    </div>
  </form>
</div>

<div class="modal-bg" [ngClass]="showModal ? 'modal-bg--show' : ''" (click)="closeMembersModal()">
</div>