<div class="member-card" >
  <button class="button button--edit" (click)="editMember.emit()" 
    *ngIf="rolesService.allowedRoles.ADMIN || rolesService.allowedRoles.LEADER">
    <em class="icon-i-filled-edit-directive"></em>
  </button>

  <div class="member-card__container">
    <p class="member-text">
      {{  memberData.isProfessor ? 'Docente:' : 'Estudiante:' }}
      {{ memberData.userapp?.Name1 }}
      {{ memberData.userapp?.LastName1 }}
    </p>

    <p class="member-text">Correo: {{ memberData.userapp?.email }}</p>
    <p class="member-text">Cédula: {{ memberData.userapp?.UserDocuments?.[0]?.Document }}</p>
    <p class="member-text">Estado: {{ memberData.isActive  === true ? 'Activo' : 'Inactivo' }}</p>
    <p class="member-text">Horas dedicadas: {{ memberData.hours + ' hs' }}</p>
  </div>
</div>