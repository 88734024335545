import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GroupStatsComponent } from './group-stats.component';
import { DatePillModule } from '../date-pill/date-pill.module';



@NgModule({
  declarations: [
    GroupStatsComponent,
  ],
  imports: [
    DatePillModule,
    CommonModule
  ],
  exports:[
    GroupStatsComponent
  ]
})
export class GroupStatsModule { }
