import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { forkJoin } from 'rxjs';
import { InfoFileData } from 'src/app/models/logicModels/infoFileData';
import { RequestResearchService } from 'src/app/services/research/research.service';
import { environment } from 'src/environments/environment';


interface Select{
  id: number | string;
  text: string;
}

@Component( {
  selector: 'request-scientific-edit-form',
  templateUrl: './scientific-edit-form.component.html',
  styleUrls: [ './scientific-edit-form.component.css' ]
} )
export class ScientificEditFormComponent {

  constructor ( private _fb: FormBuilder, private _requestResearchService: RequestResearchService ) { }

  //allows to show or hide the project form
  private _onShowForm:any = {};
  @Input( 'onShowForm' ) set onShowForm(value:any){
    this._onShowForm = value; this.onOpenForm();
  }

  get onShowForm(){
    return this._onShowForm;
  }
  
  //receives the id of the current group
  @Input( 'groupId' ) groupId: number = 0;

  //emits a call each time a product is edited
  @Output( 'editedProduct' ) editedProduct: EventEmitter<void> = new EventEmitter();

  //issues a call when the modal is closed
  @Output( 'closeModal' ) closeModal: EventEmitter<void> = new EventEmitter();

  //form 
  public fileForm: FormGroup = this._fb.group( {
    type: [ '', Validators.required ],
    subtype: [ '', Validators.required ],
    description: [ '' ],
    project: [ '' ],
    name: [ '', Validators.required ],
    publishDate: [ '' ],
    linkDrive: [ '', Validators.required ],
    addFile: [ false ],
  } );

  public projectList: Select[] = [];
  public productTypeList: Select[] = [];
  public productSubtypeList: any[] = [];
  public isFileUploaded: boolean = false;
  public fileData?: InfoFileData = undefined;
  public isSubmitting:boolean = false;
  public schoolList: Select[] = [];
  public urlBackV1:any = environment.urlBackV1;

  /**
   * loads the product data to be edited
   */
  onOpenForm() {
    this.schoolList = this._requestResearchService.currentSchools.map(e => ({ id: e.id, text: e.NameTSchool }));
    forkJoin({
      productSubtypeList: this._requestResearchService.getProductSubtypes(JSON.stringify({
        where: { categoryProductID: this.onShowForm.product.categoryProductID }
      })),
      productTypes: this._requestResearchService.getProductTypes(),
      projects: this._requestResearchService.getProjects(JSON.stringify({ where: { researchGroupID: this.groupId } })),
    }).subscribe({
      next: ({ productTypes, projects, productSubtypeList }: any) => {
        this.productTypeList = productTypes.map(e => ({ id: e.id, text: e.name }));
        this.projectList = projects.map(e => ({ id: e.id, text: e.name }));
        this.productSubtypeList = productSubtypeList.map(e => ({ id: e.id, text: e.name }));
      }, error: (err: Error) => console.log(err)
    });

    this.fileForm.patchValue({
      subtype: this.onShowForm.product.typeProductID || '',
      type: this.onShowForm.product.categoryProductID || '',
      description: this.onShowForm.product.description,
      project: this.onShowForm.product.projectID || '',
      name: this.onShowForm.product.name,
      publishDate: this.onShowForm.product.date?.split('T')[0],
      linkDrive: this.onShowForm.product.linkDrive
    });
  }

  /**
   * creates the file
   * @returns 
   */
  submitProduct() {
    const addFile = this.fileForm.get( 'addFile' )?.value;
    const data = {
      typeProductID: this.fileForm.get( 'subtype' )?.value,
      categoryProductID: this.fileForm.get( 'type' )?.value,
      description: this.fileForm.get( 'description' )?.value,
      projectID: this.fileForm.get( 'project' )?.value,
      linkDrive: addFile ? this.urlBackV1 + this.fileData?.urlFile : this.fileForm.get( 'linkDrive' )?.value,
      name: this.fileForm.get( 'name' )?.value,
      date: this.fileForm.get( 'publishDate' )?.value || null,
    }

    if ( !this.fileForm.valid || ( this.fileData === undefined && addFile ) || this.isSubmitting ) return;

    this.isSubmitting = true;
    this._requestResearchService.updateProduct( this.onShowForm.product.id, data ).subscribe( {
      complete: () => {
        this.closeFormModal();
        this.editedProduct.emit();
      }, error: ( err: Error ) => console.log( err )
    } );
  }

  /**
   * gets the list of subtypes
   * @param categoryID
   */
  getSubtype( categoryID:number ) {
    this.fileForm.get('subtype')?.reset('');
    this.productSubtypeList = [];

    let filter = JSON.stringify( {
      where: { categoryProductID: categoryID }
    } )

    this._requestResearchService.getProductSubtypes( filter ).subscribe( {
      next: ( resp ) => {
        this.productSubtypeList = resp.map(e => ({id:e.id, text:e.name}));
      }, error: ( err: Error ) => console.log( err )
    } );
  }

  /**
   * gets the data from the file
   * @param data 
   */
  getFile( data: InfoFileData ) {
    this.fileData = data;
    this.isFileUploaded = true;

    this.fileForm.patchValue({
      linkDrive: this.urlBackV1 + this.fileData?.urlFile,
      addFile: false
    })
  }

  /**
   * get the list of projects
   * @returns 
   */
  getProjects() {
    let filter = JSON.stringify( {
      where: { researchGroupID: this.groupId }
    } )
    return this._requestResearchService.getProjects( filter );
  }


  /**
  * clear the form data
  */
  clearForm() {
    this.fileForm.reset( {
      type: '',
      subtype: '',
      project: '',
      addFile: false,
    } );
  }

  /**
   * close modal form
   */
  closeFormModal() {
    this.clearForm();

    this.onShowForm.showForm = false;
    this.closeModal.emit();
    this.isSubmitting = false;
    this.fileData = undefined;
    this.isFileUploaded = false;
  }
}
