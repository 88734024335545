import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';

@Component( {
  selector: 'request-textarea-input',
  templateUrl: './textarea-input.component.html',
  styleUrls: [ './textarea-input.component.css' ]
} )
export class TextareaInputComponent implements OnInit {

  constructor () {
    //
  }

  @Input( 'parentForm' ) parentForm: FormGroup = new FormGroup( {} );
  @Input( 'controlName' ) controlName: string = '';
  @Input( 'maxLength' ) maxLength: number = 0;
  @Input( 'title' ) title: string = '';
  @Input( 'placeholder' ) placeholder: string = '';

  ngOnInit(): void {
    //
  }

  public onInput(event: Event) {
    const input = event.target as HTMLInputElement;
    if(!this.maxLength) return;

    if (input.value.length > this.maxLength) {
      input.value = input.value.slice(0, this.maxLength);
      this.parentForm.get(this.controlName)?.setValue(input.value);
    }
  }
}
