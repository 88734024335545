import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { RequestResearchService } from 'src/app/services/research/research.service';

@Component( {
  selector: 'edit-event-form',
  templateUrl: './edit-event-form.component.html',
  styleUrls: [ './edit-event-form.component.css' ]
} )
export class EditEventFormComponent {

 
  constructor (private _requestResearchService: RequestResearchService, private _fb: FormBuilder ) {
  }

  //emits a call each time a new events is edited
  @Output( 'editedEvent' ) editedEvent: EventEmitter<void> = new EventEmitter();

  //issues a call when the modal is closed
  @Output( 'closeModal' ) closeModal: EventEmitter<void> = new EventEmitter();

  //allows to show or hide the events form
  private _onShowForm:any = {};
  @Input( 'onShowForm' ) set onShowForm(value:any){
    this._onShowForm = value;
    if(value.showForm === true)
      this.editForm();
  }

  get onShowForm(){
    return this._onShowForm;
  }

  //receives the id of the current group
  @Input( 'groupId' ) groupId: number = 0;

  //allows to establish the validity of the form
  public isFormValid: boolean = true;

  public eventsForm: FormGroup = this._fb.group( {
    name: [ '', Validators.required ],
    date: [ '', Validators.required ],
    description: [ '' ],
    unitsParticipant: [ '', Validators.required ],
  } );

  //checks whether data is being uploaded or not
  public isSubmitting:boolean = false;

  /**
   * loads the data of the event to be edited
   */
  editForm(){
    this._requestResearchService.getProduct( this.onShowForm.eventId ).subscribe( {
      next: ( event: any ) => {
        this.eventsForm.patchValue( {
          description: event.description,
          name: event.name,
          date: new Date( event.date ).toISOString().substring( 0, 10 ),
          unitsParticipant: event.unitsParticipant,
        } );
      }, error: ( err: Error ) => console.log( err )
    } );
  }

  /**
   * edits the event
   * @returns 
   */
  submitEvent() {
    let data = {
      name:  this.eventsForm.get('name')?.value,
      date:  this.eventsForm.get('date')?.value,
      description: this.eventsForm.get( 'description' )?.value,
      unitsParticipant:  this.eventsForm.get( 'unitsParticipant' )?.value,
    }

    if ( !this.eventsForm.valid || this.isSubmitting ) return;

    this.isSubmitting = true;
    this._requestResearchService.updateProduct(this.onShowForm.eventId, data ).subscribe( {
      complete: () => {
        this.closeEventModal();
        this.editedEvent.emit();
      }, error: ( err: Error ) => console.log( err )
    } )
  }

  /**
  * clears the form data
  */
   clearForm() {
    this.eventsForm.reset( {
      type: '',
      subtype: '',
      project: '',
      addFile: false
    } );
  }

  /**
  *  closes and clears the events modal
  */
  closeEventModal() {
    this.clearForm();
    this.closeModal.emit();
    this.onShowForm.showForm = false;
    this.isFormValid = true;
    this.isSubmitting = false;
  }

}
