<div class="member-list">
  <ng-container [ngSwitch]="sections">
    <ng-container *ngSwitchCase="'mainSection'" [ngTemplateOutlet]="mainSection"></ng-container>

    <request-projects *ngSwitchCase="'projects'" (closeSection)="sections = 'mainSection'"
      [selectedGroupId]="selectedGroupId" [memberList]="memberList">
    </request-projects>

    <request-events *ngSwitchCase="'events'" (closeSection)="sections = 'mainSection'" [selectedGroupId]="selectedGroupId"></request-events>

    <request-scientific-production *ngSwitchCase="'scientific'" (closeSection)="sections = 'mainSection'"
      [selectedGroupId]="selectedGroupId"></request-scientific-production>
  </ng-container>
</div>

<ng-template #mainSection>
  <div class="members-list__header">
    <button class="button button--none" (click)="closeMemberList.emit()">
      <em class="icon-i-close"></em>
    </button>

    <div class="members-list__header-actions">
      <button class="button button--dark" (click)="sections = 'projects'">Proyectos</button>

      <div class="header-actions__stats">
        <button class="button button--dark" (click)="showStatsModal = true">Estadísticas</button>
        <request-group-stats 
          [showStats]="showStatsModal" 
          [groupId]="[selectedGroupId]" 
          [filterList]="['Producción científica', 'Proyectos', 'Inscritos']"
          (closeModal)="showStatsModal = false">
        </request-group-stats>
      </div>
      <button class="button button--dark" (click)="sections = 'events'">Eventos</button>
      <button class="button button--dark" (click)="sections = 'scientific'">Producción científica</button>
    </div>
  </div>

  <!-- #region SEARCH BAR -->
  <h2 class="members-text"><span class="members-text--bold">Integrantes </span>
    Buscar para gestionar o agregar integrantes
  </h2>

  <div class="members-list__actions">
    <request-filter-input (value)="filterMemberList($event)"></request-filter-input>
    <button class="button button--dark button--plus-icon" (click)="showAddMemberModal = true"
      *ngIf="rolesService.allowedRoles.ADMIN || rolesService.allowedRoles.LEADER">
      <em class="icon-i-plus-circle"></em>
      Nuevo integrante
    </button>
  </div>
  <!-- #endregion -->

  <div class="members">
    <request-member-card (editMember)="getMemberToEdit(member)" [memberData]="member" *ngFor="let member of filteredList"></request-member-card>
  </div>

  <request-add-member-modal [selectedGroupId]="selectedGroupId" [userListToExclude]="userListToExclude"
    (isMemberAdded)="refreshMemberList()" (closeModal)="showAddMemberModal = false" [showModal]="showAddMemberModal"
    *ngIf="rolesService.allowedRoles.ADMIN || rolesService.allowedRoles.LEADER">
  </request-add-member-modal>

  <request-edit-member-modal [onShowModal]="{ member: memberToEdit, showModal:showEditModal }"
    (refreshList)="refreshMemberList()" (closeModal)="showEditModal = false"
    *ngIf="rolesService.allowedRoles.ADMIN || rolesService.allowedRoles.LEADER">
  </request-edit-member-modal>
</ng-template>
