<div class="project-card">
  <button class="button button--edit" (click)="editProject.emit()" 
    *ngIf="rolesService.allowedRoles.ADMIN || rolesService.allowedRoles.LEADER">
    <em class="icon-i-filled-edit-directive"></em>
  </button>

  <div class="project-card__container">
    <p class="project-card__text">
      <span class="project-card__text--bold">Nombre del proyecto: </span>
      {{ projectInfo.name }}
    </p>
    <p class="project-card__text" [ngClass]="'project-card__text--space-right'">
      <span class="project-card__text--bold">Fecha del informe: </span>
      {{ projectInfo.dateUpdate | date:'dd/MM/yyyy' }}
    </p>
    <p class="project-card__text">
      <span class="project-card__text--bold">Fecha de inicio: </span>
      {{ projectInfo.dateStart | date:'dd/MM/yyyy' }}
    </p>
    <p class="project-card__text" *ngIf="projectInfo.dateEnd">
      <span class="project-card__text--bold">Fecha de finalización: </span>
      {{ projectInfo.dateEnd | date:'dd/MM/yyyy' }}
    </p>
    <p class="project-card__text">
      <span class="project-card__text--bold">Investigador principal: </span>
      {{ projectInfo.members.userapp.Name1 }} {{ projectInfo.members.userapp.LastName1 }}
    </p>
    <p class="project-card__text">
      <span class="project-card__text--bold">Descripción: </span>
      {{ projectInfo.description }}
    </p>
    <p class="project-card__text" *ngIf="projectInfo.school">
      <span class="project-card__text--bold">Escuela: </span>
      {{ projectInfo.school.NameTSchool }}
    </p>
    <p class="project-card__text" *ngIf="projectInfo.unit">
      <span class="project-card__text--bold">Unidad: </span>
      {{ projectInfo.unit.nameUnit}}
    </p>
    <p class="project-card__text" *ngIf="projectInfo.researchArea">
      <span class="project-card__text--bold">Área: </span>
      {{ projectInfo.researchArea.NameArea}}
    </p>
    <p class="project-card__text">
      <span class="project-card__text--bold">Investigador principal: </span>
      {{ projectInfo.members.userapp.Name1}} {{ projectInfo.members.userapp.LastName1}}
    </p>
    <p class="project-card__text">
      <span class="project-card__text--bold">Otros investigadores: </span>
      <ng-container *ngFor="let researcher of projectInfo.projectMembers; let i = index">
        {{ researcher.projectRol+': ' }}
        {{ researcher.members.userapp.Name1 | trimWhitespace }}
        {{ researcher.members.userapp.LastName1 | trimWhitespace }}{{ i < projectInfo.projectMembers.length - 1 ? ',' : '' }}
      </ng-container>
    </p>
    <p class="project-card__text">
      <span class="project-card__text--bold">Grupo de investigación: </span>
      {{ projectInfo.researchGroup.name }}
    </p>
    <p class="project-card__text">
      <span class="project-card__text--bold">Presupuesto: </span>
      {{ projectInfo.totalBudget | currency:"COP ":"symbol" }}
    </p>
    <p class="project-card__text">
      <span class="project-card__text--bold">Presupuesto contrapartida: </span>
      {{ projectInfo.counterpartBudget | currency:"COP ":"symbol" }}
    </p>
    <p class="project-card__text">
      <span class="project-card__text--bold">Presupuesto recurso asignado por DITEC: </span>
      {{ projectInfo.DITECBudget | currency:"COP ":"symbol" }}
    </p>
    <p class="project-card__text">
      <span class="project-card__text--bold">Presupuesto asignado por otras entidades: </span>
      {{ projectInfo.otherBudgets | currency:"COP ":"symbol" }}
    </p>
    <p  class="project-card__text">
      <span class="project-card__text--bold">Link carpeta drive: </span>
      <a [href]="urlBackV1 + projectInfo.linkDrive" target="_blank">Ver archivo</a>
    </p>
    <p  class="project-card__text">
      <span class="project-card__text--bold">Capacidad a impactar: </span>
      {{ projectInfo.capacity }}
    </p>
    <p class="project-card__text">
      <span class="project-card__text--bold">Línea: </span>
      <ng-container *ngFor="let item of projectInfo.projectToTypeResearches; let i = index">
        <br>
        <span>{{i+1}} - {{item.typeResearch.name}}</span>
      </ng-container>
    </p>
  </div>

  <div class="project-card__files">
    <span class="project-card__text project-card__text--bold">Archivos de proyecto: </span>
    <div class="project-card__files-container">
      <a [href]="urlBackV1 + document.linkDrive" target="_blank"
        class="button button--download" *ngFor="let document of documentList">
        {{ document.name }}
        <em class="icon-i-download"></em>
      </a>
      <button type="button" class="button button--plus-icon" (click)="showAddProjects = !showAddProjects;">
        <em class="icon-i-plus-circle" [ngClass]="!showAddProjects ? 'icon-i-plus-circle' : 'icon-i-delete'"></em>
      </button>
    </div>
  </div>

  <upload-file-box [containerName]="'scientificinfo'" (responseDataFile)="setProjectDocuments($event)" 
    *ngIf="(rolesService.allowedRoles.ADMIN || rolesService.allowedRoles.LEADER) && showAddProjects"
    [maxMbSize]="25000000" [smallTitle]="'pdf (max 25MB)'">
  </upload-file-box>
</div>
