import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DatePillComponent } from './date-pill.component';
import { FormsModule } from '@angular/forms';

@NgModule({
  declarations: [
    DatePillComponent,
  ],
  imports: [
    FormsModule,
    CommonModule
  ],
  exports:[
    DatePillComponent,
  ]
})
export class DatePillModule { }
