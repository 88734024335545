import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MemberI } from 'src/app/models/researchModels/member';

@Component( {
  selector: 'request-project-member-select-input',
  templateUrl: './project-member-select-input.component.html',
  styleUrls: [ './project-member-select-input.component.css' ]
} )
export class ProjectMemberSelectInputComponent implements OnInit {

  constructor () {
    //
   }

  //search for input 
  @ViewChild( 'filterInput' ) filterInput: ElementRef;

  //receives the maximum number of elements that can be added
  @Input( 'maxMembers' ) maxMembers: number = 8;
  private _maxMembersCounter: number = 0;

  //receives an extra conditional for the input
  @Input( 'extraValidation' ) extraValidation: boolean = false;

  //receives a list of members to be excluded
  @Input( 'membersToExclude' ) membersToExclude: MemberI[] = [];

  //receive the list of members
  @Input( 'memberList' ) memberList: MemberI[] = [];

  //label title
  @Input( 'title' ) title: string = '';

  //input id
  @Input('inputId') inputId: string = '';

  //disables input
  @Input( 'disabled' ) disabled: boolean = false;

  //emits the members to be added
  @Output( 'addedMembers' ) addedMembers: EventEmitter<MemberI[]> = new EventEmitter();

  //hides the input or not
  public hideInput: boolean = false;

  //stores the members to be added
  public membersToAdd: MemberI[] = [];

  //allows to show or not to show the search dialog box
  public showDialog: boolean = false;

  //stores the list of filtered members
  public filteredMembers: MemberI[] = [];

  //checks whether the input is valid or not
  public isInvalid: boolean = false;

  //checks whether you click inside or outside the input
  public clickedInside: boolean = false;

  ngOnInit(): void { 
    //
   }

  /**
   * members filter
   * @param event 
   */
  filterMembers( event ) {
    let toExclude = this.membersToAdd.concat( this.membersToExclude );    
    let value = event.target.value.toLowerCase();
    this.showDialog = false;
    this.filteredMembers = [];

    if ( this.disabled || value.length < 4 )
      return;
    
    this.showDialog = true;

    //TODO: SET FILTER LIMIT TO 3 RESULTS
    let filteredMembers = [...this.memberList].filter( ( m ) => ( m.userapp!.email.toLowerCase().includes( value ) ||
      m.userapp!.UserDocuments?.[ 0 ]?.Document?.toLowerCase().includes( value ) ) ).slice( 0, 3 );

      toExclude.forEach( ( e ) => {
      filteredMembers.forEach( (x,i) => {
        if ( e.id === x.id )
          filteredMembers.splice( i, 1 );
      } )
    } )

    this.filteredMembers = filteredMembers;
  }

  /**
   * when clicking on the input
   * @returns 
   */
  onClick(){
    if(this.disabled)
      return;
      
    this.showDialog = this.filteredMembers.length > 0
  }

  /**
  * temporarily stores possible group members
  * @param data 
  */
  addMember( data: MemberI ) {
    if(this.maxMembers === 0)
      return;

    if (this._maxMembersCounter < this.maxMembers ) {
      this._maxMembersCounter += 1;
      this.setMembers( data );
    } 

    if (this._maxMembersCounter >= this.maxMembers ) 
      this.hideInput = true;

    this.isInvalid = false;
  }

  /**
  * adds an item to the list of possible members to be added
  * @param data 
  */
  setMembers( data: MemberI ) {
    this.filterInput.nativeElement.value = '';
    this.filteredMembers = [];

    this.membersToAdd.push( data );
    this.addedMembers.emit( this.membersToAdd );
  }

  /**
  * deletes possible members
  * @param member 
  */
  deleteMember( userToRemove: MemberI ) {
    this.hideInput = false;
    if ( this.maxMembers > 0 )
      this._maxMembersCounter -= 1;

    this.membersToAdd.forEach( ( addedUser, index ) => {
      if ( addedUser.id === userToRemove.id )
        this.membersToAdd.splice( index, 1 );
    } );

    this.addedMembers.emit( this.membersToAdd );
  }

  //receives the id of the member to add and adds it without the need to type the name and click on add
  setMemberAutomatically(memberId){
    let member:MemberI[] = this.memberList.filter( ( m:MemberI ) => m.id === memberId );
    this.addMember(member[0]);
  }

  /**
  * clears the selector data
  */
  clearData() {
    this.filterInput.nativeElement.value = '';
    this.filteredMembers = [];
    this.membersToAdd = [];
    this.membersToExclude = [];
    this._maxMembersCounter = 0;
    this.hideInput = false;
    this.isInvalid = false;
    this.clickedInside = false;
  }

  @HostListener( 'document:click', [ '$event' ] )
  clickOutside( e ) {
    if(e.target.id === this.inputId){
      this.showDialog = true;
      this.clickedInside = true;
    }
    else if(!e.target.closest( '.member-select__dialog' )){
      this.showDialog = false;
      if( this.membersToAdd.length === 0 && this.clickedInside){
        this.isInvalid = true;
        this.clickedInside = false;
      }
    }
  }

}
