import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { EmptyRouteComponent } from './empty-route/empty-route.component';
import { RequestService } from './services/request.service';
import { HttpService } from './services/http.service';
import { HttpClientModule } from '@angular/common/http';
import { AttachmentService } from './services/attachment.service';
import { ResearchComponent } from './pages/research/research.component';
import { GroupFormComponent } from './components/research/group-form/create-group-form/group-form.component';
import { GroupCardComponent } from './components/research/group-card/group-card.component';
import { FilterPillComponent } from './components/research/filter-pill/filter-pill.component';
import { FilterInputModule } from './components/shared/filter-input/filter-input.module';
import { SelectorsModule } from './components/shared/selectors/selectors.module';
import { FormInputsModule } from './components/shared/form-inputs/form-inputs.module';
import { MemberListModule } from './components/research/member-list/member-list.module';
import { DatePillModule } from './components/shared/date-pill/date-pill.module';
import { GroupStatsModule } from './components/shared/group-stats/group-stats.module';
import { EditGroupFormComponent } from './components/research/group-form/edit-group-form/edit-group-form.component';

@NgModule({
  declarations: [
    AppComponent,
    EmptyRouteComponent,

    ResearchComponent,

    GroupFormComponent,
    EditGroupFormComponent,

    GroupCardComponent,
    FilterPillComponent,
  ],
  imports: [
    FormsModule,
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FilterInputModule,
    SelectorsModule,
    FormInputsModule,
    ReactiveFormsModule,
    MemberListModule,
    DatePillModule,
    CommonModule,
    GroupStatsModule
  ],
  providers: [
    RequestService,
    AttachmentService,
    HttpService,
  ],
  bootstrap: [ AppComponent ]
} )
export class AppModule { }
