<div>
  <input type="radio" [id]="buttonId" name="groupCard" class="rad" hidden (change)="onSelected.emit($event)"
    [checked]="activateCard">
  <label class="group-card" for="{{ buttonId }}">
    <h2 class="group-card__title">{{ groupData.name }}</h2>

    <p class="group-card__text">
      <span class="group-card__text--bold">{{ groupData.typeRxGroup.id == 3 ? 'Líder de grupo' :
        groupData.typeRxGroup.id == 1 ? 'Director de semillero' : 'Director opción de grado' }}:
      </span>
      {{ groupData.userapp.Name1 | titlecase }}
      {{ groupData.userapp.Name2 | titlecase }}
      {{ groupData.userapp.LastName1 | titlecase }}
      {{ groupData.userapp.LastName2 | titlecase }}
    </p>

    <p class="group-card__text" *ngIf="groupData.researchGroupToTypeResearches.length > 0">
      <span class="group-card__text--bold">Línea: </span>
      <ng-container *ngFor="let typeResearch of groupData.researchGroupToTypeResearches; let i = index">
        {{ typeResearch.typeResearch.name }}{{ i < groupData.researchGroupToTypeResearches.length - 1 ? ',' : '' }}
          </ng-container>
    </p>

    <p class="group-card__text">
      <span class="group-card__text--bold">Área: </span>
      {{ groupData.researchArea.NameArea | titlecase }}
    </p>

    <p class="group-card__text">
      <span class="group-card__text--bold">Tipo: </span>
      {{ groupData.typeRxGroup.name }}
    </p>

    <p class="group-card__text">
      <span class="group-card__text--bold">Escuela: </span>
      {{ groupData.school.NameTSchool }}
    </p>

    <p class="group-card__text" *ngIf="groupData.unit">
      <span class="group-card__text--bold">Unidad: </span>
      {{ groupData.unit.nameUnit }}
    </p>

    <p class="group-card__text" *ngIf="groupData.typeRxGroup.id == 3">
      <span class="group-card__text--bold">Categoría: </span>
      {{ groupData.category }}
    </p>

    <p class="group-card__text">
      <span class="group-card__text--bold">Descripción: </span>
      {{ groupData.description }}
    </p>

    <p class="group-card__text">
      <span class="group-card__text--bold">Fecha de inicio: </span>
      {{ groupData.startDate | date:'dd/MM/yyyy' }}
    </p>

    <p class="group-card__text">
      <span class="group-card__text--bold">Fecha de fin: </span>
      {{ groupData.endDate | date:'dd/MM/yyyy' }}
    </p>

    <p class="group-card__text" *ngIf="groupData.researchGroupID !== null">
      <span class="group-card__text--bold">Semillero: </span>
      {{ groupData.researchGroup.name }}
    </p>
  </label>
</div>