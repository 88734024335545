import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { forkJoin, map, switchMap } from 'rxjs';
import { ProjectMemberSelectInputComponent } from 'src/app/components/shared/selectors/project-member-select-input/project-member-select-input.component';
import { TypeResearchSelectInputComponent } from 'src/app/components/shared/selectors/type-research-select-input/type-research-select-input.component';
import { MemberI } from 'src/app/models/researchModels/member';
import { RequestResearchService } from 'src/app/services/research/research.service';


interface Select {
  id: string | number;
  text: string;
};

@Component({
  selector: 'request-edit-project-form',
  templateUrl: './edit-project-form.component.html',
  styleUrls: ['./edit-project-form.component.css']
})
export class EditProjectFormComponent implements OnInit {

  constructor(private _fb: FormBuilder, private _requestResearchService: RequestResearchService) {
    //
  }

  //searches for members selectable components
  @ViewChild('mainResearcherInput') mainResearcherInput: ProjectMemberSelectInputComponent;
  @ViewChild('typeResearch') typeResearch: TypeResearchSelectInputComponent;
  @ViewChild('roleSelectInput') roleSelectInput: ProjectMemberSelectInputComponent;

  //emits a call each time a new project is created
  @Output('editedProject') editedProject: EventEmitter<void> = new EventEmitter();

  //issues a call when the modal is closed
  @Output('closeModal') closeModal: EventEmitter<void> = new EventEmitter();

  //allows to show or hide the project form
  private _onShowForm: any = {};
  @Input('onShowForm') set onShowForm(value: any) {
    this._onShowForm = value;
    if (value.showForm === true)
      this.editProject();
  }

  get onShowForm() {
    return this._onShowForm;
  }

  //receives the list of members of the current group
  @Input('memberList') memberList: MemberI[] = [];

  //project form
  public projectForm: FormGroup =  this._fb.group({
    percentageExecuted: [0, Validators.min(0)],
    counterpartBudget: [0, Validators.min(0)],
    otherBudgets: [0, Validators.min(0)],
    projectName: ['', Validators.required],
    description: ['', Validators.required],
    totalBudget: [0, Validators.min(0)],
    DITECBudget: [0, Validators.min(0)],
    linkDrive: [''],
    dateStart: ['', Validators.required],
    capacity: ['', Validators.required],
    dateEnd: [null],
    unity: [''],
    area: [''],
    role: [''],
    school: [''],
  });

  //validates if user and role are valid
  public isRoleFormValid: boolean = true;

  //gets the main research to be added
  public mainResearcher?: MemberI = undefined;

  //stores a list of other researchers to add
  public otherResearchers: any[] = [];

  //temporarily stores the other researcher to be added
  public tempOtherResearcher: MemberI[] = [];

  //obtains a list of members who should not appear in the selector.
  public toExclude: MemberI[] = [];

  //allows to show or hide the add member and role section
  public showRoleSection: boolean = false;

  //stores a list of schools
  public schoolList: Select[] = [];

  //stores a list of areas
  public areaList: Select[] = [];

  //checks whether data is being uploaded or not
  public isSubmitting: boolean = false;

  //stores new researchers to be added
  private _newOtherResearchers: any[] = [];

  //stores the new researchers to be deleted
  private _otherResearchersToDelete: any[] = [];

  //stores the lines of research to be added
  public researchLines: any[] = [];
  public unityList: Select[] = [];
  private _researchLinesCopy: any[] = [];

  //stores the lines of research to be added
  public _newResearchLines: any[] = [];

  //stores the research lines to be deleted
  public _researchLinesToDelete: any[] = [];

  public capacity: Select[] = [
    {id: "Mando tipo misión", text: 'Mando tipo misión' },
    {id: "Fuegos", text: 'Fuegos' },
    {id: "Sostenimiento", text: 'Sostenimiento' },
    {id: "Protección", text: 'Protección' },
    {id: "Movimiento y maniobra", text: 'Movimiento y maniobra' },
  ];

  ngOnInit(): void {
    this.schoolList = this._requestResearchService.currentSchools.map(e => ({ id: e.id, text: e.NameTSchool }));
  }


  /**
   * obtains the list of areas and seedbedGroups
   * @param schoolId
   */
  getUnitContent(schoolId: number) {
    this.projectForm.get('area')?.reset('');
    this.projectForm.get('unity')?.reset('');

    this.areaList = [];
    const unitFilter = JSON.stringify({ where: { schoolID: schoolId } });
    this._requestResearchService.getUnities(unitFilter).subscribe({
      next: (resp: any[]) => {
        this.unityList = resp.sort((a, b) =>
          a.nameUnit.localeCompare(b.nameUnit)
        ).map(e => ({ id: e.id, text: e.nameUnit }));
      },
      error: (err) => console.log(err)
    });
  }

  /**
   * get the area depending on the school
   * @param unityId 
   */
  getArea(unityId) {
    this.projectForm.get('area')?.reset('');
    this.areaList = [];
    let filter = JSON.stringify({
      where: { SchoolID: unityId }
    })

    this._requestResearchService.getResearchAreas(filter).subscribe({
      next: (researchAreas) => { this.areaList = researchAreas.map(e => ({id: e.id, text:e.NameArea})); },
      error: (err) => console.log(err)
    });
  }

  /**
   * Loads the current project data
   */
  editProject() {
    forkJoin({
      project: this._requestResearchService.getProject(this.onShowForm.projectId),
      members: this.getProjectMembers(this.onShowForm.projectId),
      rLines: this.getRLines(this.onShowForm.projectId),
    }).pipe(
      switchMap(({ project, members, rLines }: any) =>
        this._requestResearchService.getResearchAreas(JSON.stringify({ where: { SchoolID: project.schoolID } }))
          .pipe(map((areas) => ({ project, areas, members, rLines })))),
      switchMap(({ project, areas, members, rLines }) =>
        this._requestResearchService.getUnities(JSON.stringify({ where: { schoolID: project.schoolID, } }))
          .pipe(map(unities => ({ unities, project, areas, members, rLines })))
      )
    ).subscribe({
      next: ({ project, areas, members, rLines, unities }: any) => {
        this.unityList = unities.map(e => ({ id: e.id, text: e.nameUnit }));
   
        for (const rLine of rLines) {
          this.typeResearch.setRLinesAutomatically(rLine.typeResearchID);
        }

        this._researchLinesCopy = rLines.map(rLineCopy => {
          return {
            id: rLineCopy.id, line: this.researchLines.find(rLine => rLine.id === rLineCopy.typeResearchID)
          }
        });

        this.mainResearcherInput.setMemberAutomatically(project.mainResearchID);
        members.forEach(m => {
          this.otherResearchers.push({ id: m.id, member: m.members, role: m.projectRol })

          let toExclude = this.toExclude.concat(m.members);
          this.toExclude = toExclude;
        });

        this.areaList = areas.map(e => ({id: e.id, text:e.NameArea}));
        this.projectForm.patchValue({ area: project.researchAreaID });

        this.projectForm.patchValue({
          school: project.schoolID || '',
          unity: project.unitID || '',
          projectName: project.name,
          linkDrive: project.linkDrive,
          description: project.description,
          area: project.researchAreaID || '',
          totalBudget: project.totalBudget,
          DITECBudget: project.DITECBudget,
          otherBudgets: project.otherBudgets,
          counterpartBudget: project.counterpartBudget,
          percentageExecuted: project.percentageExecuted,
          capacity: project.capacity,
          dateEnd: new Date(project.dateEnd).toISOString().substring(0, 10),
          dateStart: new Date(project.dateStart).toISOString().substring(0, 10),
        });
      },
      error: (err) => { console.log(err) }
    });
  }

  /**
   * searches for a list of members belonging to the current project.
   * @param projectId
   * @returns
   */
  getProjectMembers(projectId: number) {
    let filter = JSON.stringify({
      include: [{ members: 'userapp' }],
      where: { projectID: projectId }
    })
    return this._requestResearchService.getProjectMembers(filter);
  }

  getRLines(projectId: number) {
    let filter = JSON.stringify({
      where: { projectID: projectId }
    })
    return this._requestResearchService.getProjectTypeResearches(filter);
  }

  /**
   * updates a project
   */
  submitProject() {
    if (!(this.projectForm.valid && this.mainResearcher !== undefined && this.otherResearchers.length > 0 && this.researchLines.length > 0) ||
      this.isSubmitting)
      return;

    this.isSubmitting = true;
    let formData: any = {
      percentageExecuted: this.projectForm.get('percentageExecuted')?.value,
      counterpartBudget: this.projectForm.get('counterpartBudget')?.value,
      mainResearchID: this.mainResearcher.id,
      researchAreaID: this.projectForm.get( 'area' )?.value || null,
      otherBudgets: this.projectForm.get('otherBudgets')?.value,
      description: this.projectForm.get('description')?.value,
      totalBudget: this.projectForm.get('totalBudget')?.value,
      DITECBudget: this.projectForm.get('counterpartBudget')?.value,
      dateUpdate: new Date(),
      dateStart: this.projectForm.get('dateStart')?.value,
      linkDrive: this.projectForm.get('linkDrive')?.value,
      schoolID: this.projectForm.get( 'school' )?.value || null,
      capacity: this.projectForm.get('capacity')?.value,
      dateEnd: this.projectForm.get( 'dateEnd' )?.value || null,
      name: this.projectForm.get('projectName')?.value,
      unitID: this.projectForm.get('unity')?.value || null,
    }

    //#region ADD NEW RESEARCHES
    let otherResearchersObj = this._newOtherResearchers.map(r => ({
      memberID: r.member.id,
      projectID: this.onShowForm.projectId,
      projectRol: r.role
    }));

    //new members observable
    let otherResearchersObservable = otherResearchersObj.map(data =>
      this._requestResearchService.setProjectMember(data)
    );

    //members to be delete observable
    let otherResearchersToDeleteObservable = this._otherResearchersToDelete.map(id =>
      this._requestResearchService.removeProjectMembers(id)
    );
    //#endregion

    //#region ADD NEW LINES
    let rLinesObj = this._newResearchLines.map(rLine => {
      return ({
        projectID: this.onShowForm.projectId,
        typeResearchID: rLine.id,
      });
    });

    //new lines observable
    let newRLinesObservable = rLinesObj.map(data =>
      this._requestResearchService.setProjectTypeResearches(data)
    );

    //lines to be delete observable
    let rLinesToDeleteObservable = this._researchLinesToDelete.map(id =>
      this._requestResearchService.removeProjectTypeResearches(id)
    );
    //#endregion

    forkJoin([
      this._requestResearchService.updateProject(this.onShowForm.projectId, formData),
      //member list
      ...otherResearchersToDeleteObservable,
      ...otherResearchersObservable,

      //Line list
      ...rLinesToDeleteObservable,
      ...newRLinesObservable
    ]).subscribe({
      complete: () => {
        this.closeFormModal();
        this.editedProject.emit();
        this._newOtherResearchers = [];
        this._otherResearchersToDelete = [];
      }, error: (err: Error) => console.log(err)
    })
  }

  /**
  * receive the list of lines of research to be added
  * @param members
  */
  getRLinesToAdd(researchLines: any[]) {

    this._newResearchLines = [];
    this._researchLinesToDelete = [];
    this.researchLines = researchLines;

    if (this._researchLinesCopy.length > 0) {
      let rLinesCopyId = this._researchLinesCopy.map(e => e.line.id);
      let researchLinesId = this.researchLines.map(e => e.id);

      //find new lines
      this.researchLines.forEach(e => {
        if (!rLinesCopyId.includes(e.id))
          this._newResearchLines.push(e);
      });

      //find lines to delete
      this._researchLinesCopy.forEach(e => {
        if (!researchLinesId.includes(e.line.id))
          this._researchLinesToDelete.push(e.id);
      })
    }

  }

  /**
   * add the member and their respective role to the list of other researchers to be added
   * @returns
   */
  setMemberAndRole() {
    this.isRoleFormValid = this.projectForm.get('role')?.value !== '' && this.tempOtherResearcher.length > 0;

    if (!this.isRoleFormValid)
      return;

    let newMember = {
      member: this.tempOtherResearcher[0],
      role: this.projectForm.get('role')?.value
    }
    this.otherResearchers.push(newMember);

    //Members to add to the db
    this._newOtherResearchers.push(newMember);

    let toExclude = this.toExclude.concat(this.tempOtherResearcher[0]);
    this.toExclude = toExclude;

    this.tempOtherResearcher = [];
    this.roleSelectInput.clearData();
    this.projectForm.controls['role'].reset();
  }

  /**
  * gets the main researcher to add
  * @param mainResearcher
  */
  getMainResearcher(mainResearcher: MemberI[]) {
    if (mainResearcher.length === 0)
      this.toExclude.forEach((e, i) => {
        if (e.id === this.mainResearcher!.id) this.toExclude.splice(i, 1);
      })

    let toExclude = this.toExclude.concat(mainResearcher);
    this.toExclude = toExclude;
    this.mainResearcher = mainResearcher[0];
    this.roleSelectInput.clearData();
  }

  /**
   * obtains a list of other possible researchers to be added
   * @param otherResearcher
   */
  getOtherResearcher(otherResearcher: MemberI[]) {
    this.tempOtherResearcher = otherResearcher;
  }

  /**
   * removes the member from the list to be added and from the excluded list
   * @param data
   */
  onCloseOtherResearcherPill(data) {

    //remove member pill
    this.otherResearchers.forEach((r, i) => {
      if (r.member.id === data.member.id)
        this.otherResearchers.splice(i, 1);
    })

    //member id to remove from db
    if (data.id !== undefined)
      this._otherResearchersToDelete.push(data.id);

    //remove from users to be excluded
    this.toExclude.forEach((e, i) => {
      if (e.id === data.member.id)
        this.toExclude.splice(i, 1);
    })
  }

  /**
  * clear the form data
  */
  clearForm() {
    this.projectForm.reset({
      capacity: '',
      unity: '',
      area: '',
      role: '',
      school: '',
    });

    this.mainResearcherInput.clearData();
    this.roleSelectInput.clearData();
    this.tempOtherResearcher = [];
    this.otherResearchers = [];
    this.mainResearcher = undefined;
    this.toExclude = [];

    this.typeResearch.clearData();
    this._researchLinesCopy = [];
    this._newResearchLines = [];
    this._researchLinesToDelete = [];
  }

  /**
  * closes and clears the project modal
  */
  closeFormModal() {
    this.clearForm();
    this.onShowForm.showForm = false;
    this.closeModal.emit();
    this.isSubmitting = false;
    this.isRoleFormValid = true;
    this.showRoleSection = false;
    this._newOtherResearchers = [];
    this._otherResearchersToDelete = [];
  }
}