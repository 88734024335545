import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MemberSelectInputComponent } from './member-select-input/member-select-input.component';
import { TypeResearchSelectInputComponent } from './type-research-select-input/type-research-select-input.component';
import { ProjectMemberSelectInputComponent } from './project-member-select-input/project-member-select-input.component';
import { MemberPillModule } from '../member-pill/member-pill.module';

@NgModule({
  declarations: [
    MemberSelectInputComponent,
    TypeResearchSelectInputComponent,
    ProjectMemberSelectInputComponent,
  ],
  imports: [
    MemberPillModule,
    CommonModule
  ],
  exports:[
    MemberSelectInputComponent,
    ProjectMemberSelectInputComponent,
    TypeResearchSelectInputComponent
  ]
})
export class SelectorsModule { }
