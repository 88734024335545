export interface ProjectI {
  id?: number;
  researchGroupID: number;
  researchGroup:ResearchGroupI;
  projectToTypeResearches:{
    typeResearch:{name:string}
  }[]
  name: string;
  dateStart: Date;
  dateEnd: Date;
  mainResearchID: number;
  members: MembersI;
  description: string;
  schoolID: number;
  school: SchoolI;
  researchAreaID: number;
  researchArea: ResearchAreaI;
  projectMembers: ProjectMembersI[];
  totalBudget: number;
  counterpartBudget: number;
  DITECBudget: number;
  otherBudgets: number;
  percentageExecuted: number;
  linkDrive: string;
  capacity: string;
  dateUpdate: Date;
  unit:{
    nameUnit:string
  }
}

export interface SchoolI {
  id?: number;
  NameTSchool: string;
}

export interface ResearchAreaI {
  NameArea: string;
}

export interface MembersI {
  userapp: UserappI;
}

export interface UserappI {
  LastName1: string;
  LastName2: string;
  Name1: string;
  Name2: string;
}

export interface ProjectMembersI{
  members: MembersI;
  projectRol:string;
}

export interface ResearchGroupI{
  name: string;
}
