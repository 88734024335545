<form class="project-form__main" [ngClass]="onShowForm.showForm ? 'project-form__main--show' : ''" [formGroup]="projectForm"
  (submit)="submitProject()">
  <div class="project-form__panel">

    <div class="form__panel-right">
      <div class="project-form__input">
        <request-text-input [parentForm]="projectForm" [controlName]="'projectName'" [title]="'Nombre del proyecto *'"
          [placeholder]="'Escriba el nombre del proyecto'">
        </request-text-input>
      </div>

      <div class="project-form__input">
        <request-date-input [parentForm]="projectForm" [controlName]="'dateStart'" [title]="'Fecha de inicio *'">
        </request-date-input>
      </div>

      <div class="project-form__input">
        <request-date-input [parentForm]="projectForm" [controlName]="'dateEnd'" [title]="'Fecha de finalización'">
        </request-date-input>
      </div>

      <div class="project-form__input">
        <request-select-input [items]="schoolList" [parentForm]="projectForm" [controlName]="'school'" [title]="'Escuela'"
          [placeholder]="'Seleccione la escuela'" (onSelectChange)="getUnitContent($event)">
        </request-select-input>
      </div>

      <div class="project-form__input" [ngClass]="unityList.length === 0 ? 'disabled': ''">
        <request-select-input [items]="unityList" [parentForm]="projectForm" [controlName]="'unity'" [title]="'Unidad'"
          [placeholder]="'Seleccione la unidad'" (onSelectChange)="getArea($event)">
        </request-select-input>
      </div>

      <div class="project-form__input"
        [ngClass]="areaList.length === 0 ? 'disabled' : ''">
        <request-select-input [items]="areaList" [parentForm]="projectForm" [controlName]="'area'" [title]="'Área'"
          [placeholder]="'Elija el área'">
        </request-select-input>
      </div>

      <div class="project-form__input">
        <request-project-member-select-input [maxMembers]="1" [memberList]="memberList"
          [title]="'Ivestigador principal'" (addedMembers)="getMainResearcher($event)"
          [inputId]="'edit-main-researcher-select'" [membersToExclude]="toExclude" #mainResearcherInput>
        </request-project-member-select-input>
      </div>

      <ng-container *ngIf="memberList.length === 0">
        <div class="project-form__input">
          <div class="info-box">Para agregar usuarios al proyecto, es indispensable añadir integrantes al grupo.</div>
        </div>
      </ng-container>

      <div class="project-form__input">
        <label for="">Otros investigadores</label>

        <div class="other-researches__container">
          <ng-container *ngFor="let researcher of otherResearchers">
            <request-member-pill [content]="researcher.role+': '+researcher.member.userapp?.Name1!+
              ' '+researcher.member.userapp?.LastName1!" (closePill)="onCloseOtherResearcherPill(researcher)">
            </request-member-pill>
          </ng-container>

          <button type="button" class="button button--plus-icon" (click)="showRoleSection = !showRoleSection;">
            <em class="icon-i-plus-circle" [ngClass]="!showRoleSection ? 'icon-i-plus-circle' : 'icon-i-delete'"></em>
          </button>
        </div>

        <div class="role-section__container">
          <ng-container [ngTemplateOutlet]="memberRoleSection"></ng-container>
        </div>
      </div>

      <div class="project-form__input">
        <request-textarea-input [maxLength]="1000" [parentForm]="projectForm" [controlName]="'description'" [title]="'Descripción *'"
          [placeholder]="'Escriba la descripción del proyecto'">
        </request-textarea-input>
        <small class="text-counter">{{projectForm.get("description")?.value?.length || 0}} de 1000</small>
      </div>

      <button type="submit" class="button button--dark" [ngClass]="! (projectForm.valid && mainResearcher !== undefined && 
        otherResearchers.length > 0 && researchLines.length > 0 ) || isSubmitting ? 'button--disabled':''">
        Guardar Proyecto
      </button>
    </div>

    <div class="form__panel-left">
      <div class="project-form__input">
        <request-type-research-select-input  [title]="'Líneas de investigación'" 
          [inputId]="'edit-project-type-select'" (addedElements)="getRLinesToAdd($event)" #typeResearch > 
        </request-type-research-select-input>
      </div>

      <div class="project-form__input">
        <request-select-input [items]="capacity" [controlName]="'capacity'" [parentForm]="projectForm" [title]="'Capacidad a impactar *'" 
        [placeholder]="'Seleccione la capacidad a impactar'">
        </request-select-input>
      </div>
      
      <div class="project-form__input">
        <request-number-input [parentForm]="projectForm" [controlName]="'totalBudget'" [title]="'Porcentaje de avance presupuestal *'"
          [placeholder]="'Escriba el porcentaje'">
        </request-number-input>
      </div>

      <div class="project-form__input">
        <request-number-input [parentForm]="projectForm" [controlName]="'counterpartBudget'"
          [title]="'Presupuesto contrapartida *'" [placeholder]="'Escriba el presupuesto contrapartida'">
        </request-number-input>
      </div>

      <div class="project-form__input">
        <request-number-input [parentForm]="projectForm" [controlName]="'DITECBudget'" [title]="'Presupuesto DITEC *'"
          [placeholder]="'Escriba el presupuesto DITEC'">
        </request-number-input>
      </div>

      <div class="project-form__input">
        <request-number-input [parentForm]="projectForm" [controlName]="'otherBudgets'"
          [title]="'Presupuesto por otras entidades *'" [placeholder]="'Escriba el presupuesto por otras entidades'">
        </request-number-input>
      </div>

      <div class="project-form__input">
        <request-number-input [parentForm]="projectForm" [controlName]="'percentageExecuted'"
          [title]="'Porcentaje de Avance Técnico  *'" [placeholder]="'Escriba el avance'">
        </request-number-input>
      </div>

      <div class="project-form__input">
        <request-text-input [parentForm]="projectForm" [controlName]="'linkDrive'" [title]="'Link carpeta drive'"
          [placeholder]="'Link carpeta drive'">
        </request-text-input>
      </div>
    </div>

  </div>
</form>

<div class="modal-bg" (click)="closeFormModal()" [ngClass]="onShowForm.showForm ? 'modal-bg--show' : ''">
</div>

<ng-template #memberRoleSection>
  <div class="role-section" [ngClass]="showRoleSection ? 'role-section--show' : ''">
    <div class="role-form__input">
      <request-project-member-select-input [title]="'Investigador'" [memberList]="memberList" [maxMembers]="1"
        [disabled]="mainResearcher === undefined" [membersToExclude]="toExclude" [inputId]="'edit-role-members-select'"
        (addedMembers)="getOtherResearcher($event)" #roleSelectInput>
      </request-project-member-select-input>
    </div>

    <div [ngClass]="mainResearcher === undefined ? 'disabled' : ''">
      <div class="role-form__input">
        <request-text-input [parentForm]="projectForm" [controlName]="'role'" [title]="'Asignar rol'"
          [placeholder]="'Escriba el rol'"  
          [ngClass]="tempOtherResearcher.length === 0 && mainResearcher !== undefined  ? 'disabled' : ''">
        </request-text-input>
      </div>

      <button type="button" class="button button--blue button--confirm"
        [ngClass]="mainResearcher !== undefined && (tempOtherResearcher.length === 0 || 
        projectForm.get( 'role' )?.value === '') ? 'button--disabled' : ''"
        (click)="setMemberAndRole()" #confirmBtn>
        Confirmar
      </button>
    </div>
  </div>
</ng-template>