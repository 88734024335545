import { AfterViewInit, Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';
import { debounceTime, filter, fromEvent, map, switchMap } from 'rxjs';
import { RequestResearchService } from 'src/app/services/research/research.service';

@Component( {
  selector: 'request-type-research-select-input',
  templateUrl: './type-research-select-input.component.html',
  styleUrls: [ './type-research-select-input.component.css' ]
} )
export class TypeResearchSelectInputComponent implements OnInit {

  constructor ( private _requestResearchService: RequestResearchService ) {
    //
  }

  //receives the maximum number of elements that can be added
  @Input( 'maxLines' ) maxLines: number = 10;
  private _maxLinesCounter: number = 0;

  //receives an extra conditional for the input
  @Input( 'extraValidation' ) extraValidation: boolean = false;

  //label tittle
  @Input( 'title' ) title: string = '';

  @Input( 'validation' ) validation: boolean = true;
  @Input( 'defaultSelected' ) defaultSelected: number[] = [];

  //input id
  @Input('inputId') inputId: string = '';

  //emits the members to be added
  @Output( 'addedElements' ) addedElements: EventEmitter<any[]> = new EventEmitter();

  //hides the input or not
  public hideInput: boolean = false;

  //stores the members to be added
  public linesToAdd: any[] = [];

  //allows to show or not to show the search dialog box
  public showDialog: boolean = false;

  private _researchLineList: any[] = [];

  //stores the list of filtered elements
  public filteredRLines: any[] = [];

  //checks whether the input is valid or not
  public isInvalid: boolean = false;

  private _isClearingData:boolean = false;

  ngOnInit(): void {
    this._requestResearchService.getTypeResearch().subscribe({
      next:(resp:any[])=>{
        this._researchLineList = resp;
        this.filteredRLines = [...this._researchLineList];
        
        if(this.defaultSelected.length > 0 && resp.length > 0){
          resp.forEach(line => {
            if(this.defaultSelected.some(item => item === line.id)){
              this.linesToAdd.push( line );
              this.filteredRLines = this.filteredRLines.filter( (e:any) => e.id !== line.id );

              this._maxLinesCounter++;
            }
          });
          if ( this._maxLinesCounter >= this.maxLines ) this.hideInput = true;
        }
      }, error: ( err: Error ) => console.log( err )
    })
  }

  /**
  * temporarily stores possible group members
  * @param data 
  */
   addElement( data: any ) {
    if(this.maxLines === 0)
      return;

    if ( this._maxLinesCounter < this.maxLines ) {
      this._maxLinesCounter += 1;
      this.setLines( data );
    }

    if ( this._maxLinesCounter >= this.maxLines ) 
      this.hideInput = true;

    this.isInvalid = false;
  }

  /**
  * adds an item to the list of possible lines to be added
  * @param data 
  */
  setLines( data ) {
    this.linesToAdd.push( data );
    this.filteredRLines = this.filteredRLines.filter( (e:any) => e.id !== data.id );

    this.addedElements.emit( this.linesToAdd );
  }

  //receives the id of the research line to add and adds it without the need to type the name and click on add
  setRLinesAutomatically(rLineId){
    let rLine = this._researchLineList.filter( ( r ) => r.id === rLineId );
    this.addElement(rLine[0]);
  }

  /**
  * deletes possible elements
  * @param lineToRemove 
  */
  deleteElement( lineToRemove: any ) {
    this.hideInput = false;
    if ( this.maxLines > 0 )
      this._maxLinesCounter -= 1;

    this.linesToAdd.forEach( ( e, i ) => {
      if ( e.id === lineToRemove.id )
        this.linesToAdd.splice( i, 1 );
    } );


    //Reincorporate item to the list
    this.filteredRLines.unshift( [...this._researchLineList.filter( e => e.id === lineToRemove.id )][0] );
    this.addedElements.emit( this.linesToAdd );

    this.isInvalid = this.linesToAdd.length <= 0;
  }

  /**
   * clears the selector data
   */
  clearData() {
    this.filteredRLines = [...this._researchLineList];
    this.linesToAdd = [];
    this._maxLinesCounter = 0;
    this.hideInput = false;

    this._isClearingData = true;
  }

  @HostListener( 'document:click', [ '$event' ] )
  clickOutside( e ) {
    if(!(e.target.closest( '.research-select__dialog' ) || e.target.closest( '.research-select__input' ) ||  e.target.closest( '.button--add' ))){
      if( this.linesToAdd.length === 0 && this.showDialog)
        this.isInvalid = true;
      
      this.showDialog = false;

      if(this._isClearingData)
        this.isInvalid = false;
    }else{
      this._isClearingData = false;
      this.showDialog = true;
    }
  }
}
