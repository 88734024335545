import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { forkJoin, switchMap } from 'rxjs';
import { ProjectMemberSelectInputComponent } from 'src/app/components/shared/selectors/project-member-select-input/project-member-select-input.component';
import { TypeResearchSelectInputComponent } from 'src/app/components/shared/selectors/type-research-select-input/type-research-select-input.component';
import { MemberI } from 'src/app/models/researchModels/member';
import { ProjectI, SchoolI } from 'src/app/models/researchModels/project';
import { RequestResearchService } from 'src/app/services/research/research.service';


interface Select{
  id: string | number;
  text: string;
};

@Component( {
  selector: 'request-add-project-form',
  templateUrl: './add-project-form.component.html',
  styleUrls: [ './add-project-form.component.css' ]
} )
export class AddProjectFormComponent implements OnInit {

  constructor (
    private _fb: FormBuilder,
    private _requestResearchService: RequestResearchService,
  ) {
  }

  //searches for members selectable components
  @ViewChild( 'mainResearcherInput' ) mainResearcherInput: ProjectMemberSelectInputComponent;
  @ViewChild( 'otherResearcher' ) otherResearcher: ProjectMemberSelectInputComponent;
  @ViewChild( 'typeResearch' ) typeResearch: TypeResearchSelectInputComponent;

  //emits a call each time a new project is created
  @Output( 'createdProject' ) createdProject: EventEmitter<void> = new EventEmitter();

  //issues a call when the modal is closed
  @Output( 'closeModal' ) closeModal: EventEmitter<void> = new EventEmitter();

  //allows to show or hide the project form
  @Input( 'showForm' ) showForm: boolean = false;

  //receives the id of the current group
  @Input( 'groupId' ) groupId: number = 0;

  //receives the list of members of the current group
  @Input( 'memberList' ) memberList: MemberI[] = [];

  //project form
  public projectForm: FormGroup = this._fb.group({
    percentageExecuted: [0, Validators.min(0)],
    counterpartBudget: [0, Validators.min(0)],
    otherBudgets: [0, Validators.min(0)],
    projectName: ['', Validators.required],
    description: ['', Validators.required],
    totalBudget: [0, Validators.min(0)],
    DITECBudget: [0, Validators.min(0)],
    linkDrive: [''],
    dateStart: ['', Validators.required],
    capacity: ['', Validators.required],
    dateEnd: [null],
    unity: [''],
    area: [''],
    role: [''],
    school: [''],
  });

  //validates if user and role are valid
  public isRoleFormValid: boolean = true;

  //gets the main research to be added
  public mainResearcher?: MemberI = undefined;

  //stores a list of other researchers to add
  public otherResearchers: any[] = [];

  //temporarily stores the other researcher to be added
  public tempOtherResearcher: MemberI[] = [];

  //obtains a list of members who should not appear in the selector.
  public toExclude: MemberI[] = [];

  //allows to show or hide the add member and role section
  public showRoleSection: boolean = true;

  //stores a list of schools
  public schoolList: Select[] = [];

  //stores a list of areas
  public areaList: Select[] = [];

  //stores the lines of research to be added
  public researchLines: any[] = [];
  public unityList: Select[] = [];

  //checks whether data is being uploaded or not
  public isSubmitting: boolean = false;

  public capacity: Select[] = [
    {id: "Mando tipo misión", text: 'Mando tipo misión' },
    {id: "Fuegos", text: 'Fuegos' },
    {id: "Sostenimiento", text: 'Sostenimiento' },
    {id: "Protección", text: 'Protección' },
    {id: "Movimiento y maniobra", text: 'Movimiento y maniobra' },
  ];

  ngOnInit(): void {
    this.schoolList = this._requestResearchService.currentSchools.map(e => ({id:e.id, text:e.NameTSchool}));
  }

  /**
   * obtains the list of areas and seedbedGroups
   * @param schoolId
   */
  getUnitContent( schoolId: number ) {
    this.projectForm.get('area')?.reset('');
    this.projectForm.get('unity')?.reset('');

    this.areaList = [];
    const unitFilter = JSON.stringify({ where:{schoolID: schoolId} });
    this._requestResearchService.getUnities(unitFilter).subscribe( {
      next: ( resp:any[] ) => {
        this.unityList = resp.sort((a, b) =>
          a.nameUnit.localeCompare(b.nameUnit)
        ).map( e => ({id: e.id, text: e.nameUnit}));
      },
      error: ( err ) => console.log( err )
    } );
  }

  /**
   * get the area depending on the school
   * @param unityId
   */
  getArea( unityId ) {
    this.projectForm.get('area')?.reset('');

    let filter = JSON.stringify( {
      where: { SchoolID: unityId }
    } )

    this._requestResearchService.getResearchAreas( filter ).subscribe( {
      next: ( researchAreas ) => { this.areaList = researchAreas.map(e => ({id: e.id, text:e.NameArea})); },
      error: ( err ) => console.log( err )
    } );
  }


  /**
   * creates a new project
   */
  submitProject() {
    if ( !( this.projectForm.valid && this.mainResearcher !== undefined && this.otherResearchers.length > 0 && this.researchLines.length > 0) || this.isSubmitting)
      return;

    this.isSubmitting = true;
    let formData: any = {
      percentageExecuted: this.projectForm.get( 'percentageExecuted' )?.value,
      counterpartBudget: this.projectForm.get( 'counterpartBudget' )?.value,
      researchGroupID: this.groupId,
      mainResearchID: this.mainResearcher.id,
      researchAreaID: this.projectForm.get( 'area' )?.value || null,
      otherBudgets: this.projectForm.get( 'otherBudgets' )?.value,
      description: this.projectForm.get( 'description' )?.value,
      totalBudget: this.projectForm.get( 'totalBudget' )?.value,
      DITECBudget: this.projectForm.get( 'counterpartBudget' )?.value,
      dateUpdate: new Date(),
      dateStart: this.projectForm.get( 'dateStart' )?.value,
      linkDrive: this.projectForm.get( 'linkDrive' )?.value,
      schoolID: this.projectForm.get( 'school' )?.value || null,
      capacity: this.projectForm.get( 'capacity' )?.value,
      dateEnd: this.projectForm.get( 'dateEnd' )?.value || null,
      name: this.projectForm.get( 'projectName' )?.value,
      unitID: this.projectForm.get('unity')?.value || null,
    }

    let otherResearchersObj = ( projectId: number ) => this.otherResearchers.map( r => ( {
      memberID: r.member.id,
      projectID: projectId,
      projectRol: r.role
    } ) );

    let otherResearchersObservable = ( projectId: number ) => [ ...otherResearchersObj( projectId ) ].map( data =>
      this._requestResearchService.setProjectMember( data )
    );

    let newRLines = ( projectId: number ) => this.researchLines.map( rLine => {
      return ( {
        projectID: projectId,
        typeResearchID: rLine.id,
      } )
    } );

    let rLinesMappedObservable = ( projectId: number ) => [ ...newRLines( projectId ) ].map( ( rLine: any ) =>
      this._requestResearchService.setProjectTypeResearches( rLine ) );

    this._requestResearchService.createProject( formData ).pipe(
      switchMap( ( project: ProjectI ) => forkJoin({
          researchers: forkJoin([...otherResearchersObservable( project.id! )]),
          rLines: forkJoin([...rLinesMappedObservable( project.id! )])
      }) )
    ).subscribe( {
      complete: () => {
        this.closeFormModal();
        this.createdProject.emit();
      }, error: ( err: Error ) => console.log( err )
    } )
  }

  /**
   * add the member and their respective role to the list of other researchers to be added
   * @returns
   */
  setMemberAndRole() {
    this.isRoleFormValid = ( this.projectForm.get( 'role' )?.value !== '' && this.tempOtherResearcher.length > 0 );

    if ( !this.isRoleFormValid )
      return;

    this.otherResearchers.push( {
      member: this.tempOtherResearcher[ 0 ],
      role: this.projectForm.get( 'role' )?.value
    } )

    let toExclude = this.toExclude.concat( this.tempOtherResearcher[ 0 ] );
    this.toExclude = toExclude;

    this.otherResearcher.clearData();
    this.tempOtherResearcher = [];
    this.projectForm.controls[ 'role' ].reset();
  }

  /**
   * gets the main researcher to add
   * @param mainResearcher
   */
  getMainResearcher( mainResearcher: MemberI[] ) {
    if ( mainResearcher.length === 0 )
      this.toExclude.forEach( ( e, i ) => {
        if ( e.id === this.mainResearcher!.id ) this.toExclude.splice( i, 1 );
      } )

    let toExclude = this.toExclude.concat( mainResearcher );
    this.toExclude = toExclude;
    this.mainResearcher = mainResearcher[ 0 ];
    this.otherResearcher.clearData();
  }

  /**
  * obtains a list of other possible researchers to be added
  * @param otherResearchers
  */
  getOtherResearcher( otherResearcher: MemberI[] ) {
    this.tempOtherResearcher = otherResearcher;
  }

  /**
  * receive the list of lines of research to be added
  * @param members
  */
  getRLinesToAdd( researchLines: any[] ) {
    this.researchLines = researchLines;
  }

  /**
   * removes the member from the list to be added and from the excluded list
   * @param data
   */
  onCloseOtherResearcherPill( data ) {
    //remove member pill
    this.otherResearchers.forEach( ( r, i ) => {
      if ( r.member.id === data.member.id )
        this.otherResearchers.splice( i, 1 );
    } )

    //remove from users to be excluded
    this.toExclude.forEach( ( e, i ) => {
      if ( e.id === data.member.id )
        this.toExclude.splice( i, 1 );
    } )
  }

  /**
  * clear the form data
  */
  clearForm() {
    this.projectForm.reset( {
      capacity:'',
      unity: '',
      area: '',
      role: '',
      school: ''
    } );

    this.mainResearcherInput.clearData();
    this.otherResearcher.clearData();
    this.typeResearch.clearData();
    this.tempOtherResearcher = [];
    this.otherResearchers = [];
    this.mainResearcher = undefined;
    this.toExclude = [];
  }

  /**
  * closes and clears the project modal
  */
  closeFormModal() {
    this.clearForm();
    this.showForm = false;
    this.closeModal.emit();
    this.isSubmitting = false;
    this.isRoleFormValid = true;
    this.showRoleSection = true;
  }
}