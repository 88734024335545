<div class="group-form__input" [formGroup]="parentForm">
  <label for="" class="">{{ title }}</label>
  <select id="" class="input form-control" [formControlName]="controlName" (change)="onChange($event)"
    [ngClass]="parentForm.get( controlName )?.invalid && parentForm.get( controlName )?.touched ? 'input--invalid' : ''"
    #selectInput>
    <option [value]="''" disabled selected *ngIf="placeholder">{{ placeholder }}</option>
    <option [value]="item.id" *ngFor="let item of items">{{item.text}}</option>
  </select>

  <small class="text--invalid" *ngIf="parentForm.get( controlName )?.invalid && parentForm.get( controlName )?.touched">
    Este campo es obligatorio
  </small>
</div>