import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable( {
  providedIn: 'root'
} )
export class AuthService {

  private token!: string;
  public UrlAPI: string = environment.baseUrl;

  constructor () { }

  getToken() {
    let token = localStorage.getItem( 'accessToken' );
    if ( !!token ) {
      this.token = token;
    }
    return this.token;
  }

  getCurrentUserID(): string | null {
    let userIDString = localStorage.getItem( "currentUser" );
    return userIDString;
  }

}
