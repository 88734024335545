<div class="documents-selector" [ngClass]="isSubmitting ? 'disabled' : ''">
  <div class="documents-selector__inline">
    <input type="text" placeholder="Escriba el nombre del archivo" class="documents-selector__name"
      name="FileName" [(ngModel)]="dataFile.nameFile" minlength="1" maxlength="70" #FileName="ngModel"
      [ngClass]="{'documents-selector__name--error': errorNoFileName}"
    >
    <button type="button" class="documents-selector__file" [ngClass]="{'documents-selector__file--selected': fileToUpload}" (click)="fileInput.click()">
      <em class="documents-selector__file-icon icon-i-border-upload"></em>
      <div class="documents-selector__file-text">
        <div class="documents-selector__file-text-label" [ngClass]="{'documents-selector__file-text-label--warning': isArchiveError}">{{title}}</div>
        <div class="documents-selector__file-text-size" *ngIf="!fileToUpload">{{ smallTitle }}</div>
      </div>
      <input #fileInput type="file" (change)="onFileInput($event)" style="display:none;" />
    </button>
    <em class="documents-selector__button-save icon-i-check-modal" *ngIf="fileToUpload && !isArchiveError" (click)="uploadAttachment()"></em>
  </div>
  <input type="text" placeholder="Descripción" class="documents-selector__descript"
      name="FileDescript" [(ngModel)]="dataFile.descript" minlength="1" maxlength="70" #FileDescript="ngModel"
  >
</div>