import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'request-select-input',
  templateUrl: './select-input.component.html',
  styleUrls: ['./select-input.component.css']
})
export class SelectInputComponent implements OnInit {

  constructor() { 
    //
  }
  //emits a call when the value of the input changes
  @Output('onSelectChange') onSelectChange: EventEmitter<any> = new EventEmitter();

  //receives the parent form
  @Input('parentForm') parentForm:FormGroup = new FormGroup({});

  //receives the name of the control name
  @Input('controlName') controlName:string = '';
  @Input('items') items:any[] = [];

  //receives label title
  @Input('title') title:string = '';

  @Input('placeholder') placeholder:string = '';

  ngOnInit(): void {
    //
  }

  /**
   * is executed when the input value changes
   * @param value 
   */
  onChange(value){
    let inputValue = value.target.value
    this.onSelectChange.emit(inputValue);
  }
}
