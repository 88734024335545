<div class="projects-container">
  <div class="projects-container__header"> 
    <div class="projects-container__title-container">
      <button class="button button-back" (click)="closeSection.emit()">
        <em class="icon-i-back"></em>
      </button>
      <h2 class="projects-text projects-text--bold projects-text--lg">PROYECTOS DEL GRUPO</h2>
    </div>

    <div class="projects-container__button-container" *ngIf="rolesService.allowedRoles.ADMIN || rolesService.allowedRoles.LEADER">
      <button class="button button--dark button--plus-icon" (click)="showAddProjectModal = true">
        <em class="icon-i-plus-circle"></em>
        Agregar proyecto
      </button>
      <request-add-project-form [showForm]="showAddProjectModal" [groupId]="selectedGroupId" [memberList]="memberList"
        (createdProject)="getProjects()" (closeModal)="showAddProjectModal = false">
      </request-add-project-form>
    </div>
  </div>

  <div class="projects-container__filter-container">
    <div class="projects-container__date-filter">
      <request-date-pill (onInputChange)="getStartDate($event)" [defaultDate]="(fromDate | date:'yyyy-MM-dd')">
      </request-date-pill>
      <span class="projects-text projects-text--center">A</span>
      <request-date-pill (onInputChange)="getEndDate($event)" [defaultDate]="(toDate | date:'yyyy-MM-dd')">
      </request-date-pill>
    </div>
  </div>

  <div class="projects-container__projects">
    <request-project-card *ngFor="let project of projectList" [projectInfo]="project" (editProject)="editProject(project.id!)">
    </request-project-card>
  </div>

  <request-edit-project-form [onShowForm]="{ showForm:showEditProjectModal, projectId:selectedProjectId }" [memberList]="memberList"
    (editedProject)="getProjects()" (closeModal)="showEditProjectModal = false"
    *ngIf="rolesService.allowedRoles.ADMIN || rolesService.allowedRoles.LEADER">
  </request-edit-project-form>
</div>