import { Component, HostListener, OnInit } from '@angular/core';
import { forkJoin, groupBy, map, of, switchMap } from 'rxjs';
import { GroupI } from 'src/app/models/researchModels/group';
import { UserI } from 'src/app/models/researchModels/user';
import { ResearchRolesService } from 'src/app/services/research/research-roles.service';
import { RequestResearchService } from 'src/app/services/research/research.service';
import { SchoolI } from 'src/app/models/researchModels/project';
import colorList from "../../components/shared/colorList.json";
import Swal from 'sweetalert2';


@Component({
  selector: 'research',
  templateUrl: './research.component.html',
  styleUrls: ['./research.component.css']
})
export class ResearchComponent implements OnInit {

  constructor(
    private _requestResearchService: RequestResearchService,
    public rolesService: ResearchRolesService,
  ) {
  }

  public colors: any[] = colorList;
  public showOrganizationForm: boolean = false;
  public showOrganizationEditForm: boolean = false;

  public showStatsModal: boolean = false;
  public showGroupInfo: boolean = false;

  public originalGroups: GroupI[] = [];
  public investigationTypeFilter: any[] = [];

  public selectedGroup: any = null;
  public groupToEdit: any = null;

  public schoolList: SchoolI[] = [];
  public unityList: any[] = [];

  //FILTER VARIABLES
  //list of group ids to generate stats in the stats panel
  public groupListIds: number[] = [];
  public filteredGroups: GroupI[] = [];
  public _searchValue: string = '';
  public showFilters: boolean = false;
  private _groupsToBeFiltered: GroupI[] = [];
  public schoolId: number = 0;
  public unityId: number = 0;
  public investigationType: "formative" | "formal" | "all" = 'all';

  ngOnInit(): void {
    this.getGroupList();
  }

  /**
   * Gets the list of groups and filters them depending on the user's role,
   * if he/she is an admin it will bring them all, if he/she is a leader or a member,
   * it will bring only the groups where he is a member or leader.
   *
   * It also sets the roles to limit what each user can see.
   */
  getGroupList() {
    Swal.fire({
      text:"Cargando grupos...",
      didOpen: () => Swal.showLoading()
    });

    this.getCurrentUser().pipe(
      switchMap((userInfo: UserI) => {
        const userRole = userInfo.roleMappings.find(r => r.role?.name.toUpperCase() === 'INVESTIGACIÓN' ||  r.role?.name.toUpperCase() === 'ADMIN');

        //Get current school id
        const schooLId = userInfo.roleMappings[0].SchoolID;

        return this._requestResearchService.getSchools().pipe(map((school) => {
          const currentSchools: any[] = school.filter(e => e.DepenSchoolID === schooLId);
          currentSchools.push(school.find(e => e.id === schooLId));

          this.schoolList = currentSchools.sort((a, b) =>
            a.NameTSchool.localeCompare(b.NameTSchool)
          );

          this._requestResearchService.currentSchools = currentSchools;
          return { userInfo: userInfo, isAdmin: userRole !== undefined };
        }));
      }),
      switchMap((info: any) => {
        return forkJoin({
          memberGroups: !info.isAdmin ? this.getMemberGroups(info.userInfo) : of(null),
          groups: this.getGroups(info),
        }).pipe(
          map(({ memberGroups, groups }: any) => {
            this.rolesService.allowedRoles.ADMIN = info.isAdmin;
            if (info.isAdmin) return { groups: groups };

            const tempLeaderGroups = groups.filter(group => group.userID === info.userInfo.id);
            const tempMemberGroups = groups.filter(group => memberGroups.some(m => m.researchGroupID === group.id));

            tempMemberGroups.forEach((member, i) => {
              if (tempLeaderGroups.some(leader => member.id === leader.id))
                tempMemberGroups.splice(i, 1);
            });

            //Sets the roles of the current user based on the group.
            const leaderRoles = tempLeaderGroups.map(r => { return { groupId: r.id, name: 'LEADER' } });
            const memberRoles = tempMemberGroups.map(r => { return { groupId: r.id, name: 'MEMBER' } });
            this.rolesService.roleByGroups = leaderRoles.concat(memberRoles);

            return { groups: tempLeaderGroups.concat(tempMemberGroups) }
          })
        )
      })).subscribe({
        next: ({groups}) => {
          this.originalGroups = groups;
          this.applyFilters();

          const formativeCount = this.originalGroups.filter(e => e.typeRxGroupID === 1 || e.typeRxGroupID === 2).length;
          const formalCount = this.originalGroups.filter(e => e.typeRxGroupID === 3).length;
          this.investigationTypeFilter = [
            {
              id: "all",
              title: `Todas (${this.originalGroups.length})`,
            },
            {
              id: "formative",
              title: `Formativa (${formativeCount})`,
            },
            {
              id: "formal",
              title: `Formal (${formalCount})`,
            }
          ];

          //Each time the group list is updated and an element is selected, we update the selected element.
          if (this.selectedGroup) {
            this.moveSelectedGroupToTop();
            //Refresh edit form data
            this.selectedGroup = this.originalGroups.find(e => e.id === this.selectedGroup.id);
          }
        }, error: (err: Error) => console.log(err),
        complete: () => Swal.close()
      });
  }

  /**
   * Show the content of the clicked group card
   * @param event
   * @param groupInfo
   */
  onSelectCard(groupInfo) {
    this.showGroupInfo = true;
    this.selectedGroup = groupInfo;
    this.getRole(groupInfo.id);

    //Positions the selected item at the top of the list
    this.moveSelectedGroupToTop();
  }

  private moveSelectedGroupToTop() {
    for (let i = 0; i < this.filteredGroups.length; i++) {
      if (this.filteredGroups[i].id === this.selectedGroup.id) {
        const item = this.filteredGroups[i];
        this.filteredGroups.splice(i, 1);
        this.filteredGroups.unshift(item);
        break;
      }
    }
  } 

  //#region FILTERS
  /**
   * filter groups depending on the value entered in the input
   * @param event
   * @returns
   */
  filterByName(event) {
    this.filteredGroups = [...this._groupsToBeFiltered];
    const value = event.toLowerCase();
    this._searchValue = value;
    if (event.length < 4 || event === '')
      return;

    this.filteredGroups = this._groupsToBeFiltered.filter(g => g.name.toLowerCase().includes(value));
  }

  applyFilters() {
    const schooLId = Number(this.schoolId);
    const unityId = Number(this.unityId);

    this._groupsToBeFiltered = this.originalGroups.filter(group => {
      return (schooLId === 0 ? true : group.schoolID === schooLId) && 
        (unityId === 0 ? true : group.unitID === unityId) &&
        (this.investigationType === 'all' ? true : this.selectedInvestigationType(group.typeRxGroupID))
    });

    this.filteredGroups = this._groupsToBeFiltered;

    this.filterByName(this._searchValue);
    this.groupListIds = this.filteredGroups.map(e => e.id); //stats filters groups id
    this.showFilters = false;
  }

  selectedInvestigationType(typeRxGroupID: number) {
    const isFormative = (this.investigationType === 'formative' && (typeRxGroupID === 1 || typeRxGroupID === 2));
    const isFormal = (this.investigationType === 'formal' && typeRxGroupID === 3);

    return isFormative || isFormal;
  }
  //#endregion


  public onCreateGroup(){
    this.showOrganizationForm = true; 
    this.showOrganizationEditForm = false;
  }

  public onEditGroup(){
    this.showOrganizationForm = false; 
    this.showOrganizationEditForm = true; 
    this.groupToEdit = this.selectedGroup;
  }

  /**
   * depending on the group selected by the user, he/she will get the role
   * @param groupId
   * @returns
   */
  private getRole(groupId: number) {
    //If the user is already an admin
    if (this.rolesService.allowedRoles.ADMIN) return;

    const selectedGroup = this.rolesService.roleByGroups.find(r => r.groupId === groupId);
    Object.keys(this.rolesService.allowedRoles).forEach(e => {
      this.rolesService.allowedRoles[e] = (e === selectedGroup?.name);
    });
  }

  /**
  * obtains the current user's information
  * @returns
  */
  getCurrentUser() {
    const filter = JSON.stringify({ include: [{ roleMappings: 'role' }]})
    return this.rolesService.getCurrentUser('me', filter);
  }

  /**
   * obtains the list of groups
   * @returns
   */
  private getGroups(info) {
    //TODO: Pagination for the future?
    const includes = ["userapp", 'researchGroup', "typeRxGroup", 'school', { researchGroupToTypeResearches: 'typeResearch' }, 'unit', "researchArea"];
    const inqSchools = this._requestResearchService.currentSchools.map(e => e.id);

    const adminFilter = JSON.stringify({ where: { schoolID: { inq: inqSchools } }, include: includes, limit: 250 });
    const allFilter = JSON.stringify({ include: includes, limit: 250 });
    return this._requestResearchService.getGroups(info.isAdmin ? adminFilter : allFilter)
  }

  public onSelectSchool(event: Event) {
    const value = (event.target as HTMLInputElement).value;

    this.unityId = 0;
    this.unityList = [];

    const filter = JSON.stringify({
      where: { schoolID: Number(value) }
    })

    this._requestResearchService.getUnities(filter).subscribe({
      next: (resp) => {
        this.unityList = resp;
      }, error: (err) => console.log(err)
    })
  }

  /**
   * gets the list of groups in which the user is a member
   * @param userInfo
   * @returns
   */
  getMemberGroups(userInfo: UserI) {
    const filter: string = JSON.stringify({where: { userID: userInfo.id }})
    return this._requestResearchService.getMembers(filter);
  }

  /**
   * deselects the group when the member section is closed.
   */
  closeMemberList() {
    this.showGroupInfo = false;
    this.selectedGroup = null;
  }

  /**
   * Optimize loop with trackBy
   * @param index
   * @param item
   * @returns
   */
  trackItems(index: number, item: any) {
    return item ? item.id : null;
  }

  @HostListener('document:click', ['$event'])
  clickOutside(e) {
    if (e.target.id === 'filter-button')
      this.showFilters = !this.showFilters;
    else if (!e.target.closest('.filter__modal'))
      this.showFilters = false;
  }
}