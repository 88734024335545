import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'request-date-pill',
  templateUrl: './date-pill.component.html',
  styleUrls: ['./date-pill.component.css']
})
export class DatePillComponent implements OnInit {

  @Output('onInputChange') onInputChange:EventEmitter<any> = new EventEmitter();

  @Input('inputId') inputId: string = '';

  @Input('defaultDate') defaultDate: any;

  @Input('title') title: string = 'Fecha';

  /* @Out */

  public date: string = '';

  constructor() { 
    //
   }

  ngOnInit(): void {
    //
  }

  getDate(event){
    this.onInputChange.emit(event.target.value)
  }

  showPicker(event){
    event.target.showPicker();
  }

}
