import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { GroupI } from 'src/app/models/researchModels/group';

@Component( {
  selector: 'request-group-card',
  templateUrl: './group-card.component.html',
  styleUrls: [ './group-card.component.css' ]
} )
export class GroupCardComponent implements OnInit {

  //issues a call when the card is selected
  @Output('onSelected') onSelected:EventEmitter<any> = new EventEmitter();

  //receive the information of the selected group
  @Input( "groupData" ) groupData: GroupI = {} as GroupI;

  //allows to set the card status
  @Input('activateCard') activateCard: boolean = false;

  //input id
  @Input('buttonId') buttonId: any;

  constructor () {
    //
  }

  ngOnInit(): void {
    //
  }

}
