import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ResearchRolesService } from 'src/app/services/research/research-roles.service';

@Component( {
  selector: 'request-member-card',
  templateUrl: './member-card.component.html',
  styleUrls: [ './member-card.component.css' ]
} )
export class MemberCardComponent implements OnInit {

  constructor (    
    public rolesService: ResearchRolesService
    ) {
    //
  }

  //issues a call when the edit button is clicked
  @Output('editMember') editMember: EventEmitter<any> = new EventEmitter();

  //Receive a member's data
  @Input( 'memberData' ) memberData: any = {};

  ngOnInit(): void {
    //
  }

}
